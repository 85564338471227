import { useState, useEffect, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "lodash";
import AuraTable from "app/main/apps/common/AuraTable";
import withRouter from "@fuse/core/withRouter";
import FuseUtils from "@fuse/utils";
import { getParams, mapParams } from "../../common/AuraFunctions";
import {
  getShipments,
  selectShipments,
  setResetFilters,
} from "../store/shipmentsSlice";

function ShipmentsContent(props) {
  const { columns } = props;
  const dispatch = useDispatch();
  const shipments = useSelector(selectShipments);
  const [headers, setHeaders] = useState({});
  const [params, setParams] = useState({});
  const resetFilter = useSelector(
    ({ backofficeApp }) => backofficeApp.shipments.resetFilter
  );
  const { shipmentsListHeaders } = useSelector(
    ({ backofficeApp }) => backofficeApp.shipments
  );
  const searchText = useSelector(
    ({ backofficeApp }) => backofficeApp.shipments.searchText
  );
  const listLoading = useSelector(
    ({ backofficeApp }) => backofficeApp.shipments.loading
  );
  const [pageNoUpdated, setPageNoUpdated] = useState(false);
  const [filteredData, setFilteredData] = useState(null);
  const [showNoDataMessage, setShowNoDataMessage] = useState(null);
  const ref = useRef();

  const updateParams = () => {
    const computedParams = getParams();
    setHeaders(computedParams.headerObj);
    setParams(computedParams.filterObj);
  };

  const debounceFn = useCallback(
    debounce((param, header) => {
      setShowNoDataMessage(null);
      dispatch(getShipments({ params: param, headers: header }))
        .unwrap()
        .then((response) => {
          const dataLength = response?.data?.length || 0;
          if (dataLength <= 0) {
            setShowNoDataMessage(true);
          }
        });
    }, 500),
    []
  );

  useEffect(() => {
    if (ref.current) {
      // Remove pageNo when searching
      let removeKey = ["wq", "uid"];
      if (pageNoUpdated === false) {
        removeKey = ["wq", "uid", "pageNo"];
      }
      const searchParams = new URLSearchParams(
        mapParams({ ...headers, ...params }, removeKey)
      );
      setPageNoUpdated(false);
      // replacestate function will not rerender the page
      if (searchParams.toString())
        window.history.replaceState(
          null,
          "",
          `${window.location.href.split("?")[0]}?${searchParams.toString()}`
        );
    } else {
      updateParams();
    }
    debounceFn(params, headers);
    ref.current = true;
  }, [dispatch, debounceFn, params, headers]);

  useEffect(() => {
    if (resetFilter) {
      updateParams();
      debounceFn(params, headers);
      dispatch(setResetFilters(false));
    }
  }, [debounceFn, headers, params, resetFilter]);

  useEffect(() => {
    function getFilteredArray(_entities, _searchText) {
      if (_searchText.length === 0) {
        return _entities;
      }
      return FuseUtils.filterArrayByString(_entities, _searchText);
    }

    if (shipments) {
      setFilteredData(getFilteredArray(shipments, searchText));
    }
  }, [shipments, searchText]);

  const onPageChange = (value) => {
    setPageNoUpdated(true);
    setHeaders((prev) => ({ ...prev, "App-Pagination-Num": value }));
  };

  const onPerPageChange = (value) => {
    setHeaders((prev) => ({ ...prev, "App-Pagination-Limit": value }));
  };

  const onSorting = (key, desc) => {
    const sortvalue = `${key},${desc ? "DESC" : "ASC"}`;
    if (key)
      setHeaders((prev) => ({ ...prev, "App-Pagination-Sort": sortvalue }));
    else {
      setHeaders({ ...headers, "App-Pagination-Sort": desc });
    }
  };

  const serverPaginationProps = {
    changePage: onPageChange,
    perPage: onPerPageChange,
  };

  return (
    <div className="flex-auto p-24">
      <AuraTable
        columns={columns}
        data={shipments || []}
        resetFilters
        params={params}
        isManage={props.isManage}
        headers={shipmentsListHeaders}
        serverPaginationProps={serverPaginationProps}
        updateParams={(key, value) =>
          setParams((prev) => ({ ...prev, [key]: value }))
        }
        onSorting={onSorting}
        loading={listLoading}
        showListInfo={showNoDataMessage && showNoDataMessage === true}
        loaderProps={{
          show: !filteredData || filteredData.length === 0,
          loading: listLoading,
          message: `There are no Shipments!`,
        }}
        loaderWithSpace
        noDataMessage="There are no Shipments!"
        resetFunction={setResetFilters}
      />
    </div>
  );
}

export default withRouter(ShipmentsContent);
