/* eslint import/no-extraneous-dependencies: off */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import history from '@history';
import axios from 'axios';
import _ from '@lodash';
import { setInitialSettings } from 'app/store/fuse/settingsSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import settingsConfig from 'app/configs/settingsConfig';
import jwtService from '../auth/services/jwtService';
import { roleLayout } from '../auth';

export const setUser = createAsyncThunk('user/setUser', async (user, { dispatch, getState }) => {
  /*
    You can redirect the logged-in user to a specific route depending on his role
    */
  const settings = {};
  if (user.loginRedirectUrl) {
    settings.loginRedirectUrl = user.loginRedirectUrl; // for example 'dashboard'
  }
  if (user.verify) {
    settings.loginRedirectUrl = '/verify';
  } else {
    const response = await (await axios.get('/api/workflowactions')).data;
    dispatch(setDynamicActions(response));
  }
  if (roleLayout[user.role] === 'portal') {
    settings.layout = settingsConfig.layoutOptions.portal;
  }
  user.data = {
    shortcuts: [],
    settings: {
      ...settingsConfig,
      ...settings,
    },
  };
  if(user?.securityContext?.privileges) {
    dispatch(setPermissions(user?.securityContext?.privileges));
  }
  return user;
});

export const updateUserSettings = createAsyncThunk(
  'user/updateSettings',
  async (settings, { dispatch, getState }) => {
    const { user } = getState();
    const newUser = _.merge({}, user, { data: { settings } });

    dispatch(updateUserData(newUser, false));

    return newUser;
  }
);

export const updateUserShortcuts = createAsyncThunk(
  'user/updateShortucts',
  async (shortcuts, { dispatch, getState }) => {
    const { user } = getState();
    const newUser = {
      ...user,
      data: {
        ...user.data,
        shortcuts,
      },
    };

    dispatch(updateUserData(newUser, false));

    return newUser;
  }
);

export const completeVerify = createAsyncThunk(
  'user/completeVerify',
  async (settings, { dispatch, getState }) => {
    const { user } = getState();
    // Set true isVerified once verification done
    dispatch(setIsVerified(true));
    const newUser = _.merge({}, user, { verify: false });
    dispatch(updateUserData(newUser, false));
    return newUser;
  }
);

export const logoutUser = () => async (dispatch, getState) => {
  const { user } = getState();

  if (!user.role || user.role.length === 0) {
    // is guest
    return null;
  }

  history.push({
    pathname: '/',
  });
  history.go(0);

  dispatch(setInitialSettings());

  return dispatch(userLoggedOut());
};

export const updateUserData = (user, showSuccess) => async (dispatch, getState) => {
  if (!user.role || user.role.length === 0) {
    // is guest
    return;
  }
  jwtService
    .updateUserData(user)
    .then(() => {
      if (showSuccess) {
        dispatch(showMessage({ message: 'User data saved successfully' }));
      }
    })
    .catch((error) => {
      dispatch(showMessage({ message: error.message }));
    });
};

export const getNotifications = createAsyncThunk('user/getNotifications', async (arg, { getState }) => {
  const loggedInuser = getState().user;
  let data = {};
  // Added loggedInuser?.id condition to not call notifications api in logout, signin and Reset password action
  // loggedInuser.id is present in verify page so Added addition condition to not call notifications in the verification action
  // loggedInuser.isVerified will be undefined if we refresh the page since isVerified is not coming from Backend
  if(loggedInuser?.id && (typeof(loggedInuser.isVerified) === "undefined" || loggedInuser.isVerified)) {
    const response = await axios.get('/api/notifications');
    data = await response.data;
  }
  return data;
});


const initialState = {
  role: [], // guest
  data: {
    displayName: 'Guest User',
    photoURL: 'assets/images/avatars/profile.jpg',
    email: 'nobody@auramint.com',
    shortcuts: ['calendar', 'mail', 'contacts', 'todo'],
  },
  notifications: {},
  verify: true,
  dynamicActions: [],
  permissions: []
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    userLoggedOut: (state, action) => initialState,
    setDynamicActions: (state, action) => {
      state.dynamicActions = action.payload
    },
    setIsVerified: (state, action) => {
      state.isVerified = action.payload
    },
    setPermissions: (state, action) => {
      state.permissions = action.payload
    },
  },
  extraReducers: {
    [updateUserSettings.fulfilled]: (state, action) => action.payload,
    [updateUserShortcuts.fulfilled]: (state, action) => action.payload,
    [setUser.fulfilled]: (state, action) => ({ ...state, ...action.payload }),
    [getNotifications.fulfilled]: (state, action) => {
      state.notifications = action.payload
    },
  },
});

export const { userLoggedOut, setDynamicActions, setIsVerified, setPermissions } = userSlice.actions;

export const selectUser = ({ user }) => user;
export const selectActions = ({ user }) => user.dynamicActions;
export const selectUserShortcuts = ({ user }) => user.data.shortcuts;

export default userSlice.reducer;
