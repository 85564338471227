import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { showMessage } from 'app/store/fuse/messageSlice';
import axios from 'axios';
import { omit } from 'lodash';

export const getCheckList = createAsyncThunk(
    'backofficeApp/shipment/getCheckList',
    async (id) => {
        const response = await axios.get(`api/getPackingCheckLists/${id}`);
        const data = await response.data.shipment;
        return data;
    }
);

export const getShipmentDetails = createAsyncThunk(
    'backofficeApp/shipment/details', async (inputData, { dispatch }) => {
        dispatch(setDetailsLoading(true));
        // Added condition to avoid backend error
        if (inputData === null || inputData === undefined) {
            dispatch(setDetailsLoading(false));
            return {shipment : []};
        }
        const response = await axios.get(`/api/shipmentSummary/${inputData}`)
            .finally(() => dispatch(setDetailsLoading(false)))
        return response.data;
    }
)

export const updateShipment = createAsyncThunk(
    'backofficeApp/shipment/updateShipment',
    async ({ values, shipmentId }, { dispatch, getState }) => {
        const payload = {
            ...values,
            is_selected: true,
            images: Array.isArray(values?.new_documents) ? values?.new_documents?.map((item) => ({ id: item.id })) : undefined
        }
        payload.shipping_cost = Number(payload.shipping_cost)
        axios.put(`api/packingCheckList/${shipmentId}`, omit(payload, ['attachmentId', 'attachmentNewId', 'new_documents']))
            .then(() => {
                dispatch(getCheckList(shipmentId));
                dispatch(showMessage({
                    message: 'Shipment is completed and marked as shipped',
                    variant: 'success',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                }));
            })
            .catch(() => dispatch(showMessage({
                message: 'Failed to update',
                variant: 'error',
                autoHideDuration: null,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })))
    }
);

export const getPackingSlip = createAsyncThunk(
    'backofficeApp/shipment/packingSlip',
    async ({ shipmentId, orderId }) => {
        const response = await axios.get(`api/packingSlip/${shipmentId}`, { params: { oid: orderId } })
        return response.data.shipment
    }
);

export const getShipmentInformation = createAsyncThunk(
    'backofficeApp/shipment/getShipmentInformation',
    async (id) => {
        const response = await axios.get(`api/shipmentInfo/${id}`);
        const data = await response.data.shipment;
        return data;
    }
);

export const getProductionSheet = createAsyncThunk(
    'backofficeApp/shipment/getProductionSheet',
    async (id) => {
        const response = await axios.get(`api/productionSheet/${id}`);
        const data = await response.data.shipping_label;
        return data;
    }
);

export const sendPackingSlipToCustomer = createAsyncThunk(
    'backofficeApp/shipment/sendPackingSlipToCustomer',
    async (id) => {
        const response = await axios.get(`api/send/productionSheet/${id}`);
        const data = await response.data;
        return data;
    }
);

export const initState = {
    checkList: [],
    updateLoading: false,
    checkListLoading: false,
    enableSave: false,
    fileIds: [],
    packingSlip: [],
    shipmentDialog: {
        open: false
    },
    detailsLoading: false,
    shipentDetails: []
};

const shipmentSlice = createSlice({
    name: 'backofficeApp/shipment',
    initialState: initState,
    reducers: {
        enableSave: (state, action) => {
            state.enableSave = action.payload;
        },
        setFileIds: (state, action) => {
            state.fileIds = action.payload;
        },
        openShipmentDialog: (state, action) => {
            state.shipmentDialog.open = true
        },
        closeShipmentDialog: (state, action) => {
            state.shipmentDialog.open = false
        },
        setDetailsLoading: (state, action) => {
            state.detailsLoading = action.payload
        }
    },
    extraReducers: {
        [getCheckList.pending]: (state, action) => {
            state.checkListLoading = true
        },
        [getCheckList.rejected]: (state, action) => {
            state.checkListLoading = false
            state.checkList = []
        },
        [getCheckList.fulfilled]: (state, action) => {
            state.checkList = action.payload
            state.checkListLoading = false
        },
        [updateShipment.fulfilled]: (state) => {
            state.updateLoading = false
        },
        [updateShipment.pending]: (state) => {
            state.updateLoading = true
        },
        [updateShipment.fulfilled]: (state) => {
            state.updateLoading = false
        },
        [getPackingSlip.fulfilled]: (state, action) => {
            state.packingSlip = action.payload
        },
        [getShipmentDetails.fulfilled]: (state, { payload }) => {
            state.shipentDetails = payload.shipment;
        },
        [getShipmentDetails.rejected]: (state) => {
            state.shipentDetails = []
        },
    },
});

export const {
    enableSave,
    setFileIds,
    openShipmentDialog,
    closeShipmentDialog,
    setDetailsLoading
} = shipmentSlice.actions;

export const selectShipment = ({ backofficeApp }) => backofficeApp.shipment;

export default shipmentSlice.reducer;
