import Card from '@mui/material/Card';
import { styled } from '@mui/material/styles';
import CardContent from '@mui/material/CardContent';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { sumBy } from 'lodash';
import { useEffect, memo, useState } from 'react';
import { useDispatch } from 'react-redux';
import NoItemMessage from 'app/main/apps/common/NoItemMessage';
import { Loader } from 'app/main/apps/common/ListInfoMessage';
import { displayName, formatDateString, formatter, getCustomerBasedProfile, getDiscountAmtFromPercentage } from 'app/main/apps/common/AuraFunctions';
import { getContactInfo, getInvoiceDetails } from '../../store/orderSlice';

const Root = styled('div')(({ theme }) => ({
  '& table ': {
    '& th:first-of-type, & td:first-of-type': {
      paddingLeft: `${0}!important`,
    },
    '& th:last-child, & td:last-child': {
      paddingRight: `${0}!important`,
    },
  },

  '& .divider': {
    width: 1,
    backgroundColor: theme.palette.divider,
    height: 144,
  },

  '& .seller': {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.getContrastText(theme.palette.primary.dark),
    marginRight: -88,
    paddingRight: 66,
    width: 480,
    '& .divider': {
      backgroundColor: theme.palette.getContrastText(theme.palette.primary.dark),
      opacity: 0.5,
    },
  },
}));

const InvoiceTab = (props) => {
  const isDialog = props.type === 'dialog'
  const dispatch = useDispatch()
  const [values, setValues] = useState({})
  const [invoiceDetails, setInvoiceDetails] = useState({})
  const [contactInfo, setContactInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showAmountDueSection, setShowAmountDueSection] = useState(null);

  useEffect(() => {
    if (props.id && props.id !== 'new') {
      setLoading(true)
      dispatch(getInvoiceDetails({ [isDialog ? 'id' : 'orderId']: props.id }))
        .unwrap()
        .then((res) => {
          const groupedByOrderNo = res.invoice?.invoice_details?.reduce((acc, current) => {
            // Group items by order_no
            const key = current.order_no;
            if (!key) {
              return acc
            }
            if (!acc[key]) {
              acc[key] = [];
            }
            acc[key].push(current);
            return acc;
          }, {});
          setInvoiceDetails(groupedByOrderNo)
          const invoiceRes = res.invoice
          setValues(invoiceRes);
          setShowAmountDueSection(invoiceRes.paid_amount && Number(invoiceRes.paid_amount) > 0)
          setLoading(false)
        })

      dispatch(getContactInfo()).then((action) => {
        if (action.payload) {
          setContactInfo(action.payload);
        }
      });
    }
  }, [])

  if (loading) return <Loader />

  return (
    <Root className="grow shrink-0 p-0">
      {Object.keys(values).length ? (
        <Card className="w-xl mx-auto shadow-0">
          <CardContent className="p-88 print:p-0">

          <div className="flex justify-between items-start pb-8">
              <div className="flex items-start">
                 <img className="w-[18rem] pr-16 object-contain" src={getCustomerBasedProfile.companyLogo} alt="logo" />
               <div className="border-l-[4px] pl-16 pr-20 flex flex-col justify-between h-full" style={{ borderColor: 'gray', borderStyle: 'solid' }}>
                    <Typography color="textSecondary">{contactInfo && contactInfo.name ? contactInfo.name : ''}</Typography>
                    <Typography color="textSecondary">{contactInfo && contactInfo.location? contactInfo.location : ''}</Typography>
                    <Typography color="textSecondary">{contactInfo && contactInfo.phone ? contactInfo.phone : ''}</Typography>
                    <Typography color="textSecondary">{values.bank_details['Company Email']}</Typography>
               </div>
            </div>

            <div className="text-right">
                 <div className="grid grid-cols-2 gap-x-20">
                    <Typography variant="body1" className="font-medium text-gray-600">INVOICE </Typography>
                    <Typography variant="body1" className="text-gray-900 text-left">{values.invoice_no}</Typography>

                    <Typography variant="body1" className="font-medium text-gray-600">INVOICE DATE</Typography>
                    <Typography variant="body1" className="text-gray-900 text-left">{values.invoice_date ? formatDateString(values.invoice_date) : ' '}</Typography>

                    <Typography variant="body1" className="font-medium text-gray-600">DUE DATE</Typography>
                    <Typography variant="body1" className="text-gray-900 text-left">{values.due_date ? formatDateString(values.due_date) : ' '}</Typography>

                    <Typography variant="body1" className="font-medium text-gray-600">TOTAL DUE</Typography>
                    <Typography variant="body1" className="text-gray-900 text-left">{ formatter.format(values.due_amount) }</Typography>

                    <Typography variant="body1" className="font-medium text-gray-600">TERMS</Typography>
                    <Typography variant="body1" className="text-gray-900 text-left">{values.pay_term}</Typography>
              </div>
          </div>
      </div>

            <div className="flex mt-8">
                    <Typography variant="h6" className="ml-[6.8rem] text-gray-600 font-bold">CLIENT</Typography>
                <div className="pl-40">
                  <div className="border-l-[4px] pl-16 pr-20 flex flex-col justify-between h-full" style={{ borderColor: 'gray', borderStyle: 'solid' }}>

                    <Typography color="textSecondary" className="font-bold">{values.customer_name}</Typography>
                    {values.customer?.address_line_1 && <Typography color="textSecondary">{values.customer?.address_line_1}</Typography>}
                    {values.customer?.address_line_2 && <Typography color="textSecondary">{values.customer?.address_line_2}</Typography>}
                    {values.customer?.profile_name && <Typography color="textSecondary">{values.customer?.profile_name}</Typography>}
                    {values.customer?.phone && <Typography color="textSecondary">{values.customer?.phone}</Typography>}
                    {values.customer?.email && <Typography color="textSecondary">{values.customer?.email}</Typography>}
               </div>
           </div>
      </div>

            <div className="mt-64">
              <Table className="simple">
                <TableHead>
                  <TableRow>
                    <TableCell>Description</TableCell>
                    <TableCell>REFERENCE NO</TableCell>
                    <TableCell>DECEDENT</TableCell>
                    <TableCell align="right">PRICE</TableCell>
                    <TableCell align="right">QUANTITY</TableCell>
                    <TableCell align="right">DISCOUNT</TableCell>
                    <TableCell align="right">TOTAL</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    Object.keys(invoiceDetails).map((key, index) => {
                      return (
                        <>
                          <Typography className={`mt-10 font-medium text-lg ${index > 0 && 'mt-52'}`}>{`Order #${key}`}</Typography>
                          {invoiceDetails[key]?.map((orderDetail) => {
                            if (orderDetail?.name === 'Trade Discount') return <></>
                            return (
                              orderDetail.type !== 3 && <TableRow key={orderDetail.invoice_detail_id}>
                                <TableCell>
                                  <Typography variant="subtitle1">{orderDetail.name}</Typography>
                                </TableCell>
                                <TableCell>
                                  {orderDetail.customer_provider_number}
                                </TableCell>
                                <TableCell>
                                  {orderDetail.decedents}
                                </TableCell>
                                <TableCell align="right">
                                  {orderDetail.type ? '-' : formatter.format(orderDetail.unit_price)}
                                </TableCell>
                                <TableCell align="right">{orderDetail.type ? '-' : orderDetail.quantity}</TableCell>
                                <TableCell align="right">${getDiscountAmtFromPercentage(orderDetail?.discount, orderDetail?.unit_price, orderDetail?.quantity)}</TableCell>
                                <TableCell align="right">
                                  {orderDetail.amount !== null ? formatter.format(orderDetail.amount) : '-'}
                                </TableCell>
                              </TableRow>
                            )
                          })}
                          <TableRow>
                            <TableCell>
                              <Typography className="font-normal" variant="subtitle1" color="textSecondary">
                                ORDER SUBTOTAL
                              </Typography>
                            </TableCell>
                            <TableCell colSpan={8} align="right">
                              <Typography className="font-normal" variant="subtitle1" color="textSecondary">
                                {formatter.format(sumBy(invoiceDetails[key], (d) => {
                                  if (d.type !== 3) return d.amount
                                  return 0
                                }))}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </>
                      )
                    })}
                </TableBody >
              </Table>

              <Table className="simple mt-32">
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <Typography className="font-normal" variant="subtitle1" color="textSecondary">
                        SUBTOTAL
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography className="font-normal" variant="subtitle1" color="textSecondary">
                        {formatter.format(sumBy(values.invoice_details, (d) => {
                          if (d.type !== 3) return d.amount
                          return 0
                        }))}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography className="font-normal" variant="subtitle1" color="textSecondary">
                        Shipping Cost
                      </Typography>
                      <Typography className="font-normal text-13" variant="subtitle1" color="textPrimary">
                        {values.comments_to_send ? `(${values.comments_to_send})` : null}</Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography className="font-normal" variant="subtitle1" color="textSecondary">
                        {formatter.format(values.invoice_details?.find(d => d.type === 3)?.amount || 0)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  {
                    values.invoice_details?.find(d => d.type === 4)?.name && (
                      <TableRow>
                        <TableCell>
                          <Typography className="font-normal" variant="subtitle1" color="textSecondary">
                            {values.invoice_details?.find(d => d.type === 4)?.name}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography className="font-normal" variant="subtitle1" color="textSecondary">
                            {formatter.format(values.invoice_details?.find(d => d.type === 4)?.amount ? -Math.abs(values.invoice_details.find(d => d.type === 4).amount) : 0)}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )
                  }
                  <TableRow>
                    <TableCell>
                      <Typography className="font-normal" variant="subtitle1" color="textSecondary">
                        {displayName?.tax || "TAX"}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography className="font-normal" variant="subtitle1" color="textSecondary">
                        {/* {formatter.format(
                          values.commission_rate ? values.commission_rate : 0
                        )} */}
                         {formatter.format(
                          values.tax ? values.tax : 0
                        )}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  {/* <TableRow>
                    <TableCell>
                      <Typography className="font-normal" variant="subtitle1" color="textSecondary">
                        DISCOUNT
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography className="font-normal" variant="subtitle1" color="textSecondary">
                        {formatter.format(props.order.discount)}
                      </Typography>
                    </TableCell>
                  </TableRow> */}
                  <TableRow>
                    <TableCell sx={{borderBottom: '0px !important'}}>
                      <Typography className="font-light" variant="h4" color="textSecondary">
                        {showAmountDueSection ? "INVOICE TOTAL" : "TOTAL"}
                      </Typography>
                    </TableCell>
                    <TableCell align="right"  sx={{borderBottom: '0px !important'}}>
                      <Typography className="font-light" variant="h4" color="textSecondary">
                        {formatter.format(values.invoice_amount)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  {showAmountDueSection ? (
                    <>
                      <TableRow>
                        <TableCell>
                          <Typography className="font-light mt-20" variant="h5" color="textSecondary" >
                            AMOUNT PAID
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography className="font-light mt-20" variant="h5" color="textSecondary" >
                            {formatter.format(values.paid_amount)}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography className="font-medium" variant="h5" color="textSecondary" >
                            BALANCE DUE
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography className="font-medium" variant="h5" color="textSecondary" >
                            {formatter.format(values.due_amount)}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </>
                  ) : (
                    <></>
                  )}
                </TableBody>
              </Table>
            </div>

            <div className={`${showAmountDueSection ? "mt-64" : "mt-96"}`}>
              {/* <Typography className="mb-8" variant="h5" color="textSecondary">
                Payment Information
              </Typography>
              <Typography color="textSecondary">Via ACH / Wire Transfer</Typography>
              <div>{values.bank_details['Bank Name'] || ''}</div>
              <div>{values.bank_details['Bank Street'] || ''}</div>
              <div>
                {values.bank_details['Bank City'] || ''}, {values.bank_details['Bank State'] || ''}, {values.bank_details['Bank Zipcode'] || ''}
              </div>
              <div>
                <span>Swift Code: </span>
                <span>{values.bank_details['Bank Swift Code'] || ''}</span>
              </div>
              <div>
                <span>Account: </span>
                <span>{values.bank_details['Bank Account'] || ''}</span>
              </div>
              <div>
                <span>Routing: </span>
                <span>{values.bank_details['Bank Routing'] || ''}</span>
              </div> */}

              <Typography className='mt-12' color="textSecondary">{values.bank_details['Check Payable Text'] || ''}</Typography>
              <span>{values.bank_details['Check Payable To'] || ''}</span>
              { values.pay_term && values.pay_term === 0 ?
                <Typography className={`${showAmountDueSection ? "mt-12" : "mt-24"} mb-24  print:mb-12`}  variant="body1">
                  Please pay immediately. Thank you for your business.
                </Typography>
                :
                <Typography className={`${showAmountDueSection ? "mt-12" : "mt-24"} mb-24  print:mb-12`}  variant="body1">
                  Please pay within {values.pay_term || 15} days. Thank you for your business.
                </Typography>
              }
            </div>
          </CardContent>
        </Card>
      ) :
        <NoItemMessage message="Invoice yet to be generated" />
      }
    </Root>
  );
};

export default memo(InvoiceTab);
