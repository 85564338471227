import { lazy } from 'react';

const Products = lazy(() => import('./products/Products'));
const Orders = lazy(() => import('./orders/Orders'));
const RequestQuote = lazy(() => import('./order/RequestQuote'));
const Order = lazy(() => import('./order/Order'));

const AllAppConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: 'products',
      element: <Products />,
    },
    {
      path: 'quotes',
      element: <Orders type="quote" for="quotes" module="quotes" customerModule="quotes_waiting_approvals"  />,
    },
    {
      path: 'orders',
      element: <Orders type="order" for="orders" module="orders" supplierModule="received_orders" />,
    },
    {
      path: 'rfqs',
      element: <Orders type="rfq" for="rfqs" module="rfqs" customerModule="quotes_requested" />,
    },
    {
      path: 'rfd',
      element: <Orders type="rfd" for="rfd" module="rfps" />,
    },
    {
      path: 'completed',
      element: <Orders type="completed" for="completed" module="rfp_completed" />,
    },
    {
      path: 'invoices',
      element: <Orders type="invoice" for="invoices" module="invoices" />,
    },
    {
      path: 'rfqs/admin-quotes/:orderId',
      element: <Order type="rfq" module="rfqs" />,
    },
    {
      path: 'rfqs/supplier-quotes/:orderId',
      element: <RequestQuote />,
    },
    {
      path: 'rfd/supplier-quotes/:orderId',
      element: <RequestQuote isOnlyArtDesign />,
    },
    // {
    //   path: 'rfqs/:orderId',
    //   element: <RequestQuote />,
    // },
    {
      path: 'rfqs/:orderId',
      element: <Order type="rfq" module="rfqs" />,
    },
    {
      path: 'supplier-invoices',
      element: <Orders type="invoice" for="supplier-invoices" module="supplier_invoices" />,
    },
    {
      path: 'shipments',
      element: <Orders type="shipment" for="shipments" module="shipments" />,
    },
    {
      path: 'orders/:orderId',
      element: <Order type="order" module="orders" />,
    },
    {
      path: 'rfq/:orderId',
      element: <Order type="rfq" module="rfqs" />,
    },
    {
      path: 'rfds/:orderId',
      element: <Order type="rfq" module="rfqs" />,
    },
    {
      path: 'quotes/:orderId',
      element: <Order type="quote" module="quotes" />,
    },
    {
      path: 'rfqs/request/:orderId',
      element: <Order type="rfq" module="rfqs" customerModule='quotes' />
    },
    {
      path: 'confirmed/:orderId',
      element: <Order type="order" module="orders" />,
    },
    {
      path: 'completed/:orderId',
      element: <Order type="order" module="orders" />,
    },
    {
      path: 'awaiting-approval/:orderId',
      element: <Order type="order" module="orders" />,
    },
  ],
};

export default AllAppConfig;
