import withReducer from "app/store/withReducer";
import { useEffect, useRef, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FuseUtils from "@fuse/utils";
import { authPermissions } from "app/auth";
import AuraHeader from "app/main/apps/common/AuraHeader";
import {
  AuraSimplePageRoot,
  hasPermission,
  isAdminOrStaff,
  isCustomer,
} from "app/main/apps/common/AuraFunctions";
import ViewOrderDialog from "app/main/dashboards/main/dialogs/ViewOrderDialog";
import ShipmentsContent from "./ShipmentsContent";
import reducer from "../store";
import { getOrderStatuses } from "../../e-commerce/store/ordersSlice";
import getColumns from "../../e-commerce/orders/OrdersTableColumns";
import OrderDialog from "../../e-commerce/order/Dialogs/OrderDialog";
import { getShipmentTypes } from "../store/shipmentOrderSlice";

const Shipments = (props) => {
  const { type, permissionModule } = props;
  const dispatch = useDispatch();
  const pageLayout = useRef(null);
  const currentUser = useSelector(({ user }) => user);
  const isManage =
    currentUser &&
    FuseUtils.hasPermission(authPermissions.manage, currentUser.role);
  const shipmentTypeNames = useSelector(({ backofficeApp }) => backofficeApp?.shipmentOrder?.shipmentTypeNames);

  useEffect(() => {
    dispatch(getOrderStatuses({ params: { c: "outbound_shipment" } }));
    dispatch(getOrderStatuses({ params: { c: "shipment_type" } }));

    if(type === "outbound" && isManage && !shipmentTypeNames || shipmentTypeNames?.length <= 0) {
      dispatch(getShipmentTypes());
    }
  }, []);

  const getShipmentCategory = () => {
    if (type === "outbound" && isManage) return "outbound_shipments";
    if (type === "outbound" && isCustomer(currentUser.role))
      return "outbound_shipments_customer";
    return "outbound_shipments";
  };  
  const shipmentCategory = getShipmentCategory();

  const headerData = {
    headerTitle: isAdminOrStaff(currentUser) ? "Outbound Shipments" : "Shipments",
    pageLayout,
    isManage,
  };

  const columns = useMemo(
    () => getColumns(isManage, shipmentCategory),
    [isManage]
  );

  return (
    <>
      {hasPermission(currentUser, "view",  permissionModule, isManage) && <>
        <AuraSimplePageRoot
          header={<AuraHeader {...headerData} />}
          content={<ShipmentsContent isManage={isManage} columns={columns} />}
        />
        <ViewOrderDialog />
        <OrderDialog />
      </>}
    </>
  );
};

export default withReducer("backofficeApp", reducer)(Shipments);
