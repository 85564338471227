import themesConfig from 'app/configs/themesConfig';
import i18n from '../../i18n';

const backofficeLayout = {
  style: 'layout1', // layout1 layout2 layout3
  config: {
    navbar: {
      display: true,
      style: 'style-3',
      folded: true,
      position: 'left',
    },
    footer: {
      display: false,
      style: 'fixed',
    },
    rightSidePanel: {
      display: false,
    },
  }, // checkout default layout configs at app/theme-layouts for example  app/theme-layouts/layout1/Layout1Config.js
};

const portalLayout = {
  style: 'layout2',
  config: {
    mode: 'container',
    containerWidth: '100% !important',
    navbar: {
      display: true,
      style: 'fixed',
      folded: true,
      position: 'left',
    },
    toolbar: {
      display: true,
      style: 'fixed',
      position: 'above',
    },
    footer: {
      display: false,
      style: 'fixed',
    },
    rightSidePanel: {
      display: false,
    },
  }, // checkout default layout configs at app/theme-layouts for example  app/theme-layouts/layout1/Layout1Config.js
};

const settingsConfig = {
  layoutOptions: {
    backoffice: backofficeLayout,
    portal: portalLayout,
  },
  layout: backofficeLayout,
  customScrollbars: false,
  direction: i18n.dir(i18n.options.lng) || 'ltr', // rtl, ltr
  theme: {
    main: themesConfig.default,
    navbar: themesConfig.defaultDark,
    toolbar: themesConfig.default,
    footer: themesConfig.default,
  },
  /*
   To make whole app auth protected by default set defaultAuth:['admin','staff','user']
   To make whole app accessible without authorization by default set defaultAuth: null
   *** The individual route configs which has auth option won't be overridden.
   */
  defaultAuth: ['admin', 'staff', 'customer', 'supplier', 'sales'],
  /*
    Default redirect url for the logged-in user,
   */
  loginRedirectUrl: '/',
};

export default settingsConfig;
