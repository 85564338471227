import {
  AppBar,
  Button,
  DialogActions,
  DialogContent,
  Toolbar,
  Typography,
} from "@mui/material";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {
  openDialog,
  closeDialog as fuseCloseDialog,
} from "app/store/fuse/dialogSlice";

export default function showSingleButtonConfirmation(dispatch, props) {
  dispatch(
    openDialog({
      maxWidth: props?.maxWidth || "sm",
      fullWidth: true,
      children: (
        <>
          <AppBar position="static" elevation={0}>
            <Toolbar className="flex w-full">
              <Typography variant="subtitle1" color="inherit">
                {props?.title}
              </Typography>
              <IconButton
                aria-label="close"
                onClick={() => {
                  if (props.onActionBtnClick) props.onActionBtnClick();
                  dispatch(fuseCloseDialog());
                }}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: "white",
                }}
              >
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <DialogContent>
            <Typography id="alert-dialog-description">
              {props?.message}
            </Typography>
          </DialogContent>
          <DialogActions>
            {props?.displayCancelBtn &&
               <Button
                variant="contained"
                color="secondary"
                type="submit"
                onClick={() => {
                  dispatch(fuseCloseDialog());
                }}
              >
                {props?.cancelActionName || "Cancel"}
              </Button>
            }
            <Button
              variant="contained"
              color="secondary"
              type="submit"
              onClick={() => {
                if (props.onActionBtnClick) props.onActionBtnClick();
                dispatch(fuseCloseDialog());
              }}
            >
              {props?.actionName || "Ok"}
            </Button>
          </DialogActions>
        </>
      ),
    })
  );
}
