import * as yup from 'yup';
import format from 'date-fns/format';
import { checkNullOrUndefined, emptySchema, formatDateString } from 'app/main/apps/common/AuraFunctions';
import { isNumber } from 'lodash';
import { shipmentSchema } from 'app/main/apps/backoffice/shipments/dialogs/ShipmentDialog';


function statusFormInitValues(data) {
  return {
    id: data && data.id ? data.id : null,
    status: data && data.status ? data.status : '',
    note: '',
  };
}

function noteFormInitValues(data) {
  return {
    id: data && data.id ? data.id : null,
    note: data && data.note ? data.note : '',
  };
}

function rejectShipmentFormInitValues(data) {
  return {
    notes: data && data.notes ? data.notes : '',
  };
}

function shipmentDispatchFormInitValues(data) {
  return {
    id: data && data.id ? data.id : null,
    note: data && data.note ? data.note : '',
    schedule: data && data.schedule ? formatDateString(data.schedule, 'yyyy-MM-dd') : '',
    notes: data && data.notes ? data.notes : '',
    shipment_type: data && data.shipment_type ? data.shipment_type : '',
    pallet_count: data && data.pallet_count ? data.pallet_count : '',
  }
}

function editShipmentFormInitValues(data) {
  return {
    id: data && data.shipment_id ? data.shipment_id : null,
    note: data && data.note ? data.note : '',
    schedule: data && data.schedule ? formatDateString(data.schedule, 'yyyy-MM-dd') : '',
    shipment_type: data && data.shipment_type ? data.shipment_type : '',
    pallet_count: data && data.pallet_count ? data.pallet_count : '',
  }
}

function containerNumberFormInitValues(data) {
  return {
    shipment_id: data && data?.shipment_id,
    container_number: data && data?.container_number
  }
}

function confirmFormInitValues(data) {
  return {
    id: data && data.id ? data.id : null,
    note: '',
    order_no: data && data.order_no ? data.order_no : '',
  };
}

function addressFormInitValues(data) {
  return {
    id: data && data.id ? data.id : null,
    cid: data && data.customer && data.customer.id ? data.customer.id : null,
    address_id: data && data.customer && data.customer.address_id ? data.customer.address_id : null,
    address: data && data.customer && data.customer.address ? data.customer.address : null,
  };
}

function paymentFormInitValues(data, type) {
  return {
    id: data?.id,
    data: data
      ? {
        id: data.id,
        amount: type === "advance_payment" && data.advance_payment_data ? data?.advance_payment_data?.order_amount : data.invoice_amount,
        paid_amount: type === "advance_payment" && data.advance_payment_data ? data?.advance_payment_data?.paid_amount : data.paid_amount || 0,
        balance_amount: type === "advance_payment" && data.advance_payment_data ? data?.advance_payment_data?.balance_amount : data.due_amount,
        payment_status: data.payment_status,
      }
      : null,
    payment_mode:
      data && data.payment && data.payment.payment_type ? data.payment.payment_type : null,
    date:
      data && data.payment && data.payment.date
        ? data.payment.date
        : format(new Date(), 'yyyy-MM-dd'),
    amount: data && data.payment && data.payment.amount ? data.payment.amount : '',
    description: data && data.payment && data.payment.description ? data.payment.description : '',
  };
}

function completeShipmentFormInitValues(data) {
  return {
    shipment_id: data?.id,
    container_tracking_number: '',
    eta_date: '',
    shipping_company: null,
    invoice_number: ''
  };
}

function confirmInvoiceFormInitValues(data) {
  return {
    id: data && data.id ? data.id : null,
    shipping_cost: data && data.shipping_cost ? data.shipping_cost : null,
    comments_to_send: data && data.comments_to_send ? data.comments_to_send : '',
    shipment_type: data && data.shipment_type ? data.shipment_type : '',
    pallet_count: data && data.pallet_count ? data.pallet_count : '',
  }
}

function editOrderFormInitValues(data) {
  console.log('data', data)
  return {
    id: data && data.id ? data.id : null,
    eta_date: data && data.eta_date ? formatDateString(data.eta_date, 'yyyy-MM-dd') : '',
    location: data && data.location ? data.location : '',
    order_type: data && data.order_type ? { name: data.order_type.name } : null,
    ship_to: data && data.ship_to ? data.ship_to : '',
    sales_person: data && data?.sales_person,
    order_no: data?.order_no,
    confirmed_order: data ? data?.confirmed_order : false,
  }
}

function updateInvoiceFormInitValues(data) {
  return {
    id: data && data.id ? data.id : null,
    shipping_cost: data && data.shipping_cost ? data.shipping_cost : null,
    tax: data && data.tax ? data.tax : null,
  }
}

/**
 * Form Init Values
 */
export default function getInitValues(type, data) {
  if (type && type === 'container_number') {
    return containerNumberFormInitValues(data)
  }
  if (type && type === 'status') {
    return statusFormInitValues(data);
  }
  if (type && type === 'note') {
    return noteFormInitValues(data);
  }
  if (type && type === 'cus_reject_shipment') {
    return rejectShipmentFormInitValues(data);
  }
  if (type && type === 'confirm') {
    return confirmFormInitValues(data);
  }
  if (type && type === 'address') {
    return addressFormInitValues(data);
  }
  if (type && type === 'advance_payment') {
    return paymentFormInitValues(data, type);
  }
  if (type && type === 'payment') {
    return paymentFormInitValues(data);
  }
  if (type && type === 'complete_shipment') {
    return completeShipmentFormInitValues(data);
  }
  if (type && type === 'confirm_invoice') {
    return confirmInvoiceFormInitValues(data);
  }
  if (type && type === 'edit_order') {
    return editOrderFormInitValues(data)
  }
  if (type && type === 'update_invoice') {
    return updateInvoiceFormInitValues(data)
  }
  if(type && type === 'dispatch_shipment') {
    return shipmentDispatchFormInitValues(data)
  }
  if(type && type === 'editShipment') {
    return editShipmentFormInitValues(data)
  }
  return { id: data && data.id ? data.id : null };
}

const statusSchema = yup.object().shape({
  status: yup.object().required('You must enter a status'),
});

const noteSchema = yup.object().shape({
  note: yup.string().required('You must enter a note'),
});

const rejectShipmentNoteSchema = yup.object().shape({
  notes: yup.string().required('Please provide a reason for rejecting the shipmemt before submitting'),
});


const dispatchSchema = yup.object().shape({
  schedule: yup.string().required('Required'),
});

const containerNumberSchema = yup.object().shape({
  container_number: yup.string().required('Container number is required'),
});

const reviewNoteSchema = yup.object().shape({
  isInternalNotes: yup.boolean(),
});

const confirmSchema = yup.object().shape({
  order_no: yup.string().required('You must enter a order no'),
});

const addressSchema = yup.object().shape({
  address: yup.object().shape({
    address_line1: yup.string().required('You must enter a value'),
  }),
});

const paymentSchema = yup.object().shape({
  payment_mode: yup
    .object()
    .shape({
      name: yup.string().required('You must enter a value'),
    })
    .required('You must select a type'),
  data: yup.object().shape({
    balance_amount: yup.number().nullable(),
    paid_amount: yup.number().nullable(),
  }),
  amount: yup.number().when('payment_mode.name', (paymentType) => {
    if (paymentType === 'Refund') {
      return yup
        .number()
        .nullable()
        .min(0.01, 'You must enter a valid amount')
        .typeError('You must enter a amount')
        .required('You must enter a amount')
        .max(yup.ref('data.paid_amount'), 'Refund amount must be less than paid amount');
    }
    return yup
      .number()
      .nullable()
      .min(0.01, 'You must enter a valid amount')
      .typeError('You must enter a amount')
      .required('You must enter a amount')
      .max(yup.ref('data.balance_amount'), 'Amount must be equal to or less than the remaining balance');
  }),
});

const completeShipmentSchema = yup.object().shape({
  container_tracking_number: yup
    .string()
    .required('You must enter tracking number')
    .min(11, 'Minimum 11 characters')
    .max(11, 'Maximum 11 characters'),
  shipping_company: yup.object().required('Required'),
  eta_date: yup.string().required('Required'),
  invoice_number: yup.string().required('You must enter invoice number').max(20, 'Maximum 20 characters')
});

const confirmInvoiceSchema = yup.object().shape({
  shipping_cost: yup.number()
    .nullable()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .required("Required"),
  tax: yup.number()
    .nullable()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .required("Required"),
  comments_to_send: yup.string().nullable()
})

const updateInvoiceSchema = yup.object().shape({
  shipping_cost: yup.number()
    .nullable()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .required("Required"),
  tax: yup.number()
    .nullable()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .required("Required"),
})

const editOrderSchema = yup.object().shape({
  location: yup.string().nullable().required('You must enter a location'),
  order_type: yup.object().nullable().required('You must select a order type'),
  eta_date: yup.string().nullable(),
  order_no: yup.string().nullable().required('You must enter a order no'),
})

const marginValidations = () => {
  const yupMarginValidation = yup
    .number()
    .nullable()
    .required("Required")
    .transform((value) => (Number.isNaN(value) ? undefined : value))
    .min(0, "Min 0")
    .max(100, "Max 100")
    .test({
      message: "Max 2 decimal",
      test: (val) => {
        if (val && val > 0 && !/^\d+(\.\d{1,2})?$/.test(val)) {
          return false;
        }
        return true;
      },
    })
    .test({
      message: "Enter above 0",
      test: (val, { parent }) => {
        if (
          parent.unit_price &&
          Number(parent.unit_price) > 0 &&
          (Number.isNaN(val) ||
            val === "" ||
            val === "" ||
            checkNullOrUndefined(val) ||
            Number(val) <= 0)
        ) {
          return false;
        }
        return true;
      },
    });
  return yupMarginValidation;
};

const priceValidations = (type) => {
  const yupPriceValidation = yup
    .number()
    .nullable()
    .required("Required")
    .transform((value) => (Number.isNaN(value) ? undefined : value))
    .positive("Min 0")
    .min(0, "Min 0")
    .test({
      message: "Max 2 decimal",
      test: (val) => {
        if (val && val > 0 && !/^\d+(\.\d{1,2})?$/.test(val)) {
          return false;
        }
        return true;
      },
    })
    .test("Unit Price Required", "Enter above 0", function (value) {
      const { from } = this;
      const [parent1, parent2] = from;
      const unitPrice = parent1?.value?.unit_price;
      const fobPrice = parent2?.value?.unit_price;
      if (
        fobPrice &&
        Number(fobPrice) > 0 &&
        (Number.isNaN(unitPrice) ||
          unitPrice === "" ||
          unitPrice === "" ||
          checkNullOrUndefined(unitPrice) ||
          Number(unitPrice) <= 0)
      ) {
        return false;
      }
      return true;
    });
  return yupPriceValidation;
};

export const chooseSupplierSchema = yup.object().shape({
  tariff: yup
    .number()
    .required("Required")
    .transform((value) => (Number.isNaN(value) ? undefined : value))
    .positive("Min 0")
    .min(0, "Min 0"),
  shipping: yup
    .number()
    .required("Required")
    .transform((value) => (Number.isNaN(value) ? undefined : value))
    .positive("Min 0")
    .min(0, "Min 0"),
  order_details: yup.array().of(
    yup.object().shape({
      weight: yup
        .number()
        .required("Required")
        .transform((value) => (Number.isNaN(value) ? undefined : value))
        .positive("Min 0")
        .min(0, "Min 0"),
      discount: yup.string().nullable(),
      margin: marginValidations(),
      unit_price: yup.string().nullable(),
      product: yup.object().shape({
        unit_price: priceValidations("unitPrice"),
      }),
      vas: yup
        .object()
        .shape({
          imagePrice: yup
            .number()
            .nullable()
            .transform((value) => (Number.isNaN(value) ? undefined : value))
            .min(0, "Min 0")
            .test({
              message: "Max 2 decimal",
              test: (val) => {
                if (val && val > 0 && !/^\d+(\.\d{1,2})?$/.test(val)) {
                  return false;
                }
                return true;
              },
            })
            .test("Image Price Required", "Required", function (value) {
              const { from } = this;
              const [parent1, parent2] = from;
              const vas = parent2?.value?.vas;
              const imagePrice = parent1?.value?.imagePrice;
              if ((vas?.images?.[0]?.name || vas?.images?.[0]?.value || vas?.images?.[0]?.url) &&
                (Number.isNaN(imagePrice) || imagePrice === "" || imagePrice === "" || checkNullOrUndefined(imagePrice) )) {
                return false;
              }
              return true;
            }),
          textPrice: yup
            .number()
            .nullable()
            .transform((value) => (Number.isNaN(value) ? undefined : value))
            .min(0, "Min 0")
            .test({
              message: "Max 2 decimal",
              test: (val) => {
                if (val && val > 0 && !/^\d+(\.\d{1,2})?$/.test(val)) {
                  return false;
                }
                return true;
              },
            })
            .test("Text Price Required", "Required", function (value) {
              const { from } = this;
              const [parent1, parent2] = from;
              const vas = parent2?.value?.vas;
              const textPrice = parent1?.value?.textPrice;
              if ((vas?.text?.[0]?.name || vas?.text?.[0]?.value || vas?.text?.[0]?.url) &&
                (Number.isNaN(textPrice) || textPrice === "" || textPrice === "" || checkNullOrUndefined(textPrice) )) {
                return false;
              }
              return true;
            }),
        })
        .default(null)
        .nullable(),
    }, [
      ['margin', 'product.unit_price']
    ])
  ),
});

/**
 * Form Validation Schema
 */
export function getSchema(type) {
  if (type && type === 'container_number') {
    return containerNumberSchema
  }
  if (type && type === 'status') {
    return statusSchema;
  }
  if (type && type === 'review_note') {
    return reviewNoteSchema
  }
  if (type && type === 'note' || type === 'rejectQuote') {
    return noteSchema;
  }
  if (type && type === 'rejectShipment') {
    return rejectShipmentNoteSchema;
  }
  if (type && type === 'confirm') {
    return confirmSchema;
  }
  if (type && type === 'address') {
    return addressSchema;
  }
  if (type && type === 'payment') {
    return paymentSchema;
  }
  if (type === 'complete_shipment') {
    return completeShipmentSchema;
  }
  if (type === 'confirm_invoice') {
    return confirmInvoiceSchema
  }
  if (type === 'update_invoice') {
    return updateInvoiceSchema
  }
  if (type === 'edit_order') {
    return editOrderSchema
  }
  if (type === 'dispatch_shipment') {
    return dispatchSchema
  }
  if(type === 'editShipment') {
    return shipmentSchema;
  }
  return emptySchema;
}
