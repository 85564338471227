const clientConfig = {
  psg: {
    companyLogo: "assets/images/logos/logo.jpg",
    navbarLogo: "assets/images/logos/logow.png",
    welcomeText: `PS Granite is a manufacturer and wholesaler of granite monuments that sell various
    products to retailers along with value-added services. We have a manufacturing facility
    in India and also import from China. We have been serving the monument industry since
    1997. We provide high-quality products with timely delivery and great craftsmanship at a
    competitive price.`
  },
  nexora: {
    companyLogo: "assets/images/customer/nexora/logo.jpg",
    navbarLogo: "assets/images/customer/nexora/logo_nav.jpg",
    welcomeText: `Nexora is a manufacturer and wholesaler of granite monuments that sell various
    products to retailers along with value-added services. We have a manufacturing facility
    in India and also import from China. We have been serving the monument industry since
    1997. We provide high-quality products with timely delivery and great craftsmanship at a
    competitive price.`
  },
  maggardlaserart: {
    companyLogo: "assets/images/customer/maggardlaserart/logo.jpg",
    navbarLogo: "assets/images/customer/maggardlaserart/logo.jpg",
    welcomeText: `Since 1978, Maggard Memorials and Laser Art Technology has provided elegant
    granite headstones, memorials, and architectural elements. We are the only company in the 
    Tri-State area that owns and operates our own laser engraver, and all our laser etching is done in-house. 
    For a FREE in-home consultation, contact us.`
  }
}

export default clientConfig