import { formatDateString, selectAmount, formatter, numberFormatter } from 'app/main/apps/common/AuraFunctions';
import Box from '@mui/material/Box';
import { Chip, Tooltip } from '@mui/material';
import AuraTableIndeterminateCheckbox from 'app/main/apps/common/AuraTableIndeterminateCheckbox';
import TextFilter, { DateFilter, NumberFilter } from '../../common/FiltersComponent';
import OrdersSelectFilter, { OrdersTypeFilter, ShipmentTypeFilter } from './ColumnFilters';
import OrdersRowActions from './OrdersRowActions';
import OrderViewAction from '../../common/OrderViewAction';
import _ from '../../../../../@lodash';

function getColumns(isManage, type, selection, role, filterValue, isSupplierInvoice) {
  const typeName = isSupplierInvoice === true && "Supplier Invoice";

  const getTitle = () => {
    const title = _.capitalize(type)
    if (['fulfilled', 'in-production', 'approval', 'confirmed', 'container'].includes(type))
      return 'Order'
    if (['completed', 'rfd'].includes(type))
      return 'Rfp'
    return title
  }

  const hideContainernoOrCtn = () => {
    if (role === 'supplier') {
      return ['rfq', 'quote', 'rfd', 'completed', 'confirmed', 'order', 'in-production'].includes(type)
    }
    if (role === 'customer') {
      return true
    }
    return 'order'
  }

  const hideContainernoForOrder = () => {
    return ['rfq', 'quote', 'rfd', 'completed', 'confirmed', 'in-production'].includes(type)
  }

  const hideRequestedBy = () => {
    let hide = false
    if (role !== 'customer') {
      hide = true
    } else if (!['rfq', 'quote', 'order'].includes(type)) {
      hide = true
    }
    return hide
  }

  const getEtaHeader = () => {
    let header;
    if (type === 'rfq') {
      header = 'Quote ETA';
    } else if (['rfd', 'completed'].includes(type)) {
      header = 'Needed By';
    } else {
      header = 'Delivery ETA';
    }
    return header
  }

  const ellipsisOptions = {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textTransform: 'capitalize',
  }

  const HeaderType = `${_.capitalize(type)}`;
  const filterValueProp = !['supplier', 'supplier_archive'].includes(filterValue)
  const hideSupplierNameColumn = ['customer', 'customer_archive'].includes(filterValue)
  const orderColumns = [
    {
      id: 'selection',
      className: 'w-1',
      sortable: false,
      disablePadding: true,
      requiredPermissions: true,
      // hideForType: !selection,
      Header: ({ getToggleAllRowsSelectedProps }) => (
        <div>{/* <AuraTableIndeterminateCheckbox {...getToggleAllRowsSelectedProps()} /> */}</div>
      ),
      Cell: ({ row }) => (

        <div>
          {/* {row.original.amount && row.original.amount > 0 ? ( */}
          <AuraTableIndeterminateCheckbox
            {...row.getToggleRowSelectedProps()}
            onClick={(ev) => ev.stopPropagation()}
          />
          {/* ) : null} */}
        </div>
      ),
    },
    {
      Header: `${getTitle()}  No`,
      accessor: 'order_no',
      className: '',
      sxStyle: {
        width: 80,
        maxWidth: 80,
      },
      headerSxStyle: {
        width: 80,
        maxWidth: HeaderType === 'Approval' ? 150 : 80,
      },
      sortable: true,
      showFilter: true,
      Filter: TextFilter,
      Cell: ({ row, cell }) => OrderViewAction({ row, cell, stage: type, type: typeName }),
      paramKey: 'orderNo',
    },
    // Need to map this , once filter is fixed

    {
      Header: filterValueProp ? 'Customer Name' : 'Supplier Name',
      accessor: 'name',
      className: '',
      sxStyle: {
        width: 150,
        maxWidth: 'max-content',
      },
      headerSxStyle: {
        width: 150,
        maxWidth: 'max-content',
      },
      sortable: true,
      showFilter: true,
      paramKey: filterValue?.includes('supplier') && type === 'order' ? 'supplier_name' : 'name',
      Cell: ({ row }) => {
        return (
          row.original.company_name && (
            <Tooltip title={row.original.company_name}>
              <div className='less-text'>
                {row.original.company_name}
              </div>
            </Tooltip>
          )
        );
      },
      Filter: TextFilter,
      requiredPermissions: true,
    },
    {
      Header: 'Customer Name',
      accessor: 'customer_name',
      className: '',
      sxStyle: {
        width: 150,
        maxWidth: 'max-content',
      },
      headerSxStyle: {
        width: 150,
        maxWidth: 'max-content',
      },
      hideForType: type === 'order' && filterValue?.includes('') ? hideSupplierNameColumn : true,
      sortable: true,
      showFilter: true,
      paramKey: 'name',
      Cell: ({ row }) => {
        return (
          row.original.customer_name && (
            <Tooltip title={row.original.customer_name}>
              <div className='less-text'>
                {row.original.customer_name}
              </div>
            </Tooltip>
          )
        );
      },
      Filter: TextFilter,
      requiredPermissions: true,
    },
    {
      Header: `${getTitle()}  Date`,
      accessor: 'date',
      className: '',
      sxStyle: {
        width: 100,
        maxWidth: 100,
      },
      headerSxStyle: {
        width: 120,
        maxWidth: 120,
      },
      sortable: true,
      showFilter: true,
      Filter: DateFilter,
      paramKey: 'date',
      hideForType: ['rfd', 'completed'].includes(type),
      Cell: ({ row }) => {
        if (row.original.date)
          return <div>{formatDateString(row.original.date)}</div>;
        return <div />
      },
    },
    {
      Header: 'Reference No',
      accessor: 'reference_no',
      className: '',
      sxStyle: {
        width: 100,
        maxWidth: 100,
      },
      headerSxStyle: {
        width: 100,
        maxWidth: 100,
      },
      sortable: true,
      showFilter: true,
      Filter: TextFilter,
      paramKey: 'reference',
      hideForType: !['order', 'rfq', 'quote'].includes(type) && role !== 'customer',
      Cell: ({ row }) => {
        return (
          row.original.customer_provider_number && (
            <Tooltip title={row.original.customer_provider_number}>
              <Box
                sx={{ ...ellipsisOptions }}
                style={{ textTransform: 'capitalize' }}
              >
                {isManage ? row.original.customer_provider_number : row.original.customer_provider_number}
              </Box>
            </Tooltip>
          )
        );
      },
    },
    {
      Header: 'Decedent',
      accessor: 'decedents',
      className: '',
      sxStyle: {
        width: 150,
        maxWidth: 'max-content',
      },
      headerSxStyle: {
        width: 150,
        maxWidth: 'max-content',
      },
      sortable: true,
      showFilter: true,
      hideForType: ['rfd', 'completed'].includes(type),
      Filter: TextFilter,
      paramKey: 'decedent',
      Cell: ({ row }) => {
        return (
          row.original.decedents && (
            <Tooltip title={row.original.decedents}>
              <div className='less-text'>
                {row.original.decedents}
              </div>
            </Tooltip>
          )
        );
      },
    },
    {
      Header: getEtaHeader(),
      accessor: 'eta_date',
      className: '',
      sxStyle: {
        width: 100,
        maxWidth: 100,
      },
      headerSxStyle: {
        width: 120,
        maxWidth: 120,
      },
      sortable: true,
      showFilter: true,
      Filter: DateFilter,
      paramKey: 'deliveryETA',
      Cell: ({ row }) => {
        if (row.original.eta_date)
          return <div>{formatDateString(row.original.eta_date)}</div>;
        return <div />
      },
    },
    {
      Header: 'Requested Date',
      accessor: 'date',
      className: '',
      sxStyle: {
        width: 100,
        maxWidth: 100,
      },
      headerSxStyle: {
        width: 140,
        maxWidth: 140,
      },
      sortable: true,
      showFilter: true,
      Filter: DateFilter,
      paramKey: 'deliveryETA',
      hideForType: !['rfd', 'completed'].includes(type),
      Cell: ({ row }) => {
        if (row.original.date)
          return <div>{formatDateString(row.original.date)}</div>;
        return <div />
      },
    },
    {
      Header: 'Weight',
      accessor: 'weight',
      className: 'w-1',
      sxStyle: {
        width: 100,
        maxWidth: 100,
      },
      sortable: true,
      showFilter: true,
      hideForType: ['rfq', 'order', 'rfd', 'completed'].includes(type),
      Filter: NumberFilter,
      paramKey: 'weight',
      requiredPermissions: true,
      Cell: ({ row }) => {
        return <div>{row.original.weight ? `${row.original.weight} lbs` : '-'} </div>;
      },
    },
    {
      Header: 'Qty',
      accessor: 'quantity',
      className: '',
      sxStyle: {
        width: 60,
        maxWidth: 60,
      },
      headerSxStyle: {
        width: role === 'customer' ? '60' : '80',
        maxWidth: role === 'customer' ? '60' : '80',
      },
      sortable: true,
      showFilter: true,
      Filter: NumberFilter,
      paramKey: 'quantity',
      hideForType: ['rfd', 'completed'].includes(type)
    },
    {
      Header: 'Amount',
      accessor: 'displayAmount',
      className: '',
      sxStyle: {
        width: 60,
        maxWidth: 800,
      },
      headerSxStyle: {
        width: role === 'customer' ? '60' : '120',
        maxWidth: role === 'customer' ? '60' : '120',
      },
      sortable: true,
      showFilter: true,
      hideForType: ['rfq', 'rfd', 'completed'].includes(type),
      Filter: NumberFilter,
      paramKey: 'amount',
      Cell: ({ row }) => {
        return (
          <div>
            {formatter.format(selectAmount(row.original.all_invoices_pre_tax_total, row.original.displayAmount))}
          </div>
        );
      },
    },

    {
      Header: `Order Type`,
      accessor: 'order_type',
      className: '',
      sxStyle: {
        width: 80,
        maxWidth: 800,
      },
      headerSxStyle: {
        width: 100,
        maxWidth: 100,
      },
      sortable: false,
      showFilter: true,
      Filter: OrdersTypeFilter,
      // requiredPermissions: true,
      hideForType: (role === 'supplier' || role === 'customer') ? !['order', 'fulfilled', 'confirmed'].includes(type) : !['rfq', 'quote', 'order'].includes(type),
      paramKey: 'order_type',
    },

    {
      Header: `Container No`,
      accessor: 'container_number',
      className: '',
      sxStyle: {
        width: 80,
        maxWidth: 80,
      },
      headerSxStyle: {
        width: 100,
        maxWidth: 100,
      },
      sortable: false,
      showFilter: true,
      Filter: TextFilter,
      Cell: ({ row, cell }) => OrderViewAction({ row, cell, stage: type, showEditAction: role === 'supplier' && ['fulfilled'].includes(type), rowValue: row.original.container_number, paramKey: 'container_number', styles: ellipsisOptions, type: typeName }),
      // requiredPermissions: true,
      hideForType: hideContainernoForOrder(),
      paramKey: 'container_number',
    },
    {
      Header: 'CTN',
      accessor: 'container_tracking_number',
      className: '',
      sxStyle: {
        width: 80,
        maxWidth: 180,
      },
      headerSxStyle: {
        width: 80,
        maxWidth: 80,
      },
      sortable: true,
      showFilter: true,
      hideForType: hideContainernoOrCtn(),
      // requiredPermissions: true,
      Filter: TextFilter,
      Cell: ({ row }) => {
        return <div>{row.original.container_tracking_number ? row.original.container_tracking_number : ''}</div>
      },
      paramKey: 'container_tracking_number',
    },
    {
      Header: 'Status',
      accessor: 'status.name',
      className: '',
      sxStyle: {
        width: 130,
        maxWidth: 130,
      },
      headerSxStyle: {
        width: 130,
        maxWidth: 130,
      },
      sortable: true,
      showFilter: true,
      filter: 'equals',
      Filter: OrdersSelectFilter,
      paramKey: 'status',
      type,
      Cell: ({ row }) => {
        return (
          row.original.status && (
            <Tooltip title={row.original.status.name}>
              <Box
                sx={{ ...ellipsisOptions }}
                style={{ textTransform: 'capitalize' }}
              >
                {isManage ? row.original.status.name : row.original.status.display_name}
              </Box>
            </Tooltip>
          )
        );
      },
    },
    {
      Header: 'Drawing Required',
      accessor: 'drawing_required_icon',
      sxStyle: {
        width: "70px !important",
        maxWidth: "70px !important",
        whiteSpace: 'normal !important',
        wordWrap: 'break-word !important'
      },
      headerSxStyle: {
        width: "70px !important",
        maxWidth: "70px !important",
        verticalAlign: "text-top",
        whiteSpace: 'normal !important',
        wordWrap: 'break-word !important'
      },
      sortable: false,
      showFilter: false,
      hideForType: !(['rfq'].includes(type)) || !(['rfq'].includes(type) && role === "supplier"),
      Cell: ({ row }) => {
        return (
          <div style={{ width: "80px", maxWidth: "80px" }}>
            {row.original.drawing_required_icon && row.original.drawing_required_icon === true &&
              <Chip color="success" size="small" label="Yes" />
            }
          </div>
        );
      },
    },
    {
      Header: 'Revision Notes',
      accessor: 'revision_note',
      sortable: false,
      showFilter: false,
      sxStyle: {
        width: 130,
        maxWidth: 130,
      },
      headerSxStyle: {
        width: 130,
        maxWidth: 130,
      },
      hideForType: !(['rfq'].includes(type)) || !(['rfq'].includes(type) && role === "supplier"),
      Cell: ({ row }) => {
        return (
          row.original.revision_note && (
            <Tooltip title={row.original.revision_note}>
              <div className='less-text'>
                {row.original.revision_note}
              </div>
            </Tooltip>
          )
        );
      },
    },
    /* {
      Header: 'Quote For Revision',
      accessor: 'revised_notes',
      sxStyle: {
        width: "80px !important",
        maxWidth: "80px !important",
        verticalAlign: "text-top",
      },
      headerSxStyle: {
        width: "80px !important",
        maxWidth: "80px !important",
        verticalAlign: "text-top",
        whiteSpace: 'normal !important',
        wordWrap: 'break-word !important'
      },
      sortable: false,
      showFilter: false,
      hideForType: !(['rfq'].includes(type)) || !(['rfq'].includes(type) && role === "supplier"),
      Cell: ({ row }) => {
        return (
          <div style={{width: "80px", maxWidth: "80px"}}>
            { row.original.drawing_required_icon && row.original.drawing_required_icon === true &&
              <Tooltip title={row.original.revised_notes || ""}>
                <FuseSvgIcon size={24} color="success" className='fuse-list-item-icon shrink-0 text-24' >
                material-solid:announcement
                </FuseSvgIcon>
              </Tooltip>
            }
          </div>
        );
      },
    }, */
    {
      Header: `Requested By`,
      accessor: 'requested_by',
      className: '',
      sxStyle: {
        width: 150,
        maxWidth: 'max-content',
      },
      headerSxStyle: {
        width: 150,
        maxWidth: 'max-content',
      },
      sortable: false,
      showFilter: true,
      Filter: TextFilter,
      // requiredPermissions: true,
      hideForType: hideRequestedBy(),
      paramKey: 'requested_by',
      Cell: ({ row }) => {
        return (
          row.original.requested_by && (
            <Tooltip title={row.original.requested_by}>
              <div className='less-text'>
                {row.original.requested_by}
              </div>
            </Tooltip>
          )
        );
      },
    },
    {
      Header: 'Quote Sent/Received',
      accessor: 'sent_to_supplier_count',
      className: 'align-top',
      sxStyle: {
        width: 100,
        maxWidth: 100,
      },
      sortable: false,
      showFilter: false,
      hideForType: type !== 'rfq',
      requiredPermissions: true,
      Cell: ({ row }) => {
        if (row.original.sent_to_supplier_count)
          return (
            <div className='flex flex-row'>
              {`${row.original.sent_to_supplier_count} / ${row.original.replied_quote_count}`}
              {(row.original.rejected_quote_count && Number(row.original.rejected_quote_count) > 0) ? <div className='text-red font-bold pl-5'>{`(${row.original.rejected_quote_count} Rejected)`}</div> : <></>}
            </div>
          );
        return ''
      },
    },
    {
      id: 'action',
      className: 'w-1',
      sxStyle: {
        width: 100,
        maxWidth: 120,
      },
      headerSxStyle: {
        width: 100,
        maxWidth: 100,
      },
      sortable: false,
      disableFilters: true,
      disablePadding: true,
      userManage: isManage,
      Cell: OrdersRowActions,
    },
  ];

  const invoiceColumns = [
    {
      id: 'selection',
      className: 'w-1',
      sortable: false,
      disablePadding: true,
      requiredPermissions: true,
      hideForType: !selection,
      Header: ({ getToggleAllRowsSelectedProps }) => (
        <div>{/* <AuraTableIndeterminateCheckbox {...getToggleAllRowsSelectedProps()} /> */}</div>
      ),
      Cell: ({ row }) => (
        <div>
          {row.original.amount && row.original.amount > 0 && (
            <AuraTableIndeterminateCheckbox
              {...row.getToggleRowSelectedProps()}
              onClick={(ev) => ev.stopPropagation()}
            />
          )}
        </div>
      ),
    },
    {
      Header: `${HeaderType}  No`,
      accessor: 'invoice_no',
      className: '',
      sxStyle: {
        width: 100,
        maxWidth: 'max-content',
      },
      headerSxStyle: {
        width: 100,
        maxWidth: 'max-content',
      },
      sortable: true,
      showFilter: true,
      Filter: TextFilter,
      Cell: ({ row, cell }) => OrderViewAction({ row, cell, stage: type, type: typeName }),
      paramKey: 'invoice_no',
    },
    {
      Header: 'Order No',
      accessor: 'orderNo',
      className: '',
      sxStyle: {
        width: 80,
        maxWidth: 180,
      },
      headerSxStyle: {
        width: 80,
        maxWidth: 80,
      },
      sortable: true,
      showFilter: true,
      hideForType: role !== "customer",
      // requiredPermissions: true,
      Filter: TextFilter,
      Cell: ({ row }) => {
        return <div>{row.original.order_no ? row.original.order_no : ''}</div>
      },
      paramKey: 'orderNo',
    },
    {
      Header: 'Name',
      accessor: isSupplierInvoice ? 'supplier.name' : 'customer_name',
      className: '',
      sxStyle: {
        width: "110px",
        maxWidth: "110px",
      },
      headerSxStyle: {
        width: "110px",
        maxWidth: "110px",
      },
      sortable: true,
      showFilter: true,
      paramKey: 'name',
      Cell: ({ row }) => {
        if (isSupplierInvoice) {
          return (
            row?.original?.supplier?.name && (
              <Tooltip title={row.original.supplier.name}>
                <Box
                  sx={{
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textTransform: 'capitalize',
                  }}
                >
                  {row?.original?.supplier?.name}
                </Box>
              </Tooltip>
            )
          );
        }
        return (
          row.original.customer_name && (
            <Box
              sx={{
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textTransform: 'capitalize',
              }}
            >
              {row.original.customer_name}
            </Box>
          )
        );
      },
      Filter: TextFilter,
      requiredPermissions: true,
    },
    {
      Header: 'Customer',
      accessor: 'customer_name',
      className: '',
      sxStyle: {
        width: "100px",
        maxWidth: "100px",
      },
      headerSxStyle: {
        width: "100px",
        maxWidth: "100px",
        verticalAlign: "text-top !important",
      },
      sortable: false,
      showFilter: false,
      paramKey: 'customer_name',
      hideForType: role === "customer" || role === "supplier" || !isSupplierInvoice,
      Cell: ({ row }) => {
        return (
          row.original.customer_name && (
            <Tooltip title={row.original.customer_name}>
              <Box
                sx={{
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textTransform: "capitalize",
                }}
              >
                {row.original.customer_name}
              </Box>
            </Tooltip>
          )
        );
      },
      Filter: TextFilter,
      requiredPermissions: true,
    },
    {
      Header: `${HeaderType}  Date`,
      accessor: 'invoice_date',
      className: '',
      sxStyle: {
        width: "110px",
        maxWidth: "110px",
      },
      headerSxStyle: {
        width: "110px",
        maxWidth: "110px",
      },
      sortable: true,
      showFilter: true,
      Filter: DateFilter,
      paramKey: 'invoice_date',
      Cell: ({ row, cell }) => {
        if (cell.value)
          return <div>{formatDateString(cell.value)}</div>;
        return <div />
      },
    },
    {
      Header: `Due Date`,
      accessor: 'due_date',
      className: '',
      sxStyle: {
        width: "110px",
        maxWidth: "110px",
      },
      headerSxStyle: {
        width: "110px",
        maxWidth: "110px",
      },
      sortable: true,
      showFilter: true,
      Filter: DateFilter,
      paramKey: 'due_date',
      Cell: ({ row, cell }) => {
        if (cell.value)
          return <div>{formatDateString(cell.value)}</div>;
        return <div />
      },
    },
    {
      Header: `Order Type`,
      accessor: 'order_type',
      className: '',
      sxStyle: {
        width: "80px",
        maxWidth: "80px",
      },
      headerSxStyle: {
        width: "80px",
        maxWidth: "80px",
        verticalAlign: "text-top !important",
      },
      sortable: false,
      showFilter: false,
      Filter: OrdersTypeFilter,
      requiredPermissions: true,
      paramKey: 'order_type',
      hideForType: role === "customer" || role === "supplier" || !isSupplierInvoice,
    },
    {
      Header: 'Status',
      accessor: 'status.name',
      className: '',
      sxStyle: {
        width: 120,
        maxWidth: 120,
      },
      headerSxStyle: {
        width: 120,
        maxWidth: 120,
      },
      sortable: true,
      showFilter: true,
      filter: 'equals',
      Filter: OrdersSelectFilter,
      paramKey: 'invoice_status',
      Cell: ({ row }) => {
        return (
          row.original.status && (
            <Tooltip title={row.original.status.name}>
              <Box
                sx={{
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textTransform: 'capitalize',
                }}
                style={{ textTransform: 'capitalize' }}
              >
                {isManage ? row.original.status.name : row.original.status.display_name}
              </Box>
            </Tooltip>
          )
        );
      },
      // requiredPermissions: true,
    },
    {
      Header: isSupplierInvoice ? 'Invoice' : 'Invoice Amount',
      accessor: 'invoice_amount',
      className: '',
      sxStyle: {
        width: 100,
        maxWidth: 100,
      },
      headerSxStyle: {
        width: 100,
        maxWidth: 100,
      },
      sortable: true,
      showFilter: true,
      Filter: NumberFilter,
      paramKey: 'invoice_amount',
      Cell: ({ row }) => <div>{formatter.format(row.original.invoice_amount)}</div>,
    },
    {
      Header: isSupplierInvoice ? 'Paid' : 'Paid Amount',
      accessor: 'paid_amount',
      className: '',
      sxStyle: {
        width: 100,
        maxWidth: 100,
      },
      headerSxStyle: {
        width: 100,
        maxWidth: 100,
      },
      sortable: true,
      showFilter: true,
      Filter: NumberFilter,
      paramKey: 'paid_amount',
      Cell: ({ row }) => <div>{formatter.format(row.original.paid_amount)}</div>,
    },
    {
      Header: isSupplierInvoice ? 'Due' : 'Due Amount',
      accessor: 'due_amount',
      className: '',
      sxStyle: {
        width: 100,
        maxWidth: 100,
      },
      headerSxStyle: {
        width: 100,
        maxWidth: 100,
      },
      sortable: true,
      showFilter: true,
      Filter: NumberFilter,
      paramKey: 'due_amount',
      Cell: ({ row }) => <div>{formatter.format(row.original.due_amount)}</div>
    },
    {
      Header: 'Container No',
      accessor: 'container_number',
      className: '',
      sxStyle: {
        width: 100,
        maxWidth: 100,
      },
      headerSxStyle: {
        width: 100,
        maxWidth: 100,
      },
      sortable: true,
      showFilter: true,
      Filter: TextFilter,
      Cell: ({ row, cell }) => OrderViewAction({ row, cell, stage: type, showEditAction: role === 'supplier' && ['fulfilled'].includes(type), rowValue: row.original.container_number, paramKey: 'container_number', styles: ellipsisOptions, type: typeName }),
      hideForType: role === 'customer',
      // Cell: ({ row }) => <div>{row.original.container_number}</div>
    },
    {
      Header: 'CTN',
      accessor: 'container_tracking_number',
      className: '',
      sxStyle: {
        width: 100,
        maxWidth: 120,
      },
      headerSxStyle: {
        width: 100,
        maxWidth: 100,
      },
      sortable: true,
      showFilter: true,
      Filter: TextFilter,
      paramKey: 'container_tracking_number',
      hideForType: role === 'customer',
      Cell: ({ row }) => {
        return (
          row.original.container_tracking_number && (
            <Tooltip title={row.original.container_tracking_number}>
              <Box
                sx={{
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                }}
              >
                {row.original.container_tracking_number}
              </Box>
            </Tooltip>
          )
        );
      },
    },
    {
      id: 'action',
      className: 'w-1',
      sxStyle: {
        width: 100,
        maxWidth: 100,
      },
      headerSxStyle: {
        width: 100,
        maxWidth: 100,
      },
      sortable: false,
      disableFilters: true,
      disablePadding: true,
      userManage: isManage,
      Cell: OrdersRowActions,
    },
  ];

  const shipmentColumns = [
    {
      Header: 'Container Number',
      accessor: 'container_number',
      className: '',
      sxStyle: {
        width: 200,
        maxWidth: 200,
      },
      headerSxStyle: {
        width: 200,
        maxWidth: 200,
      },
      sortable: true,
      showFilter: true,
      Filter: TextFilter,
      Cell: ({ row, cell }) => OrderViewAction({ row, cell, stage: type, showEditAction: role === 'supplier' && type === 'in-production', type: typeName }),
      paramKey: 'container_number',
      hideForType: type === 'container' ? false : isManage
    },
    {
      Header: 'Name',
      accessor: 'name',
      className: '',
      sxStyle: {
        width: 190,
        maxWidth: 190,
      },
      headerSxStyle: {
        width: 190,
        maxWidth: 190,
      },
      sortable: true,
      showFilter: true,
      Filter: TextFilter,
      Cell: ({ row }) => {
        return <Tooltip title={row?.original?.name}>
          <div style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}}>
            {row.original.name}
          </div>
        </Tooltip>
      },
      paramKey: (role !== 'supplier' && role !== 'customer' && ['container'].includes(type)) ? "customer_name" : 'name',
      hideForType: (!isManage || !['container'].includes(type)),
    },
    {
      Header: `Shipment Date`,
      accessor: 'shipment_date',
      className: '',
      sxStyle: {
        width: '100px !important',
        maxWidth: '100px !important',
      },
      headerSxStyle: {
        width: '100px !important',
        maxWidth: '100px !important',
      },
      sortable: true,
      showFilter: true,
      Filter: DateFilter,
      paramKey: 'shipment_date',
      Cell: ({ row, cell }) => {
        if (cell.value)
          return <Box sx={{
            width: '100px !important',
            maxWidth: '100px !important',
          }}>{formatDateString(cell.value)}</Box>;
        return <div />
      },
    },
    {
      Header: 'Weight',
      accessor: 'weight',
      className: '',
      sxStyle: {
        width: 100,
        maxWidth: 100,
      },
      headerSxStyle: {
        width: 100,
        maxWidth: 100,
      },
      sortable: true,
      showFilter: true,
      Filter: NumberFilter,
      Cell: ({ row }) => {
        if (row.original.weight)
          return <div>{(`${row.original.weight} lbs`)}</div>;
        return <div />
      },
      paramKey: 'weight',
    },
    {
      Header: 'Container Tracking Number',
      accessor: 'container_tracking_number',
      className: '',
      sxStyle: {
        width: 100,
        maxWidth: 100,
      },
      headerSxStyle: {
        width: 100,
        maxWidth: 100,
      },
      sortable: true,
      showFilter: true,
      Filter: TextFilter,
      Cell: ({ row }) => {
        return <div>{row.original.container_tracking_number}</div>
      },
      hideForType: hideContainernoOrCtn(),
      paramKey: 'container_tracking_number',
    },
    {
      Header: 'No of Orders',
      accessor: 'no_of_orders',
      className: '',
      sxStyle: {
        width: 80,
        maxWidth: 80,
      },
      headerSxStyle: {
        width: 80,
        maxWidth: 80,
      },
      sortable: true,
      showFilter: true,
      Filter: TextFilter,
      Cell: ({ row }) => <div>{row.original.no_of_orders}</div>,
      // paramKey: 'no_of_orders',
    },
    {
      Header: `Order Type`,
      accessor: 'order_type',
      className: '',
      sxStyle: {
        width: 80,
        maxWidth: 80,
      },
      headerSxStyle: {
        width: 80,
        maxWidth: 80,
      },
      sortable: false,
      showFilter: true,
      Filter: OrdersTypeFilter,
      hideForType: (!isManage || !['container'].includes(type)),
      paramKey: 'order_type',
    },
    {
      Header: 'Status',
      accessor: 'status.name',
      sxStyle: {
        width: 180,
        maxWidth: 180,
      },
      headerSxStyle: {
        width: 180,
        maxWidth: 180,
      },
      sortable: true,
      showFilter: true,
      Filter: OrdersSelectFilter,
      Cell: ({ row }) => <div style={{
        display: "inline-block",
        maxWidth: "250px",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      }}>{row?.original?.status?.name}</div>,
      paramKey: 'status',
    },
    {
      id: 'action',
      className: 'w-1',
      sxStyle: {
        width: '100px !important',
        maxWidth: '100px',
      },
      headerSxStyle: {
        width: '100px !important',
        maxWidth: '100px',
      },
      sortable: false,
      disableFilters: true,
      disablePadding: true,
      Cell: OrdersRowActions,
    },
  ]

  const outboundShipmentsColumns = [
    {
      Header: "Shipment No",
      accessor: "shipment_no",
      className: "font-medium",
      sxStyle: {
        width: 120,
        maxWidth: 120,
      },
      headerSxStyle: {
        width: 120,
        maxWidth: 120,
      },
      sortable: true,
      showFilter: true,
      Filter: TextFilter,
      paramKey: 'shipment_no',
      Cell: ({ row, cell }) => OrderViewAction({ row, cell, stage: type}),
    },
    {
      Header: "Customer Name",
      accessor: "customer_name",
      sxStyle: {
        width: 150,
        maxWidth: 150,
      },
      headerSxStyle: {
        width: 150,
        maxWidth: 150,
      },
      sortable: true,
      showFilter: true,
      Filter: TextFilter,
      paramKey: 'customer_name',
      hideForType: type === 'outbound_shipments_customer',
      Cell: ({ row }) => {
        return (
          row.original.customer_name && (
            <Tooltip title={row.original.customer_name}>
              <div className='crop-text'>
                {row.original.customer_name}
              </div>
            </Tooltip>
          )
        );
      },
    },
    {
      Header: "Shipment Date",
      accessor: "shipment_date",
      sxStyle: {
        width: 120,
        maxWidth: 120,
      },
      headerSxStyle: {
        width: 120,
        maxWidth: 120,
        verticalAlign: 'top',
      },
      sortable: true,
      showFilter: true,
      Filter: DateFilter,
      Cell: ({ row, cell }) => {
          return cell.value && formatDateString(cell.value) ;
      },
      paramKey: 'shipment_date',
    },
    {
      Header: "Weight",
      accessor: "weight",
      sxStyle: {
        width: 150,
        maxWidth: 150,
      },
      headerSxStyle: {
        width: 150,
        maxWidth: 150,
        verticalAlign: 'top',
      },
      sortable: false,
      showFilter: false,
      Cell: ({ row, cell }) => {
          return `${numberFormatter.format(cell.value) || '0'} lbs` ;
      },
      paramKey: 'weight',
    },
    {
      Header: "No of Orders",
      accessor: "no_of_orders",
      sxStyle: {
        width: 80,
        maxWidth: 80,
      },
      headerSxStyle: {
        width: 80,
        maxWidth: 80,
        verticalAlign: 'top',
        whiteSpace: 'break-spaces !important'
      },
      sortable: false,
      showFilter: false,
      Cell: ({ row }) => {
        return (
          row.original.no_of_orders && (
            <Tooltip title={row.original.no_of_orders}>
              <div className='crop-text'>
                {row.original.no_of_orders}
              </div>
            </Tooltip>
          )
        );
      },
    },
    {
      Header: "Shipment Type",
      accessor: "shipment_type",
      sxStyle: {
        width: 160,
        maxWidth: 160,
      },
      headerSxStyle: {
        width: 160,
        maxWidth: 160,
      },
      sortable: true,
      showFilter: true,
      Filter: ShipmentTypeFilter,
      paramKey: 'shipment_type',
      Cell: ({ row }) => {
        return (
          row.original.shipment_type && (
            <Tooltip title={row.original.shipment_type}>
              <div className='crop-text'>
                {row.original.shipment_type}
              </div>
            </Tooltip>
          )
        );
      },
    },
    {
      Header: "No of Pallets",
      accessor: "pallet_count",
      sxStyle: {
        width: 80,
        maxWidth: 80,
      },
      headerSxStyle: {
        width: 80,
        maxWidth: 80,
        verticalAlign: 'top',
        whiteSpace: 'break-spaces !important'
      },
      sortable: false,
      showFilter: false,
      Cell: ({ row }) => {
        return (
          row.original.pallet_count && (
            <Tooltip title={row.original.pallet_count}>
              <div className='crop-text'>
                {row.original.pallet_count}
              </div>
            </Tooltip>
          )
        );
      },
    },
    {
      Header: "Status",
      accessor: "status.name",
      sxStyle: {
        width: 200,
        maxWidth: 200,
      },
      headerSxStyle: {
        width: 200,
        maxWidth: 200,
        verticalAlign: 'top',
      },
      sortable: true,
      showFilter: true,
      Filter: OrdersSelectFilter,
      paramKey: 'status',
    },
    {
      id: "action",
      className: "w-2",
      sortable: false,
      disableFilters: true,
      disablePadding: true,
      Cell: OrdersRowActions,
    },
  ]

  const getTypeColumns = (tableType) => {
    if (tableType === 'invoice') {
      return invoiceColumns;
    } if (['in-production', 'shipment', 'container'].includes(tableType)) {
      return shipmentColumns;
    }
    if (tableType === 'outbound_shipments' || tableType === 'outbound_shipments_customer') {
      return outboundShipmentsColumns;
    }
    return orderColumns;
  };

  const columns = getTypeColumns(type)

  if (!isManage) {
    return columns.filter((column) => !column.requiredPermissions && !column.hideForType);
  }
  return columns.filter((column) => !column.isExtra && !column.hideForType);
}

export default getColumns;
