import { combineReducers } from '@reduxjs/toolkit';
import login from './loginSlice';
import carts from './cartsSlice';
import resetPasswordSlice from './resetPasswordSlice';
import reviewOrderSlice from './reviewOrderSlice';
import reviewShipmentSlice from './reviewShipmentSlice';

const authReducers = combineReducers({
  login,
  carts,
  resetPasswordSlice,
  reviewOrderSlice,
  reviewShipmentSlice
});

export default authReducers;
