import TextField from "@mui/material/TextField";
import AuraControlledAutocomplete from "app/main/apps/common/AuraControlledAutocomplete";
import { preventNegativeValues } from "app/main/apps/common/AuraFunctions";
import { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";

function ShipmentFormContent(props) {
  const methods = useFormContext();
  const { control, formState, setValue, reset, watch } = methods;
  const shipmentTypeNames = useSelector(
    ({ backofficeApp }) => backofficeApp?.shipmentOrder?.shipmentTypeNames
  );
  const { errors } = formState;
  const shipmentType = watch("shipment_type");

  useEffect(() => {
    reset();
  }, []);

  useEffect(() => {
    const handleWheel = (e) => {
      if (e.target.type === "number") {
        e.target.blur();
      }
    };
    document.addEventListener("wheel", handleWheel, { passive: true });
    return () => {
      document.removeEventListener("wheel", handleWheel);
    };
  }, []);

  return (
    <>
      <div className="flex flex-col md:flex-row w-full gap-10">
        <div className="flex w-1/3 ">
          <Controller
            control={control}
            name="schedule"
            render={({ field }) => (
              <TextField
                {...field}
                className="mb-24"
                label="Shipment Date *"
                id="schedule"
                type="date"
                variant="outlined"
                fullWidth
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                error={!!errors.schedule}
                helperText={errors?.schedule?.message}
              />
            )}
          />
        </div>
        <div className="flex w-1/3 ">
          <AuraControlledAutocomplete
            labelName="Shipment Type"
            control={control}
            controlName="shipment_type"
            inputValues={shipmentTypeNames || []}
            required
            error={!!errors.shipment_type}
            defaultValue=""
            hideClearable
            helperText={errors?.shipment_type?.message}
            className={
              errors && errors?.shipment_type?.message
                ? "mt-0 mb-0"
                : "mt-0 mb-16"
            }
            onUpdate={(e, value) => {
              if(value !== 'LTL') {
                setValue("pallet_count", '');
              }
            }}
          />
        </div>
        {shipmentType === "LTL" && (
          <div className="flex w-1/3 ">
            <Controller
              control={control}
              name="pallet_count"
              render={({ field }) => (
                <>
                  <TextField
                    {...field}
                    value={field.value}
                    type="number"
                    label="No of Pallets"
                    variant="outlined"
                    className="p-0"
                    size="small"
                    fullWidth
                    onKeyDown={preventNegativeValues}
                    onChange={(e) => {
                      field.onChange(e.target.value);
                    }}
                    error={!!errors.pallet_count}
                    helperText={errors?.pallet_count?.message}
                    required={shipmentType === "LTL"}
                  />
                </>
              )}
            />
          </div>
        )}
      </div>
      <div className="flex flex-col md:flex-row w-full">
        <div className="flex w-full ">
          <Controller
            control={control}
            name="note"
            render={({ field }) => (
              <TextField
                {...field}
                className="mb-24"
                label={props?.type === 'edit' ? "Notes" : "Notes to Customer"}
                id="note"
                variant="outlined"
                fullWidth
                size="small"
                multiline
                rows={5}
                error={!!errors.note}
                helperText={errors?.note?.message}
              />
            )}
          />
        </div>
      </div>
    </>
  );
}

export default ShipmentFormContent;
