import React, { useCallback, useState } from 'react';
import { styled } from '@mui/material/styles';
import Icon from '@mui/material/Icon';
import Button from '@mui/material/Button';
import { Tooltip } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useDropzone } from 'react-dropzone';
import clsx from 'clsx';
import _ from '@lodash';
import { Controller, useForm, useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon/FuseSvgIcon';
import { selectUser } from 'app/store/userSlice';
import IconButton from '@mui/material/IconButton';
import ImageViewerWindow from 'app/main/dashboards/main/dialogs/ImageViewerWindow';
import pdfViewerWindow from 'app/main/dashboards/main/dialogs/pdfViewerWindow';
import { uploadFile } from '../store/filesSlice';

const Root = styled('div')(({ theme }) => ({
  '& .attachmentStar': {
    position: 'absolute',
    top: 0,
    right: 0,
    opacity: 0,
  },

  '& .attachmentUpload': {
    transitionProperty: 'box-shadow',
    transitionDuration: theme.transitions.duration.short,
    transitionTimingFunction: theme.transitions.easing.easeInOut,
  },

  '& .attachmentItem': {
    transitionProperty: 'box-shadow',
    transitionDuration: theme.transitions.duration.short,
    transitionTimingFunction: theme.transitions.easing.easeInOut,
    '&:hover': {
      '& .attachmentStar': {
        opacity: 0.8,
      },
    },
    '&.featured': {
      pointerEvents: 'none',
      boxShadow: theme.shadows[3],
      '& .attachmentStar': {
        opacity: 1,
      },
      '&:hover .attachmentStar': {
        opacity: 1,
      },
    },
  },

  '& .selection': {
    top: 6,
    right: 110,
  },
}));

function Attachments(props) {
  const dispatch = useDispatch();
  const methods = useFormContext();
  const { control, watch, setValue, getValues } = methods;
  const attachmentType = props.type || 'attachment';
  const attachmentCategory = props.category || 'document';
  const attachmentsType = props.attachmentsType || `${attachmentType}s`;
  const acceptedTypes = props.acceptedTypes || 'image/*';
  const newAttachmentsType = props.newAttachmentsType || `new_${attachmentsType}`;
  const attachments = watch(attachmentsType);
  const newAttachments = watch(newAttachmentsType);
  const user = useSelector(selectUser)
  const [Loading, setLoading] = useState(false);

  const onDrop = useCallback(async (acceptedFiles, value) => {
     // Handle the accepted files here
    const existingAttachemnts = watch(newAttachmentsType)
    function readAttachmentAsync() {
      return new Promise((resolve, reject) => {
        const file = acceptedFiles[0];
        if (!file) {
          return;
        }
        dispatch(uploadFile({ file, addTo: props.addTo, ...(props.payload || {}) })).then((response) => {
          const reader = new FileReader();
          reader.onload = () => {
            resolve({
              id: response.payload.id,
              url: `data:${file.type};base64,${btoa(reader.result)}`,
              type: attachmentCategory,
              image_uuid: response.payload.etchingUUID,
              name: response?.payload?.filename
            });
          };
          reader.onerror = reject;
         reader.readAsBinaryString(file);
        });
      });
    }

    setLoading(true); 
    const newAttachment = await readAttachmentAsync();
    setValue(newAttachmentsType, [newAttachment, ...existingAttachemnts], { shouldDirty: true });
    setLoading(false); 
    if (props.recentFiles) props.recentFiles(newAttachment)
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'image/jpeg': ['.jpeg', '.png'],
      'application/pdf': ['.pdf']
    },
    multiple: false,
    noKeyboard: true
  });

  function clearAttachment(event, value) {
    const filteredAttachments = attachments.filter((item) => item.id !== value.id);
    setValue(attachmentsType, filteredAttachments, { shouldDirty: true });
  }

  function clearNewAttachment(event, value) {
    const filteredNewAttachments = newAttachments.filter((item) => item.id !== value.id);
    setValue(newAttachmentsType, filteredNewAttachments, { shouldDirty: true });
  }

  return (
     // <Root>
    <div className='w-full'>
      <div className="flex justify-center sm:justify-start flex-wrap -mx-16 mw-10/12 !m-auto">
        {props.allowAdd && (
          <Controller
            name={newAttachmentsType}
            defaultValue=""
            control={control}
            render={({ field: { onChange, value } }) => (
              <div {...getRootProps()} className="relative flex flex-col items-center justify-center p-28 mt-14 border-2 border-dashed !border-blue-600/50 outline-none transition hover:!border-blue-600 focus:!border-blue-600 cursor-pointer w-full max-w-md mx-auto"
              style={Loading ? { pointerEvents: 'none' } : {}} 
              >
                <input {...getInputProps()} onChange={async (e) => {
                  setLoading(true);
                  await onDrop(e.target.files, value);
                  setLoading(false);
                }} 
                disabled={Loading} 
                />
                <Icon fontSize="large" color="action" sx={{ fontSize: '6rem' }}>
                  cloud_upload
                </Icon>
                <p>Drag & Drop Files Here</p>
                <p className='text-gray-500'>Upload your documents (Images or PDF) here.</p>
                <Button type="button" variant='contained' color='secondary' className='mt-14'>
                  Choose files to upload
                </Button>
                {Loading && (
                <div className="absolute inset-0 bg-white bg-opacity-100 z-10 flex items-center justify-center">
                <CircularProgress color="inherit" /> 
                </div>
                 )}

              </div>
               // <label
              //   htmlFor={`attachment-button-file-${props.attachmentKey || ''}`}
              //   className="attachmentUpload flex items-center justify-center relative w-128 h-128 rounded-16 mx-12 mb-24 overflow-hidden cursor-pointer shadow hover:shadow-lg"
              // >
              //   <input
              //     accept={acceptedTypes}
              //     className="hidden"
              //     id={`attachment-button-file-${props.attachmentKey || ''}`}
              //     type="file"
              //     onChange={async (e) => {
              // function readAttachmentAsync() {
              //   return new Promise((resolve, reject) => {
              //     const file = e.target.files[0];
              //     console.log('files', e.target.files[0])
              //     if (!file) {
              //       return;
              //     }
              //     dispatch(uploadFile({ file, addTo: props.addTo, ...(props.payload || {}) })).then((response) => {
              //       const reader = new FileReader();
              //       reader.onload = () => {
              //         resolve({
              //           id: response.payload.id,
              //           url: `data:${file.type};base64,${btoa(reader.result)}`,
              //           type: attachmentCategory,
              //           image_uuid: response.payload.etchingUUID
              //         });
              //       };
              //       reader.onerror = reject;
              //       reader.readAsBinaryString(file);
              //     });
              //   });
              // }

              // const newAttachment = await readAttachmentAsync();
              // onChange([newAttachment, ...value]);
              // if (props.recentFiles) props.recentFiles(newAttachment)
              //     }}
              //   />
              //   <Icon fontSize="large" color="action">
              //     cloud_upload
              //   </Icon>
              // </label>
            )}
          />
        )}
      </div>
      <div className='flex justify-center flex-wrap -mx-16 mt-14'>
        <Controller
          name="attachmentId"
          control={control}
          defaultValue=""
          render={({ field: { onChange, value } }) =>
            attachments ? (
              attachments.map((media, index) => {
                 // media.content_type = media['Content-Type'];
                 return <div className='relative'>
                  {props.allowDelete && (
                    <IconButton
                      className="attachmentStar top-1.5 right-1.5 w-28 h-28 absolute z-10"
                      onClick={(ev) => {
                        clearAttachment(ev, media);
                      }}
                      size="small"
                      sx={{ background: 'white!important' }}
                    >
                      <Icon>clear</Icon>
                    </IconButton>
                  )}<div
                    role="button"
                    tabIndex={0}
                    className={clsx(
                      'attachmentItem flex items-center justify-center relative w-128 h-128 rounded-16 mx-12 overflow-hidden cursor-pointer outline-none shadow hover:shadow-lg',
                      media.id === value && 'featured'
                    )}
                    key={index}
                    onClick={() => {
                      if (!_.startsWith(media['Content-Type'], 'image/')) {
                        pdfViewerWindow(media.data)
                      } else {
                        ImageViewerWindow([media])
                      }
                    }}
                    onKeyDown={() => { }}
                  
                  >
                     {/* {media.content_type} */}
                    <img
                      src={
                        _.startsWith(media['Content-Type'], 'image/')
                          ? `data:${media['Content-Type']};base64,${media.data}`
                          : 'assets/icons/pdf.png'
                      }

                      className="max-w-none w-auto h-full relative"
                      alt="attachment"
                    />
                  </div>
                  <Tooltip title={media?.filename || media?.name}>
                      <div
                        className="mb-24 mt-10 w-128 text-sm text-center m-auto"
                        style={{
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                        }}
                      >
                        {media?.filename || media?.name}
                      </div>
                    </Tooltip>
                </div>
          })
            ) : (
              <></>
            )
          }
        />
        <Controller
          name="attachmentNewId"
          control={control}
          defaultValue=""
          render={({ field: { onChange, value } }) =>
            (newAttachments && !props.hideFileList) ? (
              newAttachments.map((media) => {
                return (
                <div className='relative'>
                  <IconButton
                    className="attachmentStar top-1.5 right-1.5 w-28 h-28 absolute z-10"
                    onClick={(ev) => {
                      ev.stopPropagation()
                      clearNewAttachment(ev, media);
                    }}
                    size="small"
                    sx={{ background: 'white!important' }}
                  >
                    <Icon>clear</Icon>
                  </IconButton>
                  <div
                    onClick={() => {
                      if (!_.startsWith(media.url, 'data:image/')) {
                        pdfViewerWindow(media.url)
                      } else {
                        ImageViewerWindow([media])
                      }
                      onChange(media.id)
                      if (props.setSelected) props.setSelected(media)
                    }}
                    onKeyDown={() => onChange(media.id)}
                    role="button"
                    tabIndex={0}
                    className={clsx(
                      'attachmentItem flex items-center justify-center relative w-128 h-128 rounded-16 mx-12 overflow-hidden cursor-pointer outline-none shadow hover:shadow-lg',
                      props.selected === media.id && 'border-blue-500'
                    )}
                    key={media.id}
                  >

                    <img
                      src={
                        _.startsWith(media.url, 'data:image/')
                          ? media.url
                          : 'assets/icons/pdf.png'
                      }
                       className="max-w-none w-auto h-full relative"
                      alt="attachment"
                    />
                     {props.selected === media.id && <div className="selection absolute flex items-center justify-center w-14 h-14 bg-white rounded-full">
                        <FuseSvgIcon size={28} color="success">heroicons-solid:check-circle</FuseSvgIcon>
                      </div>}
                  </div>
                  <Tooltip title={media?.filename || media?.name}>
                      <div
                        className="mb-24 mt-10 w-128 text-sm text-center m-auto"
                        style={{
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                        }}
                      >
                        {media?.filename || media?.name}
                      </div>
                    </Tooltip>
                </div>
                )
              })
            ) : (
              <></>
            )
          }
        />
      </div>
    </div>
     // </Root>
  );
}

export default Attachments;
