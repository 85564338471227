import React, { useEffect, useRef, useState } from 'react';
import { AuraSimplePageRoot, isAdminOrStaff } from 'app/main/apps/common/AuraFunctions';
import AuraHeader from 'app/main/apps/common/AuraHeader';
import { useReactToPrint } from 'react-to-print';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectUser } from 'app/store/userSlice';
import ShippingLabelDetails from '../tabs/ShippingLableDetails';

const ShippingLabelContent = () => {
    const [isPrinting, setIsPrinting] = useState(false);
    const user = useSelector(selectUser);
    const labelRef = useRef()
    const promiseResolveRef = useRef(null);
    const location = useLocation()
    const { pathname } = location
    const pathType = pathname.split('/')[1];
    const pathType2 = pathname.split('/')[2];
    const isOutboundPrintLabel = pathType === "outbound" && pathType2 === "print-label";
    const isInboundPrintLabel = pathType === "shipments" && pathType2 === "print-label";
    const isprintProduction = pathType2 === "printProduction" || pathType2 === "print-label"
    const isAdminOrStaffUser = isAdminOrStaff(user)

    useEffect(() => {
        if (isPrinting && promiseResolveRef.current) {
            // Resolves the Promise, letting `react-to-print` know that the DOM updates are completed
            promiseResolveRef.current();
        }
    }, [isPrinting]);

    const getValue = (valueFor) => {
        if (isAdminOrStaffUser && isInboundPrintLabel) {
            if(valueFor === "title") return "Packing Slip";
            if(valueFor === "button_name") return  "Print";
            return 'packing-slip.pdf'
        }
        if (isAdminOrStaffUser && isOutboundPrintLabel) {
            if(valueFor === "title") return "Pick Ticket";
            if(valueFor === "button_name") return  "Print";
            return 'pick-ticket.pdf'
        }
        if (isprintProduction) {
            if(valueFor === "title") return "Print Production Sheet";
            if(valueFor === "button_name") return  "Download";
            return 'print-production-sheet.pdf'
        }
        if(valueFor === "title") return "Shipment Label";
        if(valueFor === "button_name") return  "Download";
        return 'shipping-label.pdf';
    };

    const handleLabelDownload = useReactToPrint({
        content: () => labelRef.current,
        onBeforeGetContent: () => {
            return new Promise((resolve) => {
                promiseResolveRef.current = resolve;
                setIsPrinting(true);
            });
        },
        onAfterPrint: () => {
            // Reset the Promise resolve so we can print again
            promiseResolveRef.current = null;
            setIsPrinting(false);
        },
        pageStyle: pathType2 === "print-label" ? `
        @media print {
            body {
                padding: 5px;
                font-size: 12px;
                -webkit-print-color-adjust: exact;
              }
            .scale-content {
              transform: scale(0.6); /* Scale content to 60% */
              transform-origin: top left; /* Set transformation origin */
            }
            .element-with-spacing {
                margin-bottom: 20mm!important; /* Add spacing between elements */
                page-break-inside: avoid!important; /* Avoid breaking the element between pages */
              }
          }
        `  :  `
        @page {
            size: A3 landscape;
        }
        @media print {
            body {
                padding: 20px;
                font-size: 12px;
                -webkit-print-color-adjust: exact;
                }
            .scale-content {
                transform: scale(0.6); /* Scale content to 60% */
                transform-origin: top left; /* Set transformation origin */
            }
            .element-with-spacing {
                margin-bottom: 20mm!important; /* Add spacing between elements */
                page-break-inside: avoid!important; /* Avoid breaking the element between pages */
                }
            }
        `,
        documentTitle: getValue("document_title"),
    });

    const getLink = () => {
        if(isAdminOrStaffUser && isOutboundPrintLabel)
            return '/shipments/calendar'
        if(isAdminOrStaffUser && isInboundPrintLabel)
            return '/shipments/containers'
        return  '/in-production'
    }

    const headerData = {
        headerTitle: getValue("title"),
        filter: {
            component: null,
        },
        search: {
            enabled: false,
        },
        updateShow: true,
        links: [
            {
                id: 1,
                title: getValue("button_name"),
                type: 'save',
                value: 'Download',
                show: true,
                onClick: handleLabelDownload,
            },
            {
                id: 2,
                type: 'back',
                value: 'Shipments',
                link: getLink(),
                show: true,
            }
        ],
        toComponentState: {from: 'pickTicket'},
    };

    return <AuraSimplePageRoot
        header={<AuraHeader {...headerData} />}
        content={<ShippingLabelDetails labelRef={labelRef} isPrinting={isPrinting} isprintProduction={!!isprintProduction} isAdminOrStaffUser={!!isAdminOrStaffUser} />}
        sidebarInner
    />
};

export default ShippingLabelContent;
