import FuseNavigation from '@fuse/core/FuseNavigation';
import clsx from 'clsx';
import { memo, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectNavigation } from 'app/store/fuse/navigationSlice';
import useThemeMediaQuery from '@fuse/hooks/useThemeMediaQuery';
import { navbarCloseMobile } from 'app/store/fuse/navbarSlice';
import { IconButton, Menu, MenuItem, Tooltip } from '@mui/material';
import { AddShoppingCart, MoreVert, RequestQuote } from '@mui/icons-material';
import { Link, useLocation } from 'react-router-dom';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import _ from 'lodash';

function Navigation(props) {
  const navigation = useSelector(selectNavigation);
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down('lg'));
  const currentUser = useSelector(({ user }) => user);
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState();
  const [moreMenu, setMoreMenu] = useState([]);
  const openActionMenu = Boolean(anchorEl);
  const location = useLocation();

  const setAnchorElement = (e) => {
    setAnchorEl(e.currentTarget);
  }

  function handleItemClick(item) {
    if (isMobile) {
      dispatch(navbarCloseMobile());
    }
  }

  const filterNavigation = () => {
    // Seperate More menus and other menus
    const navPartition = _.partition(navigation, function (nav) { return nav.menu_type === "more" });
    if(navPartition?.[0] && navPartition[0].length > 0) {
      setMoreMenu(navPartition[0]);
    }
    return navPartition?.[1] || []
  }

  return useMemo(() => {
    return (
      <div className={clsx('flex', { 'flex-col': isMobile })}>
        <div>
          <FuseNavigation
            className={clsx('navigation', props.className)}
            navigation={filterNavigation()}
            // navigation={navigation}
            layout={props.layout}
            dense={props.dense}
            active={props.active}
            onItemClick={handleItemClick}
          />
        </div>
        {moreMenu?.length > 0 && location.pathname !== '/dashboard' && (<div className={clsx({ 'm-16': isMobile })}>
          <Tooltip title="more">
            <IconButton onClick={(e) => setAnchorElement(e)}>
              <MoreVert />
            </IconButton>
          </Tooltip>

          <Menu
            anchorEl={anchorEl}
            open={openActionMenu}
            onClose={() => { setAnchorEl(null) }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'bottom',
            }}
          >

            {moreMenu?.map(val =>
              <MenuItem
                component={Link}
                to={val.url}
                onClick={() => { setAnchorEl(null) }}>
                <div className="flex gap-10">
                  <FuseSvgIcon>{val.icon}</FuseSvgIcon>
                  <span>{val.title}</span>
                </div>

              </MenuItem>)}
          </Menu>

        </div>)}
      </div>
    );
  }, [dispatch, isMobile, navigation, props.active, props.className, props.dense, props.layout, anchorEl, location.pathname]);
}

Navigation.defaultProps = {
  layout: 'vertical',
};

export default memo(Navigation);
