import { showMessage } from 'app/store/fuse/messageSlice';
import format from 'date-fns/format';
import _ from '@lodash';
import { styled } from '@mui/material/styles';
import FusePageCarded from '@fuse/core/FusePageCarded';
import FusePageSimple from '@fuse/core/FusePageSimple/FusePageSimple';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import authTypes from 'app/auth/authTypes';
import authRoles from 'app/auth/authRoles';
import * as yup from 'yup';
import config from '../../../../config'

export default function dispatchMessage(
  dispatch,
  message,
  type = 'info',
  autoHideDuration = 3000,
  position = 'top'
) {
  dispatch(
    showMessage({
      message,
      variant: type,
      autoHideDuration,
      anchorOrigin: {
        vertical: position,
        horizontal: 'right',
      },
    })
  );
}

export function formatDateString(value, dFormat = 'MMM dd, yyyy') {
  if(_.endsWith(value, "Z")) {
    // Removed Z So new Date() will not convert that given value to local timezone value
    return value ? format(new Date(value.split('Z')[0]), dFormat) : '';
  }
  return value ? format(new Date(value), dFormat) : '';
}

export function selectAndFormatAmount(value, value2,) {
  const amount = value > 0 ? value : value2;
  return `$${amount || '0.00'}`;
}

export function selectAmount(value, value2) {
  const amount = value > 0 ? value : value2;
  return amount || '0.00';
}

// It will return as "0" or "1". Converting like this like since sometime backend excepts "0" or "1"
// If we pass value as "true" first it will change to number value 1 then it will return "1"
export const getNumberBooleanString = (value) => JSON.stringify(Number(value));

// It will return true or false
// From Backend we get "0" or "1". To avoid assignment error we have to change it to boolean
// Number(value) > 0 condition checked since some of the item have value 2
export  const getBooleanValue = (value) => value === 'true' || value === '1' || value === true || Number(value) > 0;

export function calculateWeight(isManage, length, width, height, setValue) {
  if (isManage) {
    const weight = _.round(length * width * height * 0.053 * 2.22);
    if (setValue) {
      setValue('weight', weight)
    } else {
      return weight
    }
  }
  return 0
}

export function checkNonEmptyOrNull(value) {
  return value === "0" || value === 0 || value === null || typeof value === "undefined" || value === "";
}

export function checkNullOrUndefined(value) {
  return _.isNull(value) || _.isUndefined(value);
}

export function selectAndFormatWeight(val1, val2) {
  const weight = val1 > 0 ? val1 : val2;
  return `${numberFormatter.format(weight) || '0'} lbs`
}

export function formatWeight(weight, showEmpty = true) {
  if(weight && weight !== null) return `${numberFormatter.format(weight)} lbs`
  return showEmpty ?  '' : '0 lbs';
}

export function calculateAndFormatTotalWeight(quantity, weight, showEmpty = true) {
  if(!checkNullOrUndefined(quantity) && !checkNullOrUndefined(weight))
    return `${numberFormatter.format(quantity * weight)} lbs`
  return showEmpty ?  '' : '0 lbs';
}


export function emptySchema() {
  return yup.object().shape({});
}

export function isCustomer(role) {
  return role === 'customer';
}
export function isSupplier(role) {
  return role === 'supplier';
}

export function isAnyCustomerRole(role) {
  return authRoles.customer.includes(role)
}

export function isSuppllierArtDesign(user) {
  if (!isSupplier(user.role)) return false
  const supplierTypes = user?.supplier?.supplier_types || []
  return authTypes.onlyArtDesign.some(str1 => supplierTypes.includes(str1))
}

export function isSupplierStone(user) {
  if (!isSupplier(user.role)) return false
  const supplierTypes = user?.supplier?.supplier_types || []
  return authTypes.onlyStone.some(str1 => supplierTypes.includes(str1))
}

export function isSupplierEtching(user) {
  if (!isSupplier(user.role)) return false
  const supplierTypes = user?.supplier?.supplier_types || []
  return authTypes.onlyEtching.some(str1 => supplierTypes.includes(str1))
}

export function isSupplierSandblasting(user) {
  if (!isSupplier(user.role)) return false
  const supplierTypes = user?.supplier?.supplier_types || []
  return authTypes.onlyEtching.some(str1 => supplierTypes.includes(str1))
}

export function isDefaultSupplierTypes(user) {
  if (!isSupplier(user.role)) return false
  const supplierTypes = user?.supplier?.supplier_types || []
  return authTypes.defaultSupplierTypes.some(str1 => supplierTypes.includes(str1))
}

export function maxNumeric(value, maxLength, specialCharacter, field) {
  value = value.replace(new RegExp(`[^0-9${specialCharacter}]`, 'g'), '');
  if (value.length <= maxLength) {
    field.onChange(value)
  }
}

export const AuraPageCardedRoot = styled(FusePageCarded)({
  '& .FusePageCarded-header': {},
  '& .FusePageCarded-toolbar': {},
  '& .FusePageCarded-content': {},
  '& .FusePageCarded-sidebarHeader': {},
  '& .FusePageCarded-sidebarContent': {},
});

export function formatCustomer(customer) {
  let displayName = customer.company_name || '';
  displayName += customer.first_name || customer.last_name ? '-' : '';
  displayName += `${customer.first_name} ` || '';
  displayName += customer.last_name || '';
  return displayName;
}

export function formatName(firstName, lastName) {
  return `${firstName || ''} ${lastName || ''}`;
}

export function getOrderTotalWeight(orders = []) {
  return _.sumBy(getOrderSummary(orders), (order) => {
    return order?.totalWeight;
  });
}
export function getContainerOrderTotalWeight(orders = []) {
  return _.sumBy(getContainerOrderSummary(orders), (order) => {
    return order?.totalWeight;
  });
}

export function getFilteredSplitOrders(orders = [], splitOrders = []) {
  const orderDetails = []
  if (orders?.length) {
    orders.forEach(o => orderDetails.push(...o.order_detail.filter(od => od.is_selected)))
  }
  return _.filter(splitOrders, function (item) {
    if (orderDetails.length) {
      return orderDetails.find(od => od?.id === item?.id) && item?.quantity > 0
    }
    return false
  });
}

export function getOrderSummary(orders, customer) {
  return _.map(orders, (order) => {
    const totalWeight = _.sumBy(order.order_detail, (detail) => {
      const qty = detail?.newQuantity ?? detail.selected_quantity
      return detail.is_selected ? _.multiply(qty, detail.weight) : 0
    });
    const totalQuantity = _.sumBy(order.order_detail, (detail) => {
      const qty = detail?.newQuantity ?? detail.selected_quantity
      return qty;
    });
    const selected = _.some(order.order_detail, 'is_selected');
    // const selected = order.order_detail?.filter(detail => detail.is_selected === 1)
    return {
      id: order.id,
      totalWeight,
      totalQuantity,
      selected,
      customerId: customer?.id,
      customerName: customer?.name,
    };
  });
}

export function getContainerOrderSummary(orders, customer) {
  return _.map(orders, (order) => {
    const totalWeight = _.sumBy(order.order_detail, (detail) => {
      return detail.is_selected ? _.multiply(_.toNumber(detail?.quantity || 0), _.toNumber(detail.weight)) : 0
    });
    const orderWeight = _.sumBy(order.order_detail, (detail) => {
      return _.multiply(_.toNumber(detail?.quantity || 0), _.toNumber(detail.weight))
    });
    const totalQuantity = _.sumBy(order.order_detail, (detail) => {
      return _.toNumber(detail?.quantity || 0);
    });
    const totalSelectedQuantity = _.sumBy(order.order_detail, (detail) => {
      return detail.is_selected ? _.toNumber(detail?.quantity || 0) : 0;
    });
    const selected = _.some(order.order_detail, 'is_selected');
    // const selected = order.order_detail?.filter(detail => detail.is_selected === 1)
    return {
      id: order.id,
      totalWeight,
      orderWeight,
      displayWeight: totalWeight > 0 ? totalWeight : orderWeight,
      totalQuantity,
      totalSelectedQuantity,
      selected,
      customerId: customer?.id,
      customerName: customer?.name,
    };
  });
}

export const AuraSimplePageRoot = styled(FusePageSimple)(({ theme }) => ({
  '& .FusePageSimple-header': {
    backgroundColor: theme.palette.background.paper,
    borderBottomWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.divider,
  },
  '& .FusePageSimple-toolbar': {},
  '& .FusePageSimple-content': {},
  '& .FusePageSimple-sidebarHeader': {},
  '& .FusePageSimple-sidebarContent': {},
}));

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export const mapParams = (keys, removeKeys = []) => {
  const mapperStrings = {
    'App-Pagination-Num': 'pageNo',
    'App-Pagination-Limit': 'limit',
    'App-Pagination-Sort': 'sort'
  }
  const searchParams = new URLSearchParams(window.location.search);
  const keyValues = Object.fromEntries(searchParams);
  const obj = { ...keyValues }
  Object.keys(keys).forEach((key) => {
    const value = mapperStrings[key];
    if (value)
      obj[value] = keys[key];
    else
      obj[key] = keys[key]
  });

  return _.omit(obj, removeKeys)
}

export const getParams = (goToFirstPage) => {
  const mapperStrings = {
    'pageNo': 'App-Pagination-Num',
    'limit': 'App-Pagination-Limit',
    'sort': 'App-Pagination-Sort'
  };
  const { history, location } = window
  const sp = new URLSearchParams(window.location.search);
  // Go to first page if actions done in some other page
  // This will avoid showing empty results
  if (goToFirstPage && sp.has('pageNo')) {
    sp.delete('pageNo')
    history.replaceState(null, "", sp.toString() ? `${location.pathname}?${sp.toString()}` : location.pathname);
  }
  const keys = Object.fromEntries(sp);
  const headerObj = {};
  const filterObj = {};
  Object.keys(keys).forEach((key) => {
    const value = mapperStrings[key];
    if (value)
      headerObj[value] = keys[key];
    else
      filterObj[key] = keys[key]
  });

  return { headerObj, filterObj };
}

export const isValSelected = (val) => {
  return val === 1 || val === true || val === "1" || val === "true";
}

export const getSelectedRecords = (items) => {
  return _.filter(
    items || [],
    function (item) {
      return (
        item.is_selected === 1 || item.is_selected === true || item.is_selected === "1" || item.is_selected === "true"
      );
    }
  );
}

export const getValidSelectedRecords = (items) => {
  return _.filter(
    items || [],
    function (item) {
      return (
        (item.is_selected === 1 || item.is_selected === true || item.is_selected === "1" || item.is_selected === "true") && item.original_qty > 0
      );
    }
  );
}

export const isAdminOrStaff = (user) => {
  return user && (user.role === 'admin' || user.role === 'staff' || user.role === 'sales');
}

export const convertArrayToString = (arrayP) => {
  if (!Array.isArray(arrayP) || arrayP?.length === 0) return ''; // Handle invalid input gracefully
  return arrayP.map((obj) => obj.trim()).join(',');
};

// Order Item: Single unit price without discount
export const calculateUnitPrice = (weight, fob, tariff, shipping, margin = 0) => {
  const shipVal = Number(weight) * Number(shipping);
  const tariffVal = Number(fob) * (Number(tariff) / 100);
  const value = (Number(fob) + shipVal + tariffVal) / (1 - Number(margin / 100));
  const priceVal = value && Number(value) ? value.toFixed(2) : 0;
  return priceVal;
}

// Order Item: Single unit price amount with discount
export const calculateUnitAmount = (unitPrice, discount) => {
  let wholeSalePrice = unitPrice
    if (discount > 0) {
      const discountValue = wholeSalePrice * discount / 100
      wholeSalePrice -= discountValue
    }
  return wholeSalePrice ? Number(wholeSalePrice).toFixed(2): 0;
}

// Order Item: Total Discount value for the given quantity
export function getDiscountAmtFromPercentage(discountPercentage, unitPrice, quantity, returnZero = true) {
  if(discountPercentage && discountPercentage > 0 && unitPrice && unitPrice > 0 && quantity && quantity > 0) {    
    const discountValue = (_.toNumber(discountPercentage) / 100) * _.toNumber(unitPrice) * parseInt(quantity, 10);
    return discountValue.toFixed(2);
  }
  return returnZero === true ? 0 : null;
}

// Order Item: Total Amount for the given quantity including discount and vas price
export function calculateItemTotalAmount(price, quantity, discountPercentage = 0, textPrice = 0, imagePrice = 0) {
  const total = price * quantity;
  const discountAmt = total * (discountPercentage / 100);
  const amount = (total - discountAmt) + textPrice + imagePrice;
  return parseFloat(amount).toFixed(2);
}

// Order Item: Calcualate Margin
export const calculateMargin = (weight, fob, tariff, shipping, unitPrice) => {
  const shipVal = Number(weight) * Number(shipping);
  const tariffVal = Number(fob) * (Number(tariff) / 100);
  const margin = (100 * (unitPrice - (Number(fob) + shipVal + tariffVal))) / unitPrice;
  const marginVal = margin && Number(margin) ? Number(margin).toFixed(2) : 0;
  return marginVal;
}

export const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
});

export const numberFormatter = new Intl.NumberFormat('en-US');

export const preventNegativeValues = (e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()

export const preventNegativeDecimalValues = (e) => ["e", "E", "+", "-","."].includes(e.key) && e.preventDefault()

/**
* Check for a user mission to do a given operation
*
* @param user // Permission will be taken from user
* @param operation // Operation needs to be checked
* @param module // In which module operation needs to be checked
* @param checkAllOpearation // To check for complete operation is allowed that module
*/
export const hasPermission = (user, operation, module = null, checkAllOpearation = true) => {
  // To check for given operation is allowed for that module
  // If module passed the full operation text will be prepared from operation and module
  const opertaionName = module ? `PRIV_${operation?.toUpperCase()}_${module?.toUpperCase()}` : operation?.toUpperCase();

  // To check for complete operation is allowed for that module
  const allOperationName =  (checkAllOpearation === true && module) ? `PRIV_${module?.toUpperCase()}_*` : null;

  return user?.permissions?.length > 0 && ((allOperationName && user?.permissions?.includes(allOperationName)) || user?.permissions?.includes(opertaionName));
}

export const displayName = { tax: "Tariffs & Customes Fee" };

const client = process.env.REACT_APP_CUSTOMER || 'psg'
export const getCustomerBasedProfile = config[client] || {}