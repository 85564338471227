import Typography from '@mui/material/Typography';
import AuraAutoComplete from 'app/main/apps/common/AuraAutoComplete';
import { useDispatch, useSelector } from 'react-redux';
import { useDeepCompareEffect } from '@fuse/hooks';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { matchSorter } from 'match-sorter';
import { useEffect, useState, memo } from 'react';
import { useParams } from 'react-router-dom';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import TextField from '@mui/material/TextField';
import moment from 'moment';
import { getCustomersShipment } from 'app/main/apps/client/store/customersSlice';
import { getStatuses } from 'app/main/dashboards/main/store/widgetsSlice';
import { setOrderTypes, setSelectedValue } from '../store/ordersSlice';
import { getAvailableOrders, getCustomers, getOrderType, getOrderTypeLocations, resetEntities, setOrderTypeLocation, setSupplierOrderType, setWeightRange } from '../../backoffice/store/shipmentOrderSlice';

function CustomerFilter(props) {
  const { setCustomer, defaultCustomer, filterValue, setSupplier, isOrderModule, pageRef, autoCompleteSxStyle = null } = props;
  const dispatch = useDispatch();
  const currentUser = useSelector(({ user }) => user);
  const suppliersList = useSelector(state => state.eCommerceApp.orders.suppliersList);
  const selectedSupplier = useSelector(state => state.eCommerceApp.orders.supplier);
  const customersListItems = useSelector(state => state.eCommerceApp.orders.customersListItems);
  const { location } = window;
  const isCustomer = currentUser && currentUser.role === 'customer';
  const [customers, setCustomers] = useState([]);
  const isCustomerFilter = (!['supplier', 'supplier_archive',].includes(filterValue?.value)) || !isOrderModule;
  const sp = new URLSearchParams(location.search)

  useDeepCompareEffect(() => {
    if (!isCustomer && location.pathname === '/shipments/new') {
      dispatch(getCustomersShipment({ lt: 'shipment' })).then(({ payload }) => {
        setCustomers(payload.data);
      });
    } else if (!isCustomer) {
      if (customersListItems) {
        setCustomers(customersListItems);
      }
    }
  }, [dispatch, isCustomer, location.pathname, customersListItems]);

  useEffect(() => {
    if (location.search && isOrderModule && !pageRef?.current) {
      const cid = sp.get('cid');
      const sid = sp.get('sid');
      const orderType = sp.get('o_type')
      if (cid && customers?.length) {
        const customer = customers.find(c => c.id === cid)
        dispatch(setCustomer(customer));
        pageRef.current = true
      }
      else if (sid && suppliersList?.length) {
        const supplier = suppliersList.find(c => c.id === sid)
        dispatch(setSupplier(supplier));
        pageRef.current = true
      }
    }
  }, [location, customers, suppliersList, dispatch, setCustomer, setSupplier, isOrderModule])

  const handleCustomerChange = (value) => {
    sp.delete('sid')
    sp.delete('cid')
    if (!value && filterValue?.value.includes('supplier'))
      sp.set('sid', 'all');

    if (isCustomerFilter) {
      dispatch(setCustomer(value));
      if (isOrderModule) {
        dispatch(setSupplier(null));
        if (value) {
          sp.set('cid', value.id)
        }
      }
    }
    else {
      dispatch(setSupplier(value));
      dispatch(setCustomer(null));
      if (value)
        sp.set('sid', value.id)
    }

    if (isOrderModule)
      window.history.replaceState(null, "", sp.toString() ? `${location.pathname}?${sp.toString()}` : location.pathname);
  };

  const inputValues = isCustomerFilter ? customers : [{ name: 'All', value: 'all', email: '', id: 'all' }, ...suppliersList]
  const defaultValue = isCustomerFilter ? defaultCustomer : selectedSupplier

  return (
    <div className="flex flex-row mx-6">
      <Typography className="text-12 md:text-14 pr-8 pt-12">{isCustomerFilter ? 'Customer' : 'Supplier'}:</Typography>
      <div className="text-12 md:text-14 pr-8 font-semibold">
        <AuraAutoComplete
          placeholder={`All ${isCustomerFilter ? 'Customers' : 'Suppliers'}`}
          inputValues={inputValues}
          onValueChange={handleCustomerChange}
          defaultValue={defaultValue || null}
          optionDisplayName="name"
          // isOptionEqualToValue={(option, value) => option.name === value.name}
          isOptionEqualToValue={(option, value1) => {
            if (option && option.name && value1.name) {
              return option.name === value1.name;
            }
            return false;
          }}
          hideClearable={false}
          renderOption={(renderprops, option) => (
            <Box
              component="li"
              {...renderprops}
              key={option.id}
              className={`${renderprops.className} !block py-2 px-10`}
            >
              <div className="font-medium">{option.name}</div>
              <div className="font-light text-10 text-gray-400">{option.email}</div>
            </Box>
          )}
          filterOptions={(options, { inputValue }) =>
            matchSorter(options, inputValue, {
              keys: ['name'],
              threshold: matchSorter.rankings.STARTS_WITH,
            })
          }
          sxStyle={autoCompleteSxStyle}
        />
      </div>
    </div>
  );
}

export function SalesPersonFilter(props) {
  const { setSalesPerson, defaultSalesPerson, filterValue, isOrderModule, pageRef, autoCompleteSxStyle = null } = props;
  const dispatch = useDispatch();
  const { location } = window;
  const salesPersonListItems = useSelector(state => state.eCommerceApp.orders.salesPersonListItems);
  const [salesPersons, setSalesPersons] = useState([]);
  const isCustomerFilter = (!['supplier', 'supplier_archive',].includes(filterValue?.value)) || !isOrderModule;
  const sp = new URLSearchParams(location.search)

  useDeepCompareEffect(() => {
    if (salesPersonListItems) {
      setSalesPersons(salesPersonListItems);
    }
  }, [salesPersonListItems]);

  useEffect(() => {
    if (location.search && isOrderModule && !pageRef?.current) {
      const spid = sp.get('sales_person');
      if (spid && salesPersons?.length) {
        const salesPerson = salesPersons.find(s => s.id === spid)
        dispatch(setSalesPerson(salesPerson));
        pageRef.current = true
      }
    }
  }, [location, salesPersons, dispatch, setSalesPerson, isOrderModule])

  const handleSalesPersonChange = (value) => {
    sp.delete('sales_person');
    if (isCustomerFilter) {
      dispatch(setSalesPerson(value));
      if (isOrderModule && value && value.id) {
        sp.set('sales_person', value.id);
      }
    }
    if (isOrderModule)
      window.history.replaceState(null, "", sp.toString() ? `${location.pathname}?${sp.toString()}` : location.pathname);
  };

  const inputValues = salesPersons
  const defaultValue = defaultSalesPerson

  return (
    <div className="flex flex-row mx-6">
      <Typography className="text-12 md:text-14 pr-8 pt-12">Sales Person:</Typography>
      <div className="text-12 md:text-14 pr-8 font-semibold">
        <AuraAutoComplete
          placeholder="All Sales Persons"
          inputValues={inputValues}
          onValueChange={handleSalesPersonChange}
          defaultValue={defaultValue || null}
          optionDisplayName="name"
          // isOptionEqualToValue={(option, value) => option.name === value.name}
          isOptionEqualToValue={(option, value1) => {
            if (option && option.name && value1.name) {
              return option.name === value1.name;
            }
            return false;
          }}
          hideClearable={false}
          renderOption={(renderprops, option) => (
            <Box
              component="li"
              {...renderprops}
              key={option.id}
              className={`${renderprops.className} !block py-2 px-10`}
            >
              <div className="font-medium">{option.name}</div>
              <div className="font-light text-10 text-gray-400">{option.email}</div>
            </Box>
          )}
          filterOptions={(options, { inputValue }) =>
            matchSorter(options, inputValue, {
              keys: ['name'],
              threshold: matchSorter.rankings.STARTS_WITH,
            })
          }
          sxStyle={autoCompleteSxStyle}
        />
      </div>
    </div>
  );
}

export function OrderTypeFilter({ filterValue, setSelectedFilterType, setSupplier, setCustomer, setSalesPerson, options, role, type, isSupplierInvoice, autoCompleteSxStyle = null }) {
  const dispatch = useDispatch();
  const { location } = window;
  const sp = new URLSearchParams(location.search);

  const clearFilterValues = () => {
    const keysToDelete = Array.from(sp.keys());
    keysToDelete.forEach(key => sp.delete(key));
  }

  const filterFunction = (value) => {
    setSelectedFilterType(value)
    dispatch(setCustomer(null));
    dispatch(setSalesPerson(null));
    sp.delete('sid')
    sp.delete('cid')
    sp.delete('sales_person')
    sp.delete('archive')
    if (['admin', 'staff', 'sales'].includes(role)) {
      switch (value.value) {
        case 'supplier':
          dispatch(setSupplier({ name: 'All', value: 'all', email: '', id: 'all' }));
          clearFilterValues()
          if (!isSupplierInvoice)
            sp.set('sid', 'all')
          break;
        case 'customer_archive':
          dispatch(setSupplier(null));
          clearFilterValues()
          sp.set('archive', true)
          if (type !== "invoice") {
            sp.set('cid', 'all')
            sp.set('sales_person', 'all')
          }
          break;
        case 'supplier_archive':
          dispatch(setSupplier({ name: 'All', value: 'all', email: '', id: 'all' }));
          clearFilterValues()
          if (!isSupplierInvoice) {
            sp.set('sid', 'all')
          }
          if (isSupplierInvoice) {
            sp.delete('sid', 'all')
          }
          sp.set('archive', true)
          break;
        case 'customer':
          dispatch(setSupplier(null))
          clearFilterValues()
          break;
        case 'archive_rfq':
          clearFilterValues()
          sp.set('cid', 'all')
          sp.set('sales_person', 'all')
          sp.set('archive', true)
          break;
        case 'archive_quote':
          clearFilterValues()
          sp.set('cid', 'all')
          sp.set('sales_person', 'all')
          sp.set('archive', true)
          break;
        default:
          clearFilterValues()
          break;
      }
    }
    if (role === 'customer') {
      switch (value.value) {
        case 'customer_archive':
          dispatch(setSupplier(null));
          clearFilterValues()
          sp.set('archive', true)
          break;
        case 'customer':
          dispatch(setSupplier(null))
          clearFilterValues()
          break;
        default:
          break;
      }
    }

    window.history.replaceState(null, "", sp.toString() ? `${location.pathname}?${sp.toString()}` : location.pathname);
  }

  return (
    <div className="flex flex-row mx-6">
      <Typography className="text-12 md:text-14 pr-8 pt-12"> Type:</Typography>
      <div className="text-12 md:text-14 pr-8 font-semibold">
        <AuraAutoComplete
          inputValues={options}
          onValueChange={filterFunction}
          defaultValue={filterValue}
          optionDisplayName="name"
          isOptionEqualToValue={(option, value1) => {
            if (option && option.name && value1.name) {
              return option.name === value1.name;
            }
            return false;
          }}
          sxStyle={autoCompleteSxStyle}
        />
      </div>
    </div>
  );
}

export function IncludeInvoiceFilter({ setSelectedFilterType, filterValue, includeInvoice, setIncludeInvoice }) {
  const { location } = window;
  const sp = new URLSearchParams(location.search);

  const handleChange = (e) => {
    const value = e.target.checked;
    setIncludeInvoice(value)
    sp.set('include_invoice_status', value);
    window.history.replaceState(null, "", sp.toString() ? `${location.pathname}?${sp.toString()}` : location.pathname);
  }

  return (
    <FormControlLabel control={<Checkbox onChange={handleChange} checked={includeInvoice} />} label="Include Invoiced Order" />
  )
}

export function IncludeArchiveFilter({ setSelectedFilterType, filterValue, includeArchive, setIncludeArchive, pageType }) {
  const { location } = window;
  const sp = new URLSearchParams(location.search);

  const handleChange = (e) => {
    const value = e.target.checked;
    setIncludeArchive(value)
    sp.set('include_archive', value);
    window.history.replaceState(null, "", sp.toString() ? `${location.pathname}?${sp.toString()}` : location.pathname);
  }

  return (
    <FormControlLabel control={<Checkbox onChange={handleChange} checked={includeArchive} />} label={pageType === 'container'? "Include Archive Container" : "Include Archive Order"}  />
  )
}

export function NotificationFilter({ filterValue, setSelectedFilterType, options, role, }) {
  const { location } = window;
  const sp = new URLSearchParams(location.search);

  const clearFilterValues = () => {
    const keysToDelete = Array.from(sp.keys());

    keysToDelete.forEach(key => sp.delete(key));
  }

  const filterFunction = (value) => {
    setSelectedFilterType(value)
    sp.delete('notes')
    if (['admin', 'staff', 'sales'].includes(role)) {
      switch (value.value) {
        case 'customers':
          clearFilterValues()
          sp.set('notes', value.value)
          break;
        case 'suppliers':
          clearFilterValues()
          sp.set('notes', value.value)
          break;
        default:
          clearFilterValues()
          break;
      }
    }

    window.history.replaceState(null, "", sp.toString() ? `${location.pathname}?${sp.toString()}` : location.pathname);
  }

  return (
    <div className="flex flex-row mx-6">
      <Typography className="text-12 md:text-14 pr-8 pt-12"> Notes:</Typography>
      <div className="text-12 md:text-14 pr-8 font-semibold">
        <AuraAutoComplete
          inputValues={options}
          onValueChange={filterFunction}
          defaultValue={filterValue}
          optionDisplayName="name"
          isOptionEqualToValue={(option, value1) => {
            if (option && option.name && value1.name) {
              return option.name === value1.name;
            }
            return false;
          }}
        />
      </div>
    </div>
  );
}

export function OrderTyepFilterFunction() {
  const dispatch = useDispatch();
  const [value, setValue] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null)
  const [inventoryOrder, setInventoryOrder] = useState(false)

  const { selectedValue } = useSelector(({ eCommerceApp }) => eCommerceApp?.orders)
  const supplierOrderType = useSelector(({ backofficeApp }) => backofficeApp?.shipmentOrder?.supplierOrderType);
  const orderTypeLocations = useSelector(({ backofficeApp }) => backofficeApp?.shipmentOrder?.orderTypeLocations);
  const selectedDrderType = useSelector(({ backofficeApp }) => backofficeApp?.shipmentOrder?.orderType);
  const orderTypeLocation = useSelector(({ backofficeApp }) => backofficeApp?.shipmentOrder?.orderTypeLocation);
  const orderTypeListItems = useSelector(({ eCommerceApp }) => eCommerceApp?.orders?.orderTypeListItems);

  const currentUser = useSelector(({ user }) => user);
  const { location } = window
  const { shipmentId } = useParams()

  useEffect(() => {
    if (orderTypeListItems) {
      setValue(orderTypeListItems);
    }
  }, []);


  const getDefaultValue = () => {
    let defaultValue = '';
    if (location.pathname === '/shipments/new') {
      if (supplierOrderType) {
        defaultValue = supplierOrderType;
      } else {
        defaultValue = value?.[2];
      }
    }
    else {
      defaultValue = selectedValue;
    }
    dispatch(setSupplierOrderType(defaultValue))
    return defaultValue;
  };

  const chooseOrderType = (selected) => {
    const orderType = new URLSearchParams(location.search)
    orderType.delete('o_type');
    dispatch(getOrderType(selected));

    if (!selected) {
      if (currentUser.role === 'supplier') {
        dispatch(setSupplierOrderType(null))
        getDefaultValue()
      }
      else {
        dispatch(setSelectedValue(null))
      }

    }
    if (selected && currentUser.role !== 'supplier') {
      orderType.set('o_type', selected.name)
      dispatch(setSelectedValue(selected))
      const params = {
        stage: 'order',
        order_type: selected.name
      }
    }
    if (selected && currentUser.role === 'supplier') {
      dispatch(setSupplierOrderType(selected))
      dispatch(resetEntities()) // Reset entities after selection of Direct Shipment and Direct Pickup
      dispatch(setOrderTypeLocation(null)) // Reset location dropdown values
      setSelectedDate(null); // Reset order date
      setInventoryOrder(false); // Reset inventory order
      if (['Direct Shipment', 'Direct Pickup'].includes(selected.display_name)) {
        // Added order_type param to get location based on selected order type
        // selected.name => Direct Shipment/Direct Pickup
        dispatch(getOrderTypeLocations({ order_type: selected.name }))

      }
    }

    window.history.replaceState(null, "", orderType.toString() ? `${location.pathname}?${orderType.toString()}` : location.pathname)
  }

  const chooseOrderTypeLocation = (selected) => {
    dispatch(resetEntities()) // Reset entities after selection of Direct Shipment and Direct Pickup
    const { display_name: displayName = null } = selectedDrderType || {};
    dispatch(setOrderTypeLocation(selected))
    const params = { direct_shipment: displayName === 'Direct Shipment' ? true : null, direct_pickup: displayName === 'Direct Pickup' ? true : null, location: selected?.value || null }
    dispatch(getAvailableOrders({ params }))
  }

  const handleDateAndInventoryOrderChangeAction = (e, mode = 'date') => {
    dispatch(resetEntities()) // Reset entities after selection of Direct Shipment and Direct Pickup
    // eslint-disable-next-line
    let params = { order_date: selectedDate ? moment(selectedDate).format('YYYY-MM-DD') : null, is_inventory: inventoryOrder }
    if (mode === 'date') {
      setSelectedDate(e);
      if (e === 'Invalid date') {
        setSelectedDate(null);
      }
      params.order_date = moment(e).format('YYYY-MM-DD')
    } else {
      setInventoryOrder(e.target?.checked);
      params.is_inventory = e.target?.checked
    }

    if (params?.order_date === 'Invalid date') {
      delete params.order_date;
    }
    dispatch(getAvailableOrders({ params }))
  }

  return (
    shipmentId === 'new' ? (<div className="flex flex-row mx-12">
      <div className='flex flex-row w-full gap-20'>
        <div className="text-12 md:text-14 pr-8 font-semibold flex">
          <Typography className="text-12 md:text-14 pr-8 pt-12" style={{ whiteSpace: "nowrap" }}> Order Type:</Typography>
          <AuraAutoComplete
            placeholder="All"
            inputValues={value}
            defaultValue={getDefaultValue()}
            onValueChange={chooseOrderType}
            optionDisplayName="name"
            hideClearable={false}
            isOptionEqualToValue={(option, value1) => {
              if (option && option.name && value1) {
                return option.name === value1;
              }
              return false;
            }}

          />
        </div>

        {['Direct Pickup', 'Direct Shipment'].includes(getDefaultValue()?.display_name) && <div className="text-12 md:text-14 pr-8 font-semibold flex">
          <Typography className="text-12 md:text-14 pr-8 pt-12" style={{ whiteSpace: "nowrap" }}> Location:</Typography>
          <AuraAutoComplete
            placeholder="Select Location"
            inputValues={orderTypeLocations}
            defaultValue={orderTypeLocation}
            onValueChange={chooseOrderTypeLocation}
            optionDisplayName="name"
            hideClearable={false}
            isOptionEqualToValue={(option, value1) => {
              if (option && option.name && value1) {
                return option.name === value1;
              }
              return false;
            }}
          />
        </div>}

        {["Regular"].includes(getDefaultValue()?.display_name) &&
          <>
            <div className="text-12 md:text-14 pr-8 font-semibold flex">
              <Typography className="text-12 md:text-14 pr-8 pt-12" style={{ whiteSpace: "nowrap" }}> Order Date:</Typography>

              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  style={{ zIndex: 9999 }}
                  value={selectedDate}
                  onChange={(date) => handleDateAndInventoryOrderChangeAction(date, 'date')}
                  renderInput={(params) => <TextField {...params} variant="standard" inputProps={{
                    ...params.inputProps,
                    placeholder: 'DD-MM-YYYY'
                  }} />}
                  disableFuture
                />
              </LocalizationProvider>
            </div>

            <div className="text-12 md:text-14 pr-8 font-semibold flex">
              <Typography className="text-12 md:text-14 pr-8 pt-12" style={{ whiteSpace: "nowrap" }}> Inventory order :</Typography>
              <FormControlLabel control={<Checkbox onChange={(e) => handleDateAndInventoryOrderChangeAction(e, 'inventoryOrder')} checked={inventoryOrder} />} label="" />
            </div>

          </>}
      </div> </div>) : null
  );
}


export function ContainerOrderTypeFilterFunction() {
  const dispatch = useDispatch();
  const [value, setValue] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null)
  const [inventoryOrder, setInventoryOrder] = useState(false)

  const { selectedValue } = useSelector(({ eCommerceApp }) => eCommerceApp?.orders)
  const supplierOrderType = useSelector(({ backofficeApp }) => backofficeApp?.shipmentOrder?.supplierOrderType);
  const orderTypeLocations = useSelector(({ backofficeApp }) => backofficeApp?.shipmentOrder?.orderTypeLocations);
  const selectedDrderType = useSelector(({ backofficeApp }) => backofficeApp?.shipmentOrder?.orderType);
  const orderTypeLocation = useSelector(({ backofficeApp }) => backofficeApp?.shipmentOrder?.orderTypeLocation);

  const currentUser = useSelector(({ user }) => user);
  const { location } = window
  // const { shipmentId } = useParams();
  const shipmentId = 'new';

  useEffect(() => {
    dispatch(getStatuses('order_type'))
      .unwrap()
      .then((res) => {
        setValue(res);
        dispatch(setOrderTypes(res))
      });
  }, [dispatch]);


  const getDefaultValue = () => {
    let defaultValue = '';
    if (location.pathname === '/shipments/new') {
      if (supplierOrderType) {
        defaultValue = supplierOrderType;
      } else {
        defaultValue = value?.[2];
      }
    }
    else {
      defaultValue = selectedValue;
    }
    dispatch(setSupplierOrderType(defaultValue))
    return defaultValue;
  };

  const chooseOrderType = (selected) => {
    const orderType = new URLSearchParams(location.search)
    orderType.delete('o_type');
    dispatch(getOrderType(selected));

    if (!selected) {
      if (currentUser.role === 'supplier') {
        dispatch(setSupplierOrderType(null))
        getDefaultValue()
      }
      else {
        dispatch(setSelectedValue(null))
      }
    }
    if (selected && currentUser.role !== 'supplier') {
      orderType.set('o_type', selected.name)
      dispatch(setSelectedValue(selected))
      const params = {
        stage: 'order',
        order_type: selected.name
      }
    }
    if (selected && currentUser.role === 'supplier') {
      dispatch(setSupplierOrderType(selected))
      dispatch(resetEntities()) // Reset entities after selection of Direct Shipment and Direct Pickup
      dispatch(setOrderTypeLocation(null)) // Reset location dropdown values
      setSelectedDate(null); // Reset order date
      setInventoryOrder(false); // Reset inventory order
      if (['Direct Shipment', 'Direct Pickup'].includes(selected.display_name)) {
        // Added order_type param to get location based on selected order type
        // selected.name => Direct Shipment/Direct Pickup
        dispatch(getOrderTypeLocations({ order_type: selected.name }))
      }
    }
    window.history.replaceState(null, "", orderType.toString() ? `${location.pathname}?${orderType.toString()}` : location.pathname)
  }

  const chooseOrderTypeLocation = (selected) => {
    dispatch(resetEntities()) // Reset entities after selection of Direct Shipment and Direct Pickup
    const { display_name: displayName = null } = selectedDrderType || {};
    dispatch(setOrderTypeLocation(selected))
    const params = { direct_shipment: displayName === 'Direct Shipment' ? true : null, direct_pickup: displayName === 'Direct Pickup' ? true : null, location: selected?.value || null }
    dispatch(getAvailableOrders({ params }))
  }

  const handleDateAndInventoryOrderChangeAction = (e, mode = 'date') => {
    dispatch(resetEntities()) // Reset entities after selection of Direct Shipment and Direct Pickup
    // eslint-disable-next-line
    let params = { order_date: selectedDate ? moment(selectedDate).format('YYYY-MM-DD') : null, is_inventory: inventoryOrder }
    if (mode === 'date') {
      setSelectedDate(e);
      if (e === 'Invalid date') {
        setSelectedDate(null);
      }
      params.order_date = moment(e).format('YYYY-MM-DD')
    } else {
      setInventoryOrder(e.target?.checked);
      params.is_inventory = e.target?.checked
    }

    if (params?.order_date === 'Invalid date') {
      delete params.order_date;
    }
    dispatch(getAvailableOrders({ params }))
  }

  return (
    shipmentId === 'new' ? (<div className="flex flex-row mx-12">
      <div className='flex flex-row w-full gap-20 display-block'>
        <div className="text-12 md:text-14 pr-8 font-semibold flex">
          <Typography className="text-12 md:text-14 pr-8 pt-12" style={{ whiteSpace: "nowrap" }}> Order Type:</Typography>
          <AuraAutoComplete
            placeholder="All"
            inputValues={value}
            defaultValue={getDefaultValue()}
            onValueChange={chooseOrderType}
            optionDisplayName="name"
            hideClearable={false}
            isOptionEqualToValue={(option, value1) => {
              if (option && option.name && value1) {
                return option.name === value1;
              }
              return false;
            }}
          />
        </div>

        {['Direct Pickup', 'Direct Shipment'].includes(getDefaultValue()?.display_name) && <div className="text-12 md:text-14 pr-8 font-semibold flex">
          <Typography className="text-12 md:text-14 pr-8 pt-12" style={{ whiteSpace: "nowrap" }}> Location:</Typography>
          <AuraAutoComplete
            placeholder="Select Location"
            inputValues={orderTypeLocations}
            defaultValue={orderTypeLocation}
            onValueChange={chooseOrderTypeLocation}
            optionDisplayName="name"
            hideClearable={false}
            isOptionEqualToValue={(option, value1) => {
              if (option && option.name && value1) {
                return option.name === value1;
              }
              return false;
            }}
          />
        </div>}

        {["Regular"].includes(getDefaultValue()?.display_name) &&
          <>
            <div className="text-12 md:text-14 pr-8 font-semibold flex">
              <Typography className="text-12 md:text-14 pr-8 pt-12" style={{ whiteSpace: "nowrap" }}> Order Date:</Typography>

              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  style={{ zIndex: 9999 }}
                  value={selectedDate}
                  onChange={(date) => handleDateAndInventoryOrderChangeAction(date, 'date')}
                  renderInput={(params) => <TextField {...params} variant="standard" inputProps={{
                    ...params.inputProps,
                    placeholder: 'DD-MM-YYYY'
                  }} />}
                  disableFuture
                />
              </LocalizationProvider>
            </div>

            <div className="text-12 md:text-14 pr-8 font-semibold flex">
              <Typography className="text-12 md:text-14 pr-8 pt-12" style={{ whiteSpace: "nowrap" }}> Inventory order :</Typography>
              <FormControlLabel control={<Checkbox onChange={(e) => handleDateAndInventoryOrderChangeAction(e, 'inventoryOrder')} checked={inventoryOrder} />} label="" />
            </div>

          </>}
      </div> </div>) : null
  );
}

export function OutboundShipmentCreationFilterFunction(props) {
  const { setCustomer, defaultCustomer, autoCompleteSxStyle = null } = props;
  const dispatch = useDispatch();
  const customers = useSelector(({ backofficeApp }) => backofficeApp?.shipmentOrder?.customers);
  const shipmentWeightRanges = useSelector(({ backofficeApp }) => backofficeApp?.shipmentOrder?.shipmentWeightRanges);
  const defaultWeightRange = useSelector(({ backofficeApp }) => backofficeApp?.shipmentOrder?.weightRange);

  const handleWeightChange = (value) => {
    dispatch(setWeightRange(value));
    dispatch(setCustomer(null));
    dispatch(getCustomers({ weight_type: value }))
  };

  const handleCustomerChange = (value) => {
    dispatch(setCustomer(value));
  };

  return (
    <div className="flex flex-col mx-12 md:flex-row">
      <div className="flex flex-row">
        <Typography className="text-12 md:text-14 pr-8 pt-12">
            Weight
        </Typography>
        <div className="text-12 md:text-14 pr-8 w-[200px] font-semibold">
          <AuraAutoComplete
            placeholder= "All"
            inputValues={shipmentWeightRanges}
            defaultValue={defaultWeightRange || shipmentWeightRanges?.[0] || null}
            hideClearable
            onValueChange={handleWeightChange}
            isOptionEqualToValue={(option, value) => option === value}
            renderOption={(renderprops, option) => (
              <Box
                component="li"
                {...renderprops}
                key={option.id}
                className={`${renderprops.className} !block py-2 px-10`}
              >
                <div className="capitalize">{option}</div>
              </Box>
            )}
            sxStyle={{
              width: "180px !important",
              ".MuiInput-input": {
                padding: "0 !important",
                textTransform: 'capitalize'
              },
            }}
          />
        </div>
      </div>
      <div className="flex flex-row">
        <Typography className="text-12 md:text-14 pr-8 pt-12">
            Customer
        </Typography>
        <div className="text-12 md:text-14 pr-8 font-semibold">
          <AuraAutoComplete
            placeholder= "All Customers"
            inputValues={customers}
            onValueChange={handleCustomerChange}
            defaultValue={defaultCustomer || null}
            optionDisplayName="name"
            // isOptionEqualToValue={(option, value) => option.name === value.name}
            isOptionEqualToValue={(option, value1) => {
              if (option && option.name && value1.name) {
                return option.name === value1.name;
              }
              return false;
            }}
            hideClearable={false}
            renderOption={(renderprops, option) => (
              <Box
                component="li"
                {...renderprops}
                key={option.id}
                className={`${renderprops.className} !block py-2 px-10`}
              >
                <div className="font-medium">{option.name}</div>
                <div className="font-light text-10 text-gray-400">
                  {option.email}
                </div>
              </Box>
            )}
            filterOptions={(options, { inputValue }) =>
              matchSorter(options, inputValue, {
                keys: ["name"],
                threshold: matchSorter.rankings.STARTS_WITH,
              })
            }
            sxStyle={autoCompleteSxStyle}
          />
        </div>
      </div>
    </div>
  );
}

export default memo(CustomerFilter);
