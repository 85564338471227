import { Card, CardContent, Icon, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { startsWith } from 'lodash'
import { useDispatch } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { formatDateString, formatWeight, getCustomerBasedProfile } from 'app/main/apps/common/AuraFunctions';
import { Loader } from 'app/main/apps/common/ListInfoMessage';
import ImageViewerWindow from 'app/main/dashboards/main/dialogs/ImageViewerWindow';
import pdfViewerWindow from 'app/main/dashboards/main/dialogs/pdfViewerWindow';
import { getProductionSheet } from 'app/main/apps/backoffice/store/shipmentSlice';
import { getShippingLabelData, getPrintProductionData } from '../../store/orderSlice';

function ShippingLabelDetails({ labelRef, isPrinting, isprintProduction, isAdminOrStaffUser }) {
    const params = useParams()
    const dispatch = useDispatch()
    const { orderId } = params
    const location = useLocation()
    const { pathname } = location
    const pathType = pathname.split('/')[1];
    const pathType2 = pathname.split('/')[2];
    const isOutboundPrintLabel = pathType === "outbound" && pathType2 === "print-label";
    const isInboundPrintLabel = pathType === "shipments" && pathType2 === "print-label";
    const [loading, setLoading] = useState(true)
    const [shippingLabel, setShippingLabel] = useState()
    const totalLabels = !isprintProduction ? shippingLabel?.shipping_label?.orders?.length : shippingLabel?.length;
    const action = !isprintProduction ? getShippingLabelData : getPrintProductionData;

    useEffect(() => {
        function init() {
            if(isAdminOrStaffUser && pathType2 === "print-label") {
                dispatch(getProductionSheet(orderId)).then(({ payload }) => {
                    setShippingLabel(payload)
                    setLoading(false)
                })
            } else {
                dispatch(action({ [isprintProduction ? "shipment_id" : "id"]: orderId, stage: isprintProduction ? "in-production" : undefined })).then(({ payload }) => {
                    setShippingLabel(payload)
                    setLoading(false)
                })
            }
        }
        init()
    }, [])

    const getClassName = (index) => {
        if (isPrinting && index > 0 && index % 5 === 0) {
            return 'mb-96'
        }
        if (isPrinting && index !== totalLabels - 1 && totalLabels > 2 && (index + 1) % 2 === 0)
            return 'mb-48'
        return ''
    }

    return (
        loading ? <Loader className="h-5vh mx-auto my-auto inset-0" /> : (<>
            {(!isprintProduction && (<>
                <div className='mt-30 m-6 w-full shipping-label' ref={labelRef}>
                    {shippingLabel?.shipping_label?.orders.map((o, index) => (
                        <Card key={o.orderId} variant="outlined" className={clsx('w-full mt-20 p-0', getClassName(index))}>
                            <CardContent className='p-0 !pb-0'>
                                <div className='flex border-2 !border-black justify-evenly border-radius rounded-2xl'>
                                    <div className="items-center text-center border-r-2 !border-black w-224">
                                        <div className="border-b-2 !border-black flex items-center justify-center font-bold min-h-60 max-h-60">
                                            <img src={getCustomerBasedProfile.companyLogo} alt='logo' className='object-cover h-32' />
                                        </div>
                                        <div className="flex text-center h-full">
                                            <div className="border-r-2 !border-black w-full">
                                                <Typography className='font-bold border-b-2 !border-black pb-2 text-[20px]'>LEN</Typography>
                                                <Typography className='text-[20px]'>{o.length}</Typography>
                                            </div>
                                            <div className="border-r-2 !border-black w-full">
                                                <Typography className='font-bold border-b-2 !border-black pb-2 text-[20px]'>THK</Typography>
                                                <Typography className='text-[20px]'>{o.width}</Typography>
                                            </div>
                                            <div className="w-full">
                                                <Typography className='font-bold border-b-2 !border-black pb-2 text-[20px]'>HGT</Typography>
                                                <Typography className='text-[20px]'>{o.height}</Typography>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="items-center border-r-2 !border-black w-1/2">
                                        <div className='font-bold mb-2 text-center border-b-2 !border-black min-h-60 max-h-60'>
                                            <Typography variant="h5" className=''>{shippingLabel?.shipping_label?.company_name}</Typography>
                                            <Typography className='text-[20px] whitespace-nowrap'>{shippingLabel?.shipping_label?.company_address}</Typography>
                                        </div>
                                        <div className="flex  text-center h-full">
                                            <div className="border-r-2 !border-black w-full">
                                                <Typography className='font-bold border-b-2 !border-black text-[20px]'>Color</Typography>
                                                <Typography className='text-[20px] whitespace-nowrap'>{o.color}</Typography>
                                            </div>
                                            <div className="border-r-2 !border-black w-full">
                                                <Typography className='font-bold border-b-2 !border-black text-[20px]'>Shape</Typography>
                                                <Typography className='text-[20px]'>{o.type}</Typography>
                                            </div>
                                            <div className="w-full">
                                                <Typography className='font-bold border-b-2 !border-black text-[20px]'>Finish</Typography>
                                                <Typography className='text-[20px]'>{o.polish}</Typography>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="items-center border-r-2 !border-black w-1/6">
                                        <Typography variant="h5" className='font-bold mb-2 flex items-center justify-center border-b-2 !border-black min-h-60 max-h-60'>{o.box_number}</Typography>
                                        <div className="flex justify-evenly text-center h-full">
                                            <div className="border-r-2 !border-black-1 !border-black text-center w-full h-full ">
                                                <Typography className='font-bold border-b-2 !border-black text-[20px]'>Holes</Typography>
                                                <Typography className='text-[20px]'>Nil</Typography>
                                            </div>
                                            <div className="text-center w-full">
                                                <Typography className='font-bold border-b-2 !border-black text-[20px]'>QTY</Typography>
                                                <Typography className='text-[20px]'>{o.quantity}</Typography>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-center w-1/4">
                                        <Typography variant="h5" className='font-bold mb-2 flex items-center justify-center border-b-2 !border-black min-h-60 max-h-60'>
                                            {shippingLabel?.shipping_label.container_number}
                                        </Typography>
                                        <div className="flex justify-evenly h-full">
                                            <div className="border-r-2 !border-black text-center w-full">
                                                <Typography className='font-bold border-b-2 !border-black text-[20px]'>Decedent</Typography>
                                                <Typography className='text-[20px]'>{o.decedents}</Typography>
                                            </div>
                                            <div className="whitespace-nowrap w-full">
                                                <Typography className='font-bold border-b-2 !border-black text-[20px]'>Order No.</Typography>
                                                <Typography className='text-[20px]'>{o.order_no ? `#${o.order_no}` : ''}</Typography>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </CardContent>
                        </Card>
                    ))}
                </div> </>)
            )}
            {(isprintProduction &&
                <div className='mt-30 m-10 w-full shipping-label'>
                    <Card variant="outlined" className={`p-0 ${isAdminOrStaffUser && "mb-24"}`} ref={labelRef}>
                        {isAdminOrStaffUser && (<>
                            <div className="flex flex-col md:flex-row justify-between mt-16">
                                <div className="flex flex-col">
                                    {isOutboundPrintLabel && <>
                                    <div className="flex flex-row">
                                        <Typography
                                        className="font-medium mx-8 ml-16 text-18"
                                        color="inherit"
                                        >
                                        Customer Name:
                                        </Typography>
                                        <div className="font-medium text-18 opacity-75">
                                            {shippingLabel?.name || "-"}
                                        </div>
                                    </div>
                                    <>
                                        <div className="flex flex-row md:mt-8">
                                            <Typography
                                            className="font-medium mx-8 ml-16 text-16"
                                            color="inherit"
                                            >
                                            Address Line 1:
                                            </Typography>
                                            <div className="font-medium text-16 opacity-75">
                                                {shippingLabel?.address_line_1 || "-"}
                                            </div>
                                        </div>
                                        <div className="flex flex-row ">
                                            <Typography
                                            className="font-medium mx-8 ml-16 text-16"
                                            color="inherit"
                                            >
                                            Address Line 2:
                                            </Typography>
                                            <div className="font-medium text-16 opacity-75">
                                                {shippingLabel?.address_line_2 || "-"}
                                            </div>
                                        </div>
                                        <div className="flex flex-row ">
                                            <Typography
                                            className="font-medium mx-8 ml-16 text-16"
                                            color="inherit"
                                            >
                                            Profile:
                                            </Typography>
                                            <div className="font-medium text-16 opacity-75">
                                                {shippingLabel?.profile_name || "-"}
                                            </div>
                                        </div>
                                    </>
                                    </>}
                                </div>
                                <div className="flex flex-col md:flex-row">
                                    {isOutboundPrintLabel && <>
                                        <div className="flex flex-col md:border-grey-900 md:border-r-1">
                                            <div className="flex flex-row w-320">
                                                <div className="w-1/2">
                                                    <Typography className="font-medium mx-8 ml-16 text-16" color="inherit">
                                                        Shipment No:
                                                    </Typography>
                                                </div>
                                                <div className="w-1/2 font-medium text-16 opacity-75">
                                                    {shippingLabel?.shipment_no || "-"}
                                                </div>
                                            </div>
                                            <div className="flex flex-row w-320">
                                                <div className="w-1/2">
                                                    <Typography className="font-medium mx-8 ml-16 text-16" color="inherit">
                                                        Shipment Date:
                                                    </Typography>
                                                </div>
                                                <div className="w-1/2 font-medium text-16 opacity-75">
                                                    {formatDateString(shippingLabel?.shipment_date) || "-"}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex flex-col">
                                            <div className="flex flex-row w-320">
                                                <div className="w-1/2">
                                                    <Typography className="font-medium mx-8 ml-16 text-16" color="inherit">
                                                        Shipment Type:
                                                    </Typography>
                                                </div>
                                            <div className="w-1/2 font-medium text-16 opacity-75">
                                                {shippingLabel?.shipment_type || "-"}
                                            </div>
                                        </div>
                                        <div className="flex flex-row w-320">
                                            <div className="w-1/2">
                                                <Typography className="font-medium mx-8 ml-16 text-16" color="inherit" >
                                                    No of Pallets:
                                                </Typography>
                                            </div>
                                            <div className="w-1/2 font-medium text-16 opacity-75">
                                                {shippingLabel?.pallet_count || "-"}
                                            </div>
                                        </div>
                                        <div className="flex flex-row w-320">
                                            <div className="w-1/2">
                                                <Typography className="font-medium mx-8 ml-16 text-16" color="inherit" >
                                                    Total Weight:
                                                </Typography>
                                            </div>
                                            <div className="w-1/2 font-medium text-16 opacity-75">
                                                {shippingLabel?.total_weight ? formatWeight(shippingLabel?.total_weight): '-'}
                                            </div>
                                        </div>
                                    </div>
                                    </>}
                                    {!isOutboundPrintLabel &&
                                    <div className="flex flex-col mr-16 ">
                                        <div className="flex ">
                                            <Typography
                                                className="font-medium truncate mx-8 text-18"
                                                color="inherit"
                                            >
                                                Shipment Date:
                                            </Typography>
                                            <div className="font-medium whitespace-nowrap opacity-75 mr-16 text-18">
                                                {formatDateString(shippingLabel?.shipment_date) || "-"}
                                            </div>
                                        </div>
                                    </div>}
                                </div>
                            </div>
                        </>)}
                        {!isAdminOrStaffUser &&
                            <Typography variant='h4'className='w-full mt-20 p-0 text-center'>
                                {shippingLabel?.container_number}
                            </Typography>
                        }
                        <CardContent className={`p-10 ${(isOutboundPrintLabel || isInboundPrintLabel) && 'pt-0'}`}>
                            {shippingLabel?.orders?.map((o, index) => (<>
                                <Card key={o.orderId} variant="contained" className={`w-full mt-20 p-0 ${isPrinting && 'mb-24'} ${(isOutboundPrintLabel || isInboundPrintLabel) && 'table-responsive'}`}>
                                    <CardContent className='p-0 pb-0'>
                                        <Table sx={{ border: '1px solid #ffff' }} >
                                            <TableHead>
                                                {isPrinting && pathType2 === "print-label" ?
                                                    <TableRow sx={{backgroundColor: '#EEEEEE'}}>
                                                        <TableCell sx={{maxWidth: "50px"}} className='font-medium align-text-top px-10'>S.No</TableCell>
                                                        <TableCell sx={{maxWidth: "70px"}} className='font-medium align-text-top px-10'>Order No</TableCell>
                                                        <TableCell sx={{maxWidth: "150px"}} className='font-medium align-text-top px-10'>Product Name</TableCell>
                                                        <TableCell sx={{maxWidth: "100px"}} className='font-medium align-text-top px-10'>Sub Type</TableCell>
                                                        {isAdminOrStaffUser && <TableCell sx={{maxWidth: "70px"}} className='font-medium align-text-top px-10'>Reference No</TableCell>}
                                                        {isAdminOrStaffUser && <TableCell sx={{maxWidth: "70px"}} className='font-medium align-text-top px-10'>Decedent</TableCell>}
                                                        {isOutboundPrintLabel &&
                                                            <TableCell sx={{maxWidth: "80px"}} className='font-medium align-text-top px-10'>Order Type</TableCell>
                                                        }
                                                        <TableCell sx={{maxWidth: "100px"}} className='font-medium align-text-top px-10'>Qty</TableCell>
                                                        <TableCell sx={{maxWidth: "100px"}} className='font-medium align-text-top px-10'>Weight lbs</TableCell>
                                                        <TableCell sx={{maxWidth: "100px"}} className='font-medium align-text-top px-10'>Container Number</TableCell>
                                                        <TableCell sx={{maxWidth: "100px"}} className='font-medium align-text-top px-10'>Box Number</TableCell>
                                                    </TableRow>
                                                :
                                                    <TableRow sx={{backgroundColor: '#EEEEEE'}}>
                                                        <TableCell sx={{minWidth: "50px", maxWidth: "50px"}} className='whitespace-nowrap font-bold'>S.No</TableCell>
                                                        <TableCell sx={{minWidth: isAdminOrStaffUser ? "100" : "120px", maxWidth: isAdminOrStaffUser ? "100" : "120px"}} className='whitespace-nowrap font-bold'>Order No</TableCell>
                                                        <TableCell sx={{minWidth: isAdminOrStaffUser ? "250px" : "440px", maxWidth: isAdminOrStaffUser ? "250px" : "440px"}} className='whitespace-nowrap font-bold'>Product Name</TableCell>
                                                        {isAdminOrStaffUser && <TableCell sx={{minWidth: "120px", maxWidth: "120px"}} className='whitespace-nowrap font-bold'>Sub Type</TableCell>}
                                                        {!isAdminOrStaffUser && <TableCell sx={{minWidth: "170px", maxWidth: "170px"}} className='whitespace-nowrap font-bold'>Decedent</TableCell>}
                                                        {!isAdminOrStaffUser && <TableCell sx={{minWidth: "170px", maxWidth: "170px"}} className='whitespace-nowrap font-bold'>Reference No</TableCell>}
                                                        {isAdminOrStaffUser && <TableCell sx={{minWidth: "100px", maxWidth: "100px"}} className='whitespace-nowrap font-bold'>Reference No</TableCell>}
                                                        {isAdminOrStaffUser && <TableCell sx={{minWidth: "100px", maxWidth: "100px"}} className='whitespace-nowrap font-bold'>Decedent</TableCell>}
                                                        {isAdminOrStaffUser && isOutboundPrintLabel && <TableCell sx={{minWidth: "100px", maxWidth: "100px"}} className='whitespace-nowrap font-bold'>Order Type</TableCell>}
                                                        <TableCell sx={{minWidth: "80px", maxWidth: "80px"}} className='whitespace-nowrap font-bold'>Qty</TableCell>
                                                        <TableCell sx={{minWidth: "100px", maxWidth: "100px"}} className='whitespace-nowrap font-bold'>Weight lbs</TableCell>
                                                        {!isAdminOrStaffUser && <TableCell sx={{minWidth: "120px", maxWidth: "120px"}} className='whitespace-nowrap font-bold'>Total Weight lbs</TableCell>}
                                                        {isAdminOrStaffUser && <TableCell sx={{minWidth: "150px", maxWidth: "150px"}} className='whitespace-nowrap font-bold'>Container Number</TableCell>}
                                                        <TableCell sx={{minWidth: "200px", maxWidth: "200px"}} className='whitespace-nowrap font-bold'>Box Number</TableCell>
                                                    </TableRow>
                                                }
                                            </TableHead>
                                            <TableBody>
                                                {isPrinting && pathType2 === "print-label" ?
                                                <TableRow key={index}>
                                                    <TableCell sx={{maxWidth: "50px"}} className='whitespace-nowrap align-text-top px-10'>{index + 1}</TableCell>
                                                    <TableCell sx={{maxWidth: "70px"}} className='whitespace-nowrap align-text-top px-10'>{o.order_no || o.orderNo}</TableCell>
                                                    <TableCell sx={{maxWidth: "150px"}} className='break-words align-text-top px-10'>{o.name || o.product_name}</TableCell>
                                                    <TableCell sx={{maxWidth: "100px"}} className='break-words align-text-top px-10'>{o.subType || o.sub_type}</TableCell>
                                                    <TableCell sx={{maxWidth: "70px"}} className='break-words align-text-top px-10'>{o.customer_provider_number || o.reference_number}</TableCell>
                                                    <TableCell sx={{maxWidth: "70px"}} className='break-words align-text-top px-10'>{o.decedent || o.decedents}</TableCell>
                                                    {isOutboundPrintLabel && <TableCell sx={{maxWidth: "80px"}} className='break-words align-text-top px-10'>{o.order_type}</TableCell>}
                                                    <TableCell sx={{maxWidth: "100px"}} className='whitespace-nowrap align-text-top px-10'>{o.quantity}</TableCell>
                                                    <TableCell sx={{maxWidth: "100px"}} className='whitespace-nowrap align-text-top px-10'>{o.weight}</TableCell>
                                                    <TableCell sx={{maxWidth: "100px"}} className='break-words align-text-top px-10'>{o.container_number}</TableCell>
                                                    <TableCell sx={{maxWidth: "100px"}} className='break-words align-text-top px-10'>{o.box_number && o.box_number.replace(/,/g, ', ')}</TableCell>
                                                </TableRow>
                                                :
                                                <TableRow key={index}>
                                                    <TableCell sx={{minWidth: "50px", maxWidth: "50px"}} className='whitespace-nowrap align-text-top'>{index + 1}</TableCell>
                                                    <TableCell sx={{minWidth: isAdminOrStaffUser ? "100px" : "120px", maxWidth: isAdminOrStaffUser ? "100" : "120px"}} className='whitespace-nowrap align-text-top'>{o.order_no || o.orderNo}</TableCell>
                                                    <TableCell  sx={{minWidth: isAdminOrStaffUser ? "250px" : "440px", maxWidth: isAdminOrStaffUser ? "250px" : "440px"}} className='break-words align-text-top '>{o.name || o.product_name}</TableCell>
                                                    {isAdminOrStaffUser && <TableCell sx={{minWidth: "120px", maxWidth: "120px"}} className='break-words align-text-top '>{o.subType || o.sub_type}</TableCell>}
                                                    {!isAdminOrStaffUser && <TableCell sx={{minWidth: "170px", maxWidth: "170px"}} className='break-words align-text-top '>{o.decedents}</TableCell>}
                                                    {!isAdminOrStaffUser && <TableCell sx={{minWidth: "170px", maxWidth: "170px"}} className='break-words align-text-top '>{o.reference_no || o.reference_number}</TableCell>}
                                                    {isAdminOrStaffUser && <TableCell sx={{minWidth: "100px", maxWidth: "100px"}} className='break-words align-text-top '>{o.reference_no || o.reference_number || o.customer_provider_number}</TableCell>}
                                                    {isAdminOrStaffUser && <TableCell sx={{minWidth: "100px", maxWidth: "100px"}} className='break-words align-text-top '>{o.decedents || o.decedent}</TableCell>}
                                                    {isAdminOrStaffUser && isOutboundPrintLabel && <TableCell sx={{minWidth: "100px", maxWidth: "100px"}} className='break-words align-text-top '>{o.order_type}</TableCell>}
                                                    <TableCell sx={{minWidth: "80px", maxWidth: "80px"}} className='whitespace-nowrap align-text-top'>{o.quantity}</TableCell>
                                                    <TableCell sx={{minWidth: "100px", maxWidth: "100px"}} className='whitespace-nowrap align-text-top'>{o.weight}</TableCell>
                                                    {!isAdminOrStaffUser && <TableCell sx={{minWidth: "120px", maxWidth: "120px"}} className='whitespace-nowrap align-text-top'>{o.quantity * o.weight}</TableCell>}
                                                    {isAdminOrStaffUser && <TableCell sx={{minWidth: "150px", maxWidth: "150px"}} className='break-words align-text-top '>{o.container_number}</TableCell>}
                                                    <TableCell sx={{minWidth: "200px", maxWidth: "200px"}} className='break-words align-text-top '>{o.box_number && o.box_number.replace(/,/g, ', ')}</TableCell>
                                                </TableRow>
                                                }
                                            </TableBody>
                                        </Table>
                                        {o?.attachments?.length > 0 && (
                                            <div className='flex flex-row gap-10'>
                                                {o?.attachments?.map((media, ind) => {
                                                    const isPDF = media.mime === 'application/pdf' || startsWith(media?.url, 'data:application/pdf') || media['Content-Type'] === 'application/pdf';
                                                    return (
                                                        <div
                                                            role="button"
                                                            onClick={() => isPDF ? pdfViewerWindow(media.data || media?.url) : ImageViewerWindow([media])}
                                                            onKeyDown={() => { }}
                                                            tabIndex={0}
                                                            className='p-20 flex item-center justify-center gap-10'

                                                        >
                                                            <img
                                                                src={
                                                                    startsWith(media['Content-Type'], 'image/')
                                                                        ? `data:${media['Content-Type']};base64,${media.data}`
                                                                        : 'assets/icons/pdf.png'
                                                                }
                                                                className={clsx(
                                                                    'flex justify-center w-200 h-200 rounded-16 mr-12 mb-12 overflow-hidden outline-none border border-black rounded-lg  shadow hover:shadow-lg relative cursor-pointer')}
                                                                alt="rfq"
                                                            />
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        )}
                                    </CardContent>
                                </Card>
                            </>))}
                        </CardContent>
                    </Card>
                </div>
            )}
        </>)
    );
}
export default ShippingLabelDetails;