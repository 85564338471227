import format from "date-fns/format";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { viewNotes } from "app/main/dashboards/main/store/widgetsSlice";
import { Loader, ShowMessage } from "app/main/apps/common/ListInfoMessage";
import NotificationCard from "app/main/apps/backoffice/notifications/NotificationCard";

function ViewNotes(props) {
  const { paramData } = props;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [notes, setNotes] = useState([]);

  useEffect(() => {
    // Add extra OR condition here for undefined ID in case of any new view notes history
    if(paramData?.reqParam && (paramData?.reqParam?.stage === 'shipment' && paramData?.reqParam?.shipment_id)) {
      setLoading(true);
      dispatch(viewNotes(paramData.reqParam)).then(({ payload }) => {
        setLoading(false);
        setNotes(payload);
      });
    }
  }, [paramData]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="mb-10">
          {notes && notes?.length > 0 ? (
            notes?.map((item, index) => {
              return (
                <NotificationCard
                  key={item.id}
                  className="mb-16"
                  item={item}
                  onClose={() => console.log("close")}
                  parent="notes"
                />
              );
            })
          ) : (
            <div style={{ height: "30vh", width: "100%" }}>
              <ShowMessage message="No Notes Found!" />
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default ViewNotes;
