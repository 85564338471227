import { authRoles } from 'app/auth';
import { lazy } from 'react';


const ReviewOrderPage = lazy(() => import('./ReviewOrderPage'));
const ReviewOrderActionPage = lazy(()=>import('./ReviewOrderAction'))
const ReviewShipmentPage = lazy(()=>import('./ReviewShipmentPage'))

const ReviewOrderPageConfig = {
  settings: {
    layout: {
      config: {
        navbar: {
          display: false,
        },
        toolbar: {
          display: false,
        },
        footer: {
          display: false,
        },
        leftSidePanel: {
          display: false,
        },
        rightSidePanel: {
          display: false,
        },
      },
    },
  },
  auth: authRoles.onlyGuest,
  routes: [
    {
      path: 'process/:token',
      element: <ReviewOrderPage/>,
    },
    {
      path: 'processShipment/:token',
      element: <ReviewShipmentPage/>,
    },
    {
        path:'process-response/:Id',
        element:<ReviewOrderActionPage/>
    }
  ],
};

export default ReviewOrderPageConfig;
