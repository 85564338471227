import { Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import React, { Fragment, forwardRef, useEffect } from 'react';
import { AuraSimpleTableHead } from 'app/main/apps/common/AuraSimpleTable';
import { cloneDeep } from 'lodash';
import { formatter, formatWeight, getDiscountAmtFromPercentage } from 'app/main/apps/common/AuraFunctions';

// This component is used only for printing will not visible in Ui
const PrintableColumns = forwardRef(({ header, data , showDiscount, fields}, ref) => {
    const HeaderComponent = header?.component;

    const getTableHeader = () => {
        return [
            { name: 'No ' },
            { name: 'Order No ', hide: !fields?.showOrderNo },
            { name: 'Customer Name', hide: !fields?.showCustomerName },
            { name: 'Name' },
            { name: 'Sub Type' },
            { name: 'Reference No', hide: !fields?.showReferenceNo },
            { name: 'Decedent', hide: !fields?.showDecedent },
            { name: 'Order Type', hide: !fields?.showOrderType },
            { name: 'Order Type', hide: !fields?.showCustomInventoryType },
            { name: 'Quantity' },
            { name: 'Weight', hide: !fields?.showWeight},
            { name: 'Price', hide: !fields?.showPrice},
            { name: 'Total Weight', hide: !fields?.showTotalWeight},
            { name: 'Discount', hide: !showDiscount },
            { name: 'Amount', hide: !fields?.showAmount},
            { name: 'Box No', hide: !fields?.showBoxNo},
        ];
    }

    const headerData = {
        ...cloneDeep(header.data),
        showPrintAction: false,
        showDownloadAction: false,
    }

    function isShippingCost(orderDetail) {
        return orderDetail?.name === "Shipping Cost" && orderDetail?.order_id === null;
    }

    return (
        <div ref={ref} className='print-style'>
            <HeaderComponent {...headerData} />
            <div className="table-responsive w-full">
                <Table className="simple w-full min-w-full">
                    <AuraSimpleTableHead
                        headerList={getTableHeader()}
                        classes={{ cnCell: 'font-semibold text-sm' }}
                    />
                    <TableBody sx={{padding: '0px !important'}}>
                        {data?.map((orderDetail, index) => {
                            return (
                                <Fragment key={index}>
                                    <TableRow>
                                        <TableCell className='p-0 max-w-50 md:max-w-150' scope="row">
                                            <Typography className="text-sm">{index + 1}</Typography>
                                        </TableCell>
                                        {fields?.showOrderNo &&
                                            <TableCell className='p-0 max-w-80 md:max-w-150'>
                                                <Typography className="text-sm">{orderDetail.order_no}</Typography>
                                            </TableCell>
                                        }
                                        {fields?.showCustomerName &&
                                            <TableCell className='p-0 max-w-80 md:max-w-150'>
                                                <Typography className="text-sm">{orderDetail.customer_name}</Typography>
                                            </TableCell>
                                        }
                                        <TableCell className='p-0 max-w-130 md:max-w-250' scope="row">
                                            <Typography className="text-sm">{orderDetail.name}</Typography>
                                        </TableCell>
                                        <TableCell className='p-0 max-w-70 md:max-w-150' scope="row">
                                            <Typography className="text-sm">{orderDetail.subType || orderDetail.sub_type}</Typography>
                                        </TableCell>
                                        {fields?.showReferenceNo &&
                                            <TableCell className='p-0 max-w-70 md:max-w-150' scope="row">
                                                <Typography className="text-sm">{orderDetail.customer_provider_number || orderDetail.reference_number}</Typography>
                                            </TableCell>
                                        }
                                        {fields?.showDecedent &&
                                            <TableCell className='p-0 max-w-70 md:max-w-150' scope="row">
                                                <Typography className="text-sm">{orderDetail.decedent || orderDetail.decedents}</Typography>
                                            </TableCell>
                                        }
                                        {fields?.showOrderType && (
                                            <TableCell className='p-0 max-w-40 md:max-w-100' scope="row">
                                                <Typography className="">{orderDetail.order_type}</Typography>
                                            </TableCell>
                                        )}
                                        {fields?.showCustomInventoryType && (
                                            <TableCell className='p-0 max-w-40 md:max-w-100' scope="row">
                                                <Typography className="">{orderDetail.customer_order_type}</Typography>
                                            </TableCell>
                                        )}
                                        <TableCell className='p-0 max-w-50 md:max-w-100' scope="row">
                                            <Typography className="text-sm">{orderDetail.quantity}</Typography>
                                        </TableCell>
                                        {fields?.showWeight &&
                                            <TableCell className='p-0 max-w-60 md:max-w-100' scope="row">
                                                <Typography className="text-sm">{formatWeight(orderDetail.weight)}</Typography>
                                            </TableCell>
                                        }
                                        {fields?.showPrice &&
                                            <TableCell className='p-0 max-w-80 md:max-w-120' scope="row">
                                                {!isShippingCost(orderDetail) &&
                                                    <Typography className="text-sm">{formatter.format(orderDetail.unit_price)}</Typography>
                                                }
                                            </TableCell>
                                        }
                                        {fields?.showTotalWeight &&
                                            <TableCell className='p-0 max-w-80 md:max-w-110' scope="row">
                                                <Typography className="text-sm">{formatWeight(orderDetail.quantity * orderDetail.weight)}</Typography>
                                            </TableCell>
                                        }
                                        {showDiscount === true &&
                                            <TableCell className='p-0 max-w-80 md:max-w-110' scope="row">
                                                {!isShippingCost(orderDetail) && <Typography className="text-sm">
                                                    {formatter.format(getDiscountAmtFromPercentage(orderDetail?.discount, orderDetail?.unit_price, orderDetail?.quantity))}
                                                </Typography>}
                                            </TableCell>
                                        }
                                        {fields?.showAmount &&
                                            <TableCell className='p-0 max-w-90 md:max-w-150' scope="row">
                                                <Typography className="text-sm">{formatter.format(orderDetail.amount)}</Typography>
                                            </TableCell>
                                        }
                                        {fields?.showBoxNo &&
                                            <TableCell className='p-0 max-w-50 md:max-w-150' scope="row">
                                                <Typography>{orderDetail.box_number && orderDetail.box_number.replace(/,/g, ', ')}</Typography>
                                            </TableCell>
                                        }
                                    </TableRow>
                                </Fragment>
                            )
                        })}
                    </TableBody>
                </Table>
            </div>
        </div>
    );
});

export default PrintableColumns;