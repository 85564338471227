import {
  Button,
  Collapse,
  Icon,
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  Box,
  Checkbox,
  Divider,
  Radio
} from '@mui/material';
import format from 'date-fns/format';
import _ from '@lodash';
import { calculateItemTotalAmount, calculateMargin, calculateUnitAmount, calculateUnitPrice, checkNullOrUndefined, formatter, isSupplier, preventNegativeValues } from 'app/main/apps/common/AuraFunctions';
import { Fragment, useEffect, useState, memo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { cloneDeep, isEmpty, omit, sumBy } from 'lodash';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import AuraBaseDialog from 'app/main/apps/common/AuraBaseDialog';
import { Loader } from 'app/main/apps/common/ListInfoMessage';
import OrderHeader from 'app/main/apps/common/OrderHeader';
import { AuraSimpleTableHead } from 'app/main/apps/common/AuraSimpleTable';
import { showMessage } from 'app/store/fuse/messageSlice';
import ImageViewerWindow from 'app/main/dashboards/main/dialogs/ImageViewerWindow';
import pdfViewerWindow from 'app/main/dashboards/main/dialogs/pdfViewerWindow';
import { openDialog } from 'app/store/fuse/dialogSlice';
import { chooseSupplierForArtWork, closeChooseSupplierDialog, getQuotedSuppliers, updateQuotedSuppliersList, saveOrder, updateOrder } from '../../store/orderSlice';
import { getOrders } from '../../store/ordersSlice';
import ConfirmAttachments from './ConfirmAttachments';
import { quoteToOrder } from '../../store/rfqsSlice';
import { chooseSupplierSchema } from './OrderBase';


function ChooseSupplier({ isDashboard, loadOrders }) {
  const dispatch = useDispatch();
  const suppliersList = useSelector((state) => state.eCommerceApp.order?.quotedSuppliersList?.orders);
  const parentId = useSelector((state) => state.eCommerceApp?.order?.quotedSuppliersList?.parent_order_details)
  const [selected, setSelected] = useState('');
  const [accordionIndex, setAccordionIndex] = useState(null);
  const [loading, setLoading] = useState(false);
  const [next, setNext] = useState(true)
  const [selectedIndex, setSelectIndex] = useState()
  const [approve, setApprove] = useState(false)
  const [isCreateOrder, setIsCreateOrder] = useState(null)
  const [action, setAction] = useState({ approve: false, revision: false, reject: false })
  const [weightIndex, setWeightIndex] = useState({})
  const [open, setOpen] = useState({});
  const [selectedImage, setSelectedImage] = useState({})
  const [checked, setChecked] = useState(false)
  const expandSelectedSupplier = useRef(true)
  const [selectedSupplierId, setSelectedSupplierId] = useState(null);
  const { props, param, completeOrderData, name, status, stage, addOnParam } = useSelector(
    ({ eCommerceApp }) => eCommerceApp?.order?.chooseSupplierDialog
  );

  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      tariff: 0,
      shipping: 0,
      note: '',
      order_details: []
    },
    resolver: yupResolver(chooseSupplierSchema),
  });

  const {
    control,
    setValue,
    watch,
    reset,
    formState: { errors, isValid, touchedFields },
    getValues,
    setError,
    handleSubmit: formSubmit,
  } = methods;
  const tariff = watch('tariff');
  const shipping = watch('shipping');
  const orderDetailsForm = watch('order_details')

  const highlightedInputStyle = {
    "& .MuiInput-underline:before": {
      borderBottomColor: "#4f46e5", // Default bottom border color
      borderBottomWidth: '2px'
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#4f46e5", // Active (focused) bottom border color
    },
    "& .MuiFormLabel-root": {
      color: "#4f46e5", // Label color
    },
  }

  const highlightedBoxInputStyle = {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#4f46e5", // Default border color
        borderWidth: '2px'
      },
      "&:hover fieldset": {
        borderColor: "#4f46e5", // Hover border color
      },
      "&.Mui-focused fieldset": {
        borderColor: "#4f46e5", // Focused border color
      },
    },
    "& .MuiInputLabel-root": {
      color: "#4f46e5", // Default label color
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: "#4f46e5", // Label color when focused
    },
  }

  useEffect(() => {
    if(props?.open && param) {
      const orderDetail = suppliersList?.[selectedIndex]?.order_details?.map((od) =>
        ({ vas: od.vas, weight: od.weight, margin: od.product.margin, discount: od.discount, unit_price: od.unit_price, product:{"unit_price": od.product.unit_price}})
      )
      setValue('order_details', orderDetail, {shouldValidate: true});
      if(suppliersList && suppliersList.length > 0 && expandSelectedSupplier.current === true) {
        // Expand the selected supplier row for the first time when user open the dialog
        suppliersList?.forEach((supplierData, index) => {
          if(supplierData.is_selected) {
            setOpen(prev => ({ ...prev, [index]: true }))
            expandSelectedSupplier.current = false
          }
        })
      }
    }
  }, [suppliersList, selectedIndex])

  useEffect(() => {
    if(props?.open && param && !checkNullOrUndefined(selectedIndex) && suppliersList) {
      // Update Unit Price on tariff, shipping value change
      suppliersList?.[selectedIndex]?.order_details?.forEach((od, odIndex) => {
        if(od?.product?.unit_price && Number(od?.product?.unit_price) === 0) return
        const priceVal = calculateUnitPrice(od.weight, od.unit_price, tariff, shipping, od?.product?.margin);
        dispatch(updateQuotedSuppliersList({ field: "unit_price", value: priceVal, selectedIndex, selectedOrder: odIndex }))
      })
    }
  }, [tariff, shipping, selectedIndex])

  const hideForStatus = () => {
    if (status === 'Artwork Inprogress') return true
    return false
  }

  const hideForEdit = () => {
    if (status === 'Artwork Inprogress' || (isCreateOrder && !next) || !isCreateOrder) return true
    return false
  }

  const updateSupplierData = (e, field, selectedOrder, updateVas) => {
    const { value } = e.target
    dispatch(updateQuotedSuppliersList({ field, value, selectedIndex, selectedOrder, updateVas }))
  }

  const supplierHeaders = [
    { name: 'Select',  hide: !isCreateOrder},
    { name: 'Name' },
    { name: 'Email' },
    { name: 'Weight', hide: hideForStatus() },
    { name: 'Qty', hide: hideForStatus() },
    { name: 'Total FOB', hide: hideForStatus() },
    { name: 'Status', hide: isCreateOrder},
    { name: 'Actions' },
  ];

  const supplierProductsHeaders = [
    { name: 'No' },
    { name: 'Name' },
    { name: 'Sub Type' },
    { name: 'Weight', hide: hideForStatus() },
    { name: 'Price', hide: hideForEdit() },
    { name: 'Quantity', hide: hideForStatus() },
    { name: 'FOB', hide: hideForStatus() },
    { name: 'Total FOB', hide: hideForStatus() }
  ]

  const supplierProductsHeadersActions = [
    { name: 'No' },
    { name: 'Name' },
    { name: 'Sub Type' },
    { name: 'Weight (lbs)', hide: hideForStatus() },
    { name: 'Quantity', hide: hideForStatus() },
    { name: 'FOB', hide: hideForStatus() },
    { name: 'Margin (%)', hide: hideForStatus() },
    { name: 'Price ($)', hide: hideForStatus() },
    { name: 'Discount (%)', hide: hideForStatus() },
    { name: 'Amount', hide: hideForStatus() },
  ]

  useEffect(() => {
    if (props?.open && param) {
      setLoading(true);
      dispatch(
        getQuotedSuppliers({
          id: param,
          params: {
            stage: name === "Choose Supplier for Proof" ? "rfd" : "quote",
            action: "rfq_to_quote",
          },
        })
      ).then(({payload}) => {
        if(addOnParam && addOnParam?.isCreateSupplierOrder) {
          payload?.orders?.forEach((data) => {
            if(data?.is_selected === true) {
              setSelectedSupplierId(data?.supplier?.id)
            }
          })
        }
      }).finally(() => {
        setLoading(false)
      });
    }
  }, [props, param]);

  useEffect(() => {
    if (props?.open) {
      reset();
      setSelected('');
      setAccordionIndex(null);
      setNext(true);
      setApprove(false)
      setSelectedImage({})
      setSelectedSupplierId(null)
      if(addOnParam && addOnParam?.isCreateSupplierOrder) {
        setIsCreateOrder(true)
      } else {
        setIsCreateOrder(false)
      }
    }
  }, [reset, props?.open]);

  useEffect(() => {
    suppliersList?.[selectedIndex]?.order_details?.forEach((data, ind) => {
      setWeightIndex(prev => ({ ...prev, [ind]: data.weight }))
    })
  }, [suppliersList, selectedIndex, props.open])

  useEffect(() => {
    const handleWheel = (e) => {
      if (e.target.type === "number") {
        e.target.blur();
      }
    };

    document.addEventListener("wheel", handleWheel, { passive: true });

    return () => {
      document.removeEventListener("wheel", handleWheel);
    };
  }, []);

  const onClose = () => dispatch(closeChooseSupplierDialog());

  // fob - unit_price, wgt - wight, mrg -margin,
  // isPrice - true for custom() - unit_price and Price Column in table
  // isPrice - false Amount Column for calcumating TOTAL amount
  // discount - null -  custom() - unit_price and Price Column in table
  // discount -  Amount Column for calcumating TOTAL amount
  const calculatePrice = (unitPrice, fob, wgt, qty, mrg, isPrice, discount, textPrice = 0, imagePrice = 0) => {
    const calculate = unitPrice && Number(unitPrice) === 0
    const price = !calculate ? calculateUnitPrice(wgt, fob, tariff, shipping, mrg) : 0
    if (isPrice)
      return price;
    const unitPriceVal = unitPrice && (Number(unitPrice) === 0 || unitPrice > 0) ? Number(unitPrice) : price
    return calculateItemTotalAmount(unitPriceVal, qty, discount, textPrice, imagePrice);
  }

  const updateUnitPriceAndList = (e, field, fieldName, selectedOrder, orderDetailObj) => {
    if(fieldName === "margin") {
      let { value } = e.target;
      value = value < 0 ? value = '' : value
      // Allow empty values, and ensure the value is a valid number or decimal between 0 and 99.99
      if (value === '' || (/^(\d{1,2}(\.\d{0,2})?)$/.test(value) && Number(value) <= 99.99)) {
        field.onChange(value);
        dispatch(updateQuotedSuppliersList({ field: fieldName, value, selectedIndex, selectedOrder }))
        const price = calculateUnitPrice(orderDetailObj.weight, orderDetailObj.unit_price, tariff, shipping, value)    
        dispatch(updateQuotedSuppliersList({ field: "unit_price", value: price, selectedIndex, selectedOrder }))
      }
    }
    const { value } = e.target
    if(fieldName === "weight") {
      dispatch(updateQuotedSuppliersList({ field: fieldName, value, selectedIndex, selectedOrder }))
      const price = calculateUnitPrice(value, orderDetailObj.unit_price, tariff, shipping, orderDetailObj.product.margin)    
      dispatch(updateQuotedSuppliersList({ field: "unit_price", value: price, selectedIndex, selectedOrder }))
    }
    if(fieldName === "unit_price") {
      // Update Supplier list and update margin
      dispatch(updateQuotedSuppliersList({ field: fieldName, value, selectedIndex, selectedOrder }))
      let marginValue = 0;
      if(value && value > 0) {
        marginValue = calculateMargin(orderDetailObj.weight, orderDetailObj.unit_price, tariff, shipping, value)
      }
      dispatch(updateQuotedSuppliersList({ field: "margin", value: marginValue, selectedIndex, selectedOrder }))
    }
  }

  const handleSubmit = (values) => {
    if (next) setNext(false)
    else {
      reset();
      setNext(true)
      if(isCreateOrder) {
        setSelected('');
      }
    }
    setApprove(false)
  };

  const custom = () => {
    return parentId?.map((parent, index) => {
      const child = suppliersList[selectedIndex].order_details[index]
      return {
        ...omit(getValues(), ['note']),
        id: parent.product_id,
        margin: child.product?.margin,
        discount: child.discount,
        unit_price: child.product?.unit_price
      }
    })
  }

  const validateApproval = (orderDetails) => {
    let isValidApproval = true
    const formValues = getValues()
    if (!formValues?.tariff && formValues?.tariff !== 0) {
      setError(`tariff`, { message: 'Required' })
      isValidApproval = false
    }
    if (!formValues?.shipping && formValues?.shipping !== 0) {
      setError(`shipping`, { message: 'Required' })
      isValidApproval = false
    }
    orderDetails?.forEach((item, index) => {
      if (!item?.product?.margin && item?.product?.margin !== 0) {
        setError(`order_details.${index}.margin`, { message: 'Required' })
        isValidApproval = false
      }
    })
    return isValidApproval
  }

  const approveOrRejectQuote = (isApprove, note, toStatusCode) => {
    if (isApprove)
      setApprove(true)
    if (isApprove && note === '') {
      setError('note', { message: 'You must provide note' })
      return
    }
    dispatch(
      updateOrder({
        id: suppliersList[selectedIndex].id,
        to_statuses_type_id: toStatusCode,
        updateStatus: 'yes',
        note
      })
    ).then((res) => {
      if (!res.error) {
        dispatch(showMessage({ type: 'success', message: 'Success' }));
        if (isDashboard && loadOrders) {
          loadOrders();
        }
        dispatch(getOrders({ params: { stage } }))
      }
    })
    onClose()
  }

  const handleCreateSupplierOrder = (setSupplier, payloadData) => {
    const payload = payloadData || {};
    let selectedSuppliersListId = null;
    payload.is_approval_type = checked;
    payload.action_name = "Create Supplier Order";
    payload.change_supplier = !setSupplier;
    if(setSupplier === true) {
      suppliersList?.forEach((data, index) => {
        const { supplier, order_details: od } = data;
        if(supplier.id === selectedSupplierId) {
          selectedSuppliersListId = data.id;
          payload.supplier = { ...supplier };
          payload.supplier.is_selected = true;
          payload.change_supplier = checkNullOrUndefined(data.is_selected)
        }
      });
    } else {
      payload.custom_product = custom();
      selectedSuppliersListId = suppliersList[selectedIndex].id
    }
    dispatch(
      quoteToOrder({ id: param, payload: { ...payload }, params: { qid: selectedSuppliersListId } })
    )
    .then((res) => {
      setLoading(false);
      if (!res.error) {
        dispatch(showMessage({ message: 'Supplier order created', type: 'success' }));
        if (isDashboard && loadOrders) {
          loadOrders();
        }
        dispatch(getOrders({ params: { stage } }))
      }
    })
    .finally(onClose);
  }

  const SupplierHandler = ({ note }, isApprove, toStatusCode) => {
    if (isApprove)
      approveOrRejectQuote(isApprove, note, toStatusCode)
    else {
      setLoading(true);
      let isValidVas = true
      let payload = {
        supplier: {},
        new_documents: [],
        note
      };

      let supplierIndex
      suppliersList?.forEach((data, index) => {
        const { supplier, order_details: od } = data;
        if(isCreateOrder && next) {
          if(supplier.is_selected) {
            payload.supplier = { ...supplier };
          }
        } else {
          if (selected === supplier.id) {
            payload.supplier = { ...supplier, is_selected: true, };
            payload.order_details = od;
            supplierIndex = index
          }
          if (accordionIndex !== null && accordionIndex === index) {
            payload.new_documents = data.uploadedFile?.map(({ id }) => ({ id }));
          }
        }
        payload = cloneDeep(payload)
      });
      payload.order_details?.forEach((od, sIndex) => {
        if (completeOrderData?.image_customisation === 'vas') {
          const vasPrice = getValues().order_details[sIndex].vas
          console.log('vasPrice', vasPrice)
          if (!vasPrice?.imagePrice && (vasPrice?.images?.[0]?.name || vasPrice?.images?.[0]?.value || vasPrice?.images?.[0]?.url)) {
            isValidVas = false
            setError(`order_details.${sIndex}.vas.imagePrice`, { message: 'Required' })
          }
          if (!vasPrice?.textPrice && (vasPrice?.text?.[0]?.name || vasPrice?.text?.[0]?.value || vasPrice?.text?.[0]?.url)) {
            isValidVas = false
            setError(`order_details.${sIndex}.vas.textPrice`, { message: 'Required' })
          }
        }
      })
      if (!isValidVas) {
        setLoading(false);
        return
      }
      payload.order_details?.forEach((od, sIndex) => {
        const foundProuduct = suppliersList[accordionIndex]?.order_details?.find(data => data.product.name === od.product.name)
        if (foundProuduct)
          payload.order_details[sIndex].new_drawing_documents = foundProuduct.drawing_documents
        else {
          const image = []
          od?.drawing_documents?.forEach((doc, docIndex) => {
            if (selectedImage?.[supplierIndex]?.[sIndex]?.[docIndex]) {
              image.push(od.drawing_documents[docIndex])
              od.new_drawing_documents = image
            }
          })
          od?.proof_documents?.forEach((doc, docIndex) => {
            if (selectedImage?.[supplierIndex]?.[sIndex]?.[docIndex]) {
              image.push(od.proof_documents[docIndex])
              od.new_proof_documents = image
            }
          })
        }
        delete od.drawing_documents;
        delete od.proof_documents;
        const parentOrder = parentId?.find((item) => item.id === od.parent_order_detail_id)
        od.vas = getValues().order_details[sIndex].vas ? JSON.stringify({ ...parentOrder.vas, ...getValues().order_details[sIndex].vas }) : null
        od.weight = weightIndex[sIndex]
      })

      if (status !== 'Artwork Inprogress') {
        if (validateApproval(payload.order_details)) {
          if(isCreateOrder) {
            handleCreateSupplierOrder(false, payload)
          } else {
            dispatch(saveOrder({ ...payload, isQuote: true, id: param, custom_product: custom() }))
            .then((res) => {
              setLoading(false);
              if (!res.error) {
                dispatch(showMessage({ type: 'success', message: 'Success' }));
                if (isDashboard && loadOrders) {
                  loadOrders();
                }
                dispatch(getOrders({ params: { stage } }))
              }
            })
            .finally(onClose);
          }
        } else {
          setLoading(false);
        }
      }
      else {
        const { new_documents: newDocumnets, note: Note, order_details: orderDetails } = payload
        orderDetails?.forEach((od, sIndex) => {
          const image = []
          od?.proof_documents?.forEach((doc, docIndex) => {
            if (selectedImage?.[supplierIndex]?.[sIndex]?.[docIndex]) {
              image.push(od.proof_documents[docIndex])
              od.new_proof_documents = image
            }
          })
          delete od.proof_documents
          delete od.vas
        })
        const artWorkPayload = {
          new_documents: newDocumnets,
          note: Note,
          order_details: orderDetails,
          id: param
        }
        dispatch(chooseSupplierForArtWork(artWorkPayload)).then((res) => {
          setLoading(false);
          if (!res.error) {
            dispatch(showMessage({ type: 'success', message: 'Success' }));
            if (isDashboard && loadOrders) {
              loadOrders();
            }
            dispatch(getOrders({ params: { stage } }))
          }
        }).finally(onClose)
      }

    }
  }

  function validateDrawingDocuments(supplier, orderDetails, index) {
    const validationErrors = [];
    const arrayIndex = [];
    orderDetails?.forEach((od, sIndex) => {
      const foundProuduct = supplier?.order_details?.find((data) => data.product.name === od.product.name);
      if (foundProuduct)
        orderDetails[sIndex].new_drawing_documents =foundProuduct.drawing_documents;
      else {
        const selectedDrawingDocuments = [];
        const selectedProofDocuments = [];
        od?.drawing_documents?.forEach((doc, docIndex) => {
          if (selectedImage?.[index]?.[sIndex]?.[docIndex]) {
            selectedDrawingDocuments.push(od.drawing_documents[docIndex]);
          }
        });
        od?.proof_documents?.forEach((doc, docIndex) => {
          if (selectedImage?.[index]?.[sIndex]?.[docIndex]) {
            selectedProofDocuments.push(od.proof_documents[docIndex]);
          }
        });
        let drawingDocumentsSelected = true;
        if (od?.drawing_documents && od?.drawing_documents.length > 0 && selectedDrawingDocuments?.length === 0 ) {
          validationErrors.push({row_index: sIndex});
          arrayIndex.push(sIndex + 1);
          drawingDocumentsSelected = false;
        }
        if (drawingDocumentsSelected === true && od?.proof_documents && od?.proof_documents.length > 0 && selectedProofDocuments?.length === 0) {
          validationErrors.push({row_index: sIndex});
          arrayIndex.push(sIndex + 1);
        }
      }
    });
    if (validationErrors && validationErrors.length > 0) {
      return { validationErrors, errorRows: arrayIndex };
    }
    return {};
  }

  const showDrawingConfirmation = (arrayIndex, supplier, od, index) => {
    dispatch(
      openDialog({
        children: (
          <>
            <ConfirmAttachments title="Drawing/Proof Confirmation" message={<div id="alert-dialog-description">
                No drawing/proof selected for order item {_.join(arrayIndex, ", ")}.
                Do you want to proceed?
              </div>}
              onProceed={() => {onSupplierSelect(supplier, od, index)}} />
          </>
        ),
      })
    );
  };

  const handleSupplierSelect = (supplier, od, index, isValidate) => {
    if(isValidate === true) {
      const validationResponse = validateDrawingDocuments(supplier, od, index);
      if(validationResponse && validationResponse.errorRows && validationResponse.errorRows.length > 0) {
        showDrawingConfirmation(validationResponse.errorRows, supplier, od, index);
      } else {
        onSupplierSelect(supplier, od, index,);
      }
    } else {
      onSupplierSelect(supplier, od, index);
    }
  };

  const onSupplierSelect = (supplier, od, index) => {
    setSelected(supplier.id);
    setSelectIndex(index)
    if (supplier.profile) {
      setValue('shipping', Number(supplier.profile.shipping) || 0);
      setValue('tariff', Number(supplier.profile.tariff) || 0);
    } else {
      setValue('shipping', 0);
      setValue('tariff', 0);
    }
    handleSubmit();
  }

  const getOrderLabel = () => {
    switch (status) {
      case 'Received Supplier Quote':
        return 'RFQ'
      case 'Artwork Inprogress':
        return 'Order'
      case 'Design Inprogress':
        return 'Order'
      default:
        return 'Order';
    }
  }

  const headerData = {
    enable: true,
    component: OrderHeader,
    data: {
      order: completeOrderData,
      hideAmount: true,
      hideQuantity: status === 'Artwork Inprogress',
      stage: getOrderLabel()
    },
  };

  const renderImages = (images, index, sInd, showImageSelection = true) => {
    return <>
      <Typography
        variant="h6"
        gutterBottom
        component="div"
        className="mt-6"
      >
        {images ? "Documents" : ""}
      </Typography>
      <div className='flex'>
        {images?.map((media, selectedImageIndex) => {
          console.log('media', media)
          return (
            <div>
              <div className='flex'>
                {showImageSelection === true && <div>
                  <Checkbox
                    className='flex-start'
                    checked={selectedImage?.[sInd]?.[index]?.[selectedImageIndex] || false}
                    onChange={(event) => {
                      const newSelectedImages = selectedImage?.[sInd] || [];
                      newSelectedImages[index] = {
                        ...(newSelectedImages[index] || {}),
                        [selectedImageIndex]: event.target.checked
                      };
                      setSelectedImage({ [sInd]: newSelectedImages });
                    }}
                  />
                </div>}
                <div
                  className="justify-center w-128 h-128 rounded-16 mr-12 overflow-hidden outline-none shadow hover:shadow-lg relative cursor-pointer"
                  key={media.id}
                  onClick={() => {
                    if (!_.startsWith(media['Content-Type'], 'image/')) {
                      pdfViewerWindow(media.data)
                    } else {
                      ImageViewerWindow([media])
                    }
                  }}
                  onKeyDown={() => { }}
                  role="button"
                  tabIndex={0}
                >
                  <img
                    src={
                      _.startsWith(media['Content-Type'], 'image/')
                        ? `data:${media['Content-Type']};base64,${media.data}`
                        : 'assets/icons/pdf.png'
                    }
                    className="max-w-none w-auto h-full"
                    alt="product"
                    aria-hidden="true"
                  />
                </div>
              </div>
              <Tooltip title={media?.filename || media?.name}>
                <div
                  className='mb-16 mt-10 w-128 text-sm text-center m-auto'
                  style={{
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden'
                  }}>
                    {media?.filename || media?.name}
                </div>
              </Tooltip>
            </div>
          );
        })}
      </div>
    </>
  }

  const getTitle = () => {
    let title = 'Approve'
    if (action.revision) title = 'Request Revision'
    return title
  }

  const getVasPrice = (type, index, propertyKey, row) => {
    return (
      <tr>
        <td className="w-800 px-8 py-4 mr-16" style={{ paddingRight: '3rem', textAlign: 'right' }} colSpan={7}>
          <div className="flex w-full">
            <div className="ml-auto">{type}</div>
            <div color='warning' className="px-4 text-red">*</div></div>
        </td>
        <td colSpan={5} className="px-8 py-4" style={{width:"100px"}}>
          <Controller
            control={control}
            name={`order_details.${index}.vas.${propertyKey}`}
            render={({ field }) => (
              <>
                <TextField
                  {...field}
                  value={field.value}
                  type="number"
                  variant="standard"
                  className="p-0 w-80"
                  size="small"
                  fullWidth
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                  onKeyDown={preventNegativeValues}
                  onChange={(e) => {
                    field.onChange(e.target.value)
                    updateSupplierData(e, propertyKey, index, true)
                  }}
                  error={!!errors.order_details?.[index]?.vas?.[propertyKey] && touchedFields?.order_details?.[index]?.vas?.[propertyKey]}
                  helperText={touchedFields?.order_details?.[index]?.vas?.[propertyKey] && errors.order_details?.[index]?.vas?.[propertyKey]?.message}
                  disabled={action?.reject || action?.revision}
                  sx={{
                    "& .MuiInput-underline:before": {
                      borderBottomColor: "#4f46e5", // Default bottom border color
                      borderBottomWidth: '2px'
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "#4f46e5", // Active (focused) bottom border color
                    },
                    "& .MuiFormLabel-root": {
                      color: "#4f46e5", // Label color
                    },
                  }}
                  required
                /></>
            )}
          />
        </td>
      </tr>
    );
  };

  const getWidth = () => {
    if (status === 'Artwork Inprogress') return '850px'
    if (completeOrderData?.image_customisation === 'vas') return '550px'
    return 'auto'
  }

  const onChange = (rowIndex, value) => {
    setWeightIndex(prev => ({ ...prev, [rowIndex]: value }))
  }

  const getProductDetail = (products, index, isWeightEditable, allFiles, sInd, isSelectedSupplier = false) => {
    // suppliersList -> sList?.order_details?.map((pdetail, ind) => {
    // products - pdetail(order_detail)
    const vas = orderDetailsForm?.[index]?.vas || {}
    const textPrice = Number(vas?.textPrice || 0)
    const imagePrice = Number(vas?.imagePrice || 0)
    const showImageSelect = !isCreateOrder || (isCreateOrder && !isSelectedSupplier)
    return (
      <>
        <TableRow>
          <TableCell className="px-8 py-4" style={{width:"50px"}}>
            {index+1}
          </TableCell>
          <TableCell className="px-8 py-4" style={{minWidth:"400px", maxWidth:"400px"}}>
            {products.name}
          </TableCell>
          <TableCell className="px-8 py-4" style={{width:"120px"}}>
            {products.sub_type}
          </TableCell>
          {status !== 'Artwork Inprogress' && <>
            <TableCell className="px-8 py-4">
              {
                isWeightEditable ? (
                  <Controller
                    control={control}
                    name={`order_details.${index}.weight`}
                    rules={{
                      required: {
                        value: true,
                        message: 'Required'
                      }
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        className="number-no-scroll w-60"
                        type="number"
                        onKeyDown={preventNegativeValues}
                        onChange={(e) => {
                          field.onChange(e.target.value)
                          updateUnitPriceAndList(e, field, 'weight', index, products)
                        }}
                        variant="standard"
                        size="small"
                        label=''
                        required
                        error={!!errors?.order_details?.[index]?.weight}
                        helperText={errors?.order_details?.[index]?.weight?.message}
                        sx={highlightedInputStyle}
                        disabled={action?.reject || action?.revision}
                      />
                    )}
                  />
                ) : <>{products.weight} lbs</>
              }
            </TableCell>
            {/* Price */}
            {isCreateOrder && next && <TableCell className="px-8 py-4">
              {isSelectedSupplier === true ? formatter.format(products.product.unit_price) : "-"}
            </TableCell>}
            <TableCell className="px-8 py-4">
              {products.quantity}
            </TableCell>
            {/* FOB */}
            <TableCell className="px-8 py-4">
              {formatter.format(products.unit_price)}
            </TableCell>
            <>
              {
                isWeightEditable && (
                  <TableCell className="px-8 py-4">
                    <Controller
                      control={control}
                      name={`order_details.${index}.margin`}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          className="number-no-scroll w-60"
                          type="number"
                          onKeyDown={preventNegativeValues}
                          onChange={(e) => {
                            updateUnitPriceAndList(e, field, 'margin', index, products)
                          }}
                          InputLabelProps={{ shrink: true }}
                          error={!!errors?.order_details?.[index]?.margin}
                          helperText={errors?.order_details?.[index]?.margin?.message}
                          variant="standard"
                          size="small"
                          label=''
                          sx={highlightedInputStyle}
                          disabled={action?.reject || action?.revision}
                        />
                      )}
                    />
                  </TableCell>
                )}
                {/* Price column */}
                {isWeightEditable && (
                  <TableCell className="px-8 py-4" style={{width:"100px"}}>
                    <Controller
                      control={control}
                      name={`order_details.${index}.product.unit_price`}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          className="number-no-scroll w-100"
                          type="number"
                          onKeyDown={preventNegativeValues}
                          onChange={(e) => {
                            field.onChange(e.target.value)
                            updateUnitPriceAndList(e, field, 'unit_price', index, products)
                          }}
                          InputLabelProps={{ shrink: true }}
                          error={!!errors?.order_details?.[index]?.product?.unit_price}
                          helperText={errors?.order_details?.[index]?.product?.unit_price?.message}
                          variant="standard"
                          size="small"
                          label=''
                          sx={highlightedInputStyle}
                          disabled={action?.reject || action?.revision}
                        />
                      )}
                    />
                  </TableCell>
                )}
              {/* Discount column */}
              {isWeightEditable && <TableCell className="px-8 py-4">
                <Controller
                      control={control}
                      name={`order_details.${index}.discount`}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          className="number-no-scroll w-60"
                          type="number"
                          onKeyDown={preventNegativeValues}
                          onChange={(e) => {
                            const { value } = e.target;
                            if (value === '' ||  (Number(value) >= 0 && Number(value) <= 99.99)) {
                              field.onChange(e.target.value)
                              updateSupplierData(e, 'discount', index)
                            }
                          }}
                          InputLabelProps={{ shrink: true }}
                          error={!!errors?.order_details?.[index]?.discount}
                          helperText={errors?.order_details?.[index]?.discount?.message}
                          variant="standard"
                          size="small"
                          label=''
                          sx={highlightedInputStyle}
                          disabled={action?.reject || action?.revision}
                        />
                      )}
                    />
              </TableCell>}
              {/* Amount column or Total FOB */}
              {isWeightEditable ?
              <TableCell className="px-8 py-4">
                {formatter.format(calculatePrice(products.product?.unit_price, products.unit_price, products.weight, products.quantity, products.product?.margin, false, products.discount, textPrice, imagePrice))}
              </TableCell> :
              <TableCell className="px-8 py-4">
              {formatter.format(products.unit_price * products.quantity)}
            </TableCell>}
            </>
          </>}
        </TableRow>
        {!isWeightEditable && (products.drawing_documents || products.proof_documents) && (
          <TableRow>
            <TableCell colspan="100%" >
            <Box sx={{ margin: 2 }}>
              <Collapse in timeout="auto" unmountOnExit>
                {renderImages(products.drawing_documents || products.proof_documents, index, sInd, showImageSelect)}
              </Collapse>
            </Box></TableCell>
          </TableRow>
        )}
      </>
    )
  }

  useEffect(() => {
    // Reset all expanded rows
    setOpen({})
    if(!props?.open) {
      expandSelectedSupplier.current = true
      setSelectIndex(null);
      setChecked(false);
    }
    suppliersList?.forEach((data) => {
      data?.order_details?.forEach((od, key) => {
        setOpen(prev => ({ ...prev, [key]: false }))
      })
    })
  }, [props?.open])

  const additionalBtns = () => {
    if(isCreateOrder === true && next) {
      return <>
        <Button
            variant="contained"
            color="secondary"
            type="submit"
            onClick={formSubmit((data) => {setLoading(true); handleCreateSupplierOrder(true)})}
            disabled={loading || !isEmpty(errors)}
          >
            Create Supplier Order
        </Button>
      </>
    }
    if(!next) {
      return <>
        <Button
          variant="contained"
          color="secondary"
          type="submit"
          onClick={handleSubmit}
          disabled={loading || !selected}
        >
          Previous
        </Button>
        {!isCreateOrder && action.approve && (
          <Button
            variant="contained"
            color="success"
            type="submit"
            onClick={formSubmit((data) => SupplierHandler(data, false))}
            disabled={loading || !isEmpty(errors) || (!_.isEmpty(touchedFields) && !isValid)}
          >
            Submit For Approval
          </Button>
        )}
        {!isCreateOrder && action.revision && (
          <Button
            variant="contained"
            color="warning"
            type="submit"
            onClick={formSubmit((data) => SupplierHandler(data, true, 'QUOTE_REVISIONS_REQUESTED'))}
          >
            Request Revision
          </Button>
        )}
        {isCreateOrder && (
          <Button
              variant="contained"
              color="secondary"
              type="submit"
              onClick={formSubmit((data) => SupplierHandler(data, false))}
              disabled={loading || !isEmpty(errors)}
            >
              Create Supplier Order
          </Button>
        )}
      </>
    }
    return <></>
  }

  const getTableHeaderColor = (supplier, index) => {
    if(isCreateOrder && supplier.is_selected) {
      return "#99ffb4"
    }
    if(isCreateOrder && !supplier.is_selected) {
      return open[index] ? "#0eabff80" : "white"
    }
    return  open[index] ? "#0eabff80" : "white"
  }

  const onChoosenSuplierChange = (value) => {
    setSelectedSupplierId(value);
  };

  return (
    <FormProvider {...methods}>
      <AuraBaseDialog
        dialogProps={{ open: props?.open }}
        closeDialog={onClose} 
        disableCloseIcon={loading }  
        dialogParam={{
          maxWidth: 'xl',
          title: next ? name : getTitle(),
          hideDialogActions: true,
        }}
        disableSubmitButton={() => false}
        header={headerData}
        additionalButton={additionalBtns()}
      >
        {loading ? (
          <Loader />
        ) : (
          <>
            {next && <div className="table-responsive w-full">
              {isCreateOrder && (
                  <div className='mx-10'>
                    <Checkbox
                      checked={checked}
                      className='px-8 py-8'
                      onChange={() => { setChecked(!checked) }}
                    />
                    Send for Approval
                  </div>
                )}
              <Table className="simple w-full min-w-full">
                <AuraSimpleTableHead
                  headerList={supplierHeaders}
                  classes={{ cnCell: 'font-semibold px-8 py-8' }}
                />
                <TableBody>
                  {suppliersList?.map((data, index) => {
                    const { supplier = {}, order_details: od = [], uploadedFile } = data;
                    const hasDrawingDocuments = od?.some(d => d.drawing_documents || d.proof_documents);
                    const allFiles = []
                    if (uploadedFile?.length)
                      allFiles.push(...uploadedFile)
                    od?.forEach(l => {
                      if (l.drawing_documents)
                        allFiles.push(...l.drawing_documents)
                      if (l.proof_documents)
                        allFiles.push(...l.proof_documents)
                    })

                    return (
                      <Fragment key={index}>
                        <TableRow style={{ backgroundColor:  getTableHeaderColor(supplier, index), borderColor: isCreateOrder && selectedSupplierId === supplier?.id ? "#99FFB4" : "" }} className={`${isCreateOrder && selectedSupplierId === supplier?.id && "border-2"}`} >
                          {isCreateOrder &&
                            <TableCell scope="row" style={{width: "50px"}}>
                              <Radio
                                checked={selectedSupplierId === supplier?.id}
                                onChange={() => onChoosenSuplierChange(supplier?.id)}
                                value={index}
                                name="radio-buttons"
                                inputProps={{ 'aria-label': {index} }}
                                color="success"
                              />
                            </TableCell>
                          }
                          <TableCell scope="row">
                            <div className='flex flex-row'>
                              {allFiles?.length > 0 &&
                              <Tooltip title="Attachment included">
                                  <FuseSvgIcon color="success"
                                  size={20}
                                    className="text-20 mr-10"
                                  >
                                    material-outline:file_present
                                  </FuseSvgIcon>
                                </Tooltip>
                              }
                            <Typography className={allFiles?.length > 0 && 'font-semibold'}>{supplier.name}</Typography>
                            </div>
                          </TableCell>
                          <TableCell scope="row">
                            <Typography>{supplier.email}</Typography>
                          </TableCell>
                          {status !== 'Artwork Inprogress' && <>
                            <TableCell scope="row">
                              <Typography>{data.weight} lbs</Typography>
                            </TableCell>
                            <TableCell scope="row">
                              <Typography>{data.quantity}</Typography>
                            </TableCell>
                            <TableCell scope="row">
                              <Typography>{formatter.format(data.amount)}</Typography>
                            </TableCell>
                          </>}
                          {/* {suppliersList?.length > 1 &&
                            <TableCell scope="row">
                              {allFiles.length ? (
                                <Switch
                                  size="small"
                                  checked={index === accordionIndex}
                                  onClick={(e) => setAccordionIndex(e.target.checked ? index : null)}
                                />
                              ) : null}
                            </TableCell>
                          } */}
                          {!isCreateOrder && <TableCell scope="row">
                            <Typography>{data.status?.name}</Typography>
                          </TableCell>
                          }
                          <TableCell scope="row">
                            {["Requested", "Submitted"].includes(data.status?.name) &&
                              <div className='flex'>
                                {
                                  !isCreateOrder && name !== 'Revisions To Supplier' && (
                                    <Tooltip title='Approve'>
                                      <FuseSvgIcon onClick={() => {
                                        setAction({ approve: true, revision: false, reject: false })
                                        handleSupplierSelect(supplier, od, index, true)
                                      }} className="text-48 cursor-pointer" size={24} color="action">material-outline:done</FuseSvgIcon>
                                    </Tooltip>
                                  )
                                }
                                {!isCreateOrder && <Tooltip title='Request Revision'>
                                  <FuseSvgIcon onClick={() => {
                                    setAction({ approve: false, revision: true, reject: false })
                                    handleSupplierSelect(supplier, od, index, true)
                                  }} className="text-48 cursor-pointer">material-outline:restart_alt</FuseSvgIcon>
                                </Tooltip> }
                                <IconButton
                                  aria-label="expand row"
                                  size="small"
                                  onClick={() => setOpen((prev) => ({ ...prev, [index]: !prev[index] }))}
                                  title="Show Product Detail"
                                >
                                  <Icon>
                                    {open[index] ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
                                  </Icon>
                                </IconButton>
                              </div>}
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
                            {
                              open[index] && (
                                <Table>
                                  <AuraSimpleTableHead
                                    headerList={supplierProductsHeaders}
                                    classes={{ cnCell: 'font-semibold px-8 py-8' }}
                                  />
                                  <TableBody>
                                    {suppliersList?.map((sList, sInd) => {
                                      if (sInd !== index) return null
                                      return (
                                        sList?.order_details?.map((pdetail, ind) => {
                                          return (
                                            <>
                                              {
                                                getProductDetail(pdetail, ind, false, allFiles, sInd, sList.is_selected)
                                              }
                                              <TableRow>
                                                <TableCell colspan="100%" style={{ padding: 0, border:0 }}>
                                                  <Divider sx={{ width: '100%' }} />
                                                </TableCell>
                                              </TableRow>
                                            </>
                                          )
                                        })
                                      )
                                    })}
                                  </TableBody>
                                </Table>
                              )
                            }
                            {/* < Box sx={{ margin: 5 }}>
                              <Collapse in timeout="auto" unmountOnExit>
                                {renderImages(allFiles)}
                              </Collapse>
                            </Box> */}

                          </TableCell>
                        </TableRow>
                      </Fragment>
                    );
                  })}
                </TableBody>
              </Table>
            </div>}
            {!next && <>
              <div className="table-responsive w-full">
                {isCreateOrder && (
                  <div className='mx-10'>
                    <Checkbox
                      checked={checked}
                      className='px-8 py-8'
                      onChange={() => { setChecked(!checked) }}
                    />
                    Send for Approval
                  </div>
                )}
                <Table className="simple w-full min-w-full">
                  <AuraSimpleTableHead
                    headerList={supplierProductsHeadersActions}
                    classes={{ cnCell: 'font-semibold px-8 py-8' }}
                  />
                  <TableBody>
                    {
                      selectedIndex !== undefined && suppliersList && suppliersList[selectedIndex]?.order_details?.map((products, index) => {
                        return (
                          <Fragment key={products.id}>
                            {getProductDetail(products, index, true)}
                            {(products?.vas?.text?.[0]?.name || products?.vas?.text?.[0]?.value || products?.vas?.text?.[0]?.url) && getVasPrice('Sandblasting', index, 'textPrice')}
                            {(products?.vas?.images?.[0]?.name || products?.vas?.images?.[0]?.value || products?.vas?.images?.[0]?.url) && getVasPrice('Etching', index, 'imagePrice')}
                          </Fragment>
                        )
                      })}
                  </TableBody>
                </Table>

              </div>
              <div className='flex mt-14 gap-10 justify-between'>
                <div>
                  {(status !== 'Artwork Inprogress') && (
                    <div className="flex gap-6">
                      {['Tariff', 'Shipping'].map((label) => (
                        <Controller
                          control={control}
                          key={label}
                          name={label.toLowerCase()}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              className="mb-24"
                              label={['Tariff', 'Margin'].includes(label) ? `${label} (%)` : label}
                              id={label.toLowerCase()}
                              InputLabelProps={{ shrink: true }}
                              variant="outlined"
                              fullWidth
                              size="small"
                              type="number"
                              onKeyDown={preventNegativeValues}
                              error={!!errors[label.toLowerCase()]}
                              helperText={errors[label.toLowerCase()]?.message}
                              InputProps={{
                                startAdornment: ['Shipping'].includes(label) && (
                                  <InputAdornment position="start">$</InputAdornment>
                                ),
                                inputProps: { min: 0 },
                              }}
                              disabled={action?.reject || action?.revision}
                              sx={highlightedBoxInputStyle}
                            />
                          )}
                        />
                      ))}
                    </div>
                  )}
                  {
                    selectedIndex !== undefined && suppliersList && suppliersList[selectedIndex]?.note && (
                      <div className='w-100 mb-24 w-full'>
                        <TextField
                          className='w-100 w-full'
                          id="supplier_note"
                          label="Supplier Notes"
                          value={suppliersList[selectedIndex]?.note}
                          disabled
                          multiline
                          rows={5}
                          variant="outlined"
                          sx={highlightedBoxInputStyle}
                        />
                      </div>
                    )
                  }
                  <div style={{ width: getWidth() }}>
                    <Controller
                      control={control}
                      name='note'
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label='Notes'
                          required={approve}
                          multiline
                          minRows={3}
                          maxRows={5}
                          fullWidth
                          error={!!errors.note}
                          helperText={errors.note?.message}
                          sx={highlightedBoxInputStyle}
                        />
                      )}
                    />
                  </div>

                </div>

                {status !== 'Artwork Inprogress' && (<div className="flex justify-end flex-col w-1/3">
                  <Table className="simple mt-20">
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <Typography className="font-normal text-13 ">Total Item(s)</Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography className="font-normal">
                            {selectedIndex !== undefined && suppliersList && sumBy([suppliersList[selectedIndex]], function (o) {
                              return o?.quantity;
                            })}
                          </Typography>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>
                          <Typography className="font-normal text-13 ">Total Weight</Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography className="font-normal">

                            {selectedIndex !== undefined && suppliersList && _.sumBy(_.map(suppliersList[selectedIndex]?.order_details, (o, index) => {
                              const quantity = Number(o?.quantity);
                              const itemWeight = weightIndex[index];
                              return quantity * itemWeight;
                            }), _.identity)} lbs

                          </Typography>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>
                          <Typography className="font-bold text-13">
                            TOTAL
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <div className="flex flex-row justify-end">
                            <Typography className="font-normal" variant="h6">
                              {selectedIndex !== undefined && suppliersList &&
                                formatter.format(
                                suppliersList[selectedIndex]?.order_details?.reduce((sum, o, index) => {
                                  const vas = orderDetailsForm?.[index]?.vas || {};
                                  const textPrice = Number(vas?.textPrice || 0)
                                  const imagePrice = Number(vas?.imagePrice || 0)
                                  return sum + Number(calculatePrice(o?.product?.unit_price, o?.unit_price, o?.weight, o?.quantity, o?.product?.margin, false, o?.discount, textPrice, imagePrice));
                                }, 0))
                              }

                            </Typography>
                            <Typography className="font-normal text-red" variant="h6">
                              *
                            </Typography>
                          </div>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </div>)}
              </div>
            </>}
          </>
        )}
      </AuraBaseDialog>
    </FormProvider>
  );
}

export default memo(ChooseSupplier);