import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import axios from "axios";
import { getParams } from "../../common/AuraFunctions";

export const getShipments = createAsyncThunk(
  "backofficeApp/shipments/getShipments",
  async ({ params, goToFirstPage }, { dispatch }) => {
    dispatch(setLoading(true))
    const { filterObj, headerObj } = getParams(goToFirstPage);
    const headers = {
      "App-Pagination-Limit": 10,
      "App-Pagination-Num": 0,
      ...headerObj,
    };
    const response = await axios.get("/api/shipments/customers", {
      params: { ...params, ...filterObj },
      headers,
    });
    const data = await response.data.data;
    dispatch(setShipmentsListHeaders(response.headers));
    dispatch(resetLoading(false))
    return data;
  }
);

const shipmentsAdapter = createEntityAdapter({
  selectId: (entity) => {
    return entity.shipment_id ? entity.shipment_id : entity.id;
  },
});

export const initState = {
  loading: false,
  searchText: "",
  shipmentsListHeaders: {},
  selectedShipments: [],
  resetFilter: false
};

export const {
  selectAll: selectShipments,
  selectById: selectShipmentById,
} = shipmentsAdapter.getSelectors(
  (state) => state.backofficeApp.shipments
);

const shipmentsSlice = createSlice({
  name: "backofficeApp/shipments",
  initialState: shipmentsAdapter.getInitialState({ ...initState }),
  reducers: {
    setLoading: (state, action) => {
      state.loading = true
    },
    resetLoading: (state, action) => {
      state.loading = false
    },
    setShipmentsSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event ? event.target.value : "" }),
    },
    setSelection: {
      reducer: (state, action) => {
        state[action.payload.field] = action.payload.value;
      },
      prepare: (field, event) => ({
        payload: { field, value: event ? event.target.value : "" },
      }),
    },
    setShipmentsListHeaders: (state, action) => {
      state.shipmentsListHeaders = action.payload;
    },
    resetShipments: () =>
      shipmentsAdapter.getInitialState({
        ...initState,
      }),
    setResetFilters: (state, action) => {
      state.resetFilter = action.payload
    },
  },
  extraReducers: {
    [getShipments.fulfilled]: shipmentsAdapter.setAll,
    [getShipments.pending]: shipmentsAdapter.removeAll,
    [getShipments.rejected]: (state) => { state.loading = false },
  },
});

export const {
  setLoading,
  resetLoading,
  setShipmentsSearchText,
  setSelection,
  setShipmentsListHeaders,
  resetShipments,
  setResetFilters,
} = shipmentsSlice.actions;

export default shipmentsSlice.reducer;
