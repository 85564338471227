import Icon from '@mui/material/Icon';
import Input from '@mui/material/Input';
import Paper from '@mui/material/Paper';
import { Badge } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { motion } from 'framer-motion';
import { useSelector } from 'react-redux';
import { selectUser } from 'app/store/userSlice';
import { selectMainTheme } from 'app/store/fuse/settingsSlice';
import IconButton from '@mui/material/IconButton';
import Hidden from '@mui/material/Hidden';
import LocalPrintshopOutlined from '@mui/icons-material/LocalPrintshopOutlined';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import { Link, useLocation } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import _ from '@lodash';
import Button from '@mui/material/Button';
import AuraImport from './AuraImport';
import AuraImportPopover from './AuraImportPopover';
import { ResetFilter } from './FiltersComponent';
import { hasPermission, isCustomer, isSupplier } from './AuraFunctions';

function AuraHeader(props) {
  const mainTheme = useSelector(selectMainTheme);
  const user = useSelector(selectUser);
  const FilterComponent = props.filter?.component;
  const location = useLocation()
  const { pathname } = location
  const isSupplierCofirmedOrder = pathname.split('/').includes('confirmed')
  const isCustomerProducts = pathname.split('/')[1]
  const allState = useSelector((state) => state);
  const toComponentState = props.toComponentState || null

  return (
    <div className="flex flex-1 items-center justify-between p-8 sm:p-24 relative aura-header">
      <Hidden lgUp>
        <IconButton
          onClick={() => props.pageLayout.current.toggleLeftSidebar()}
          aria-label="open left sidebar"
          size="large"
        >
          <Icon>menu</Icon>
        </IconButton>
      </Hidden>

      <div className="flex flex-col w-full">
        <div className="flex flex-row mx-6 items-center">
          {props.links?.map((link) => {
            if (link.type === 'back' && link.show) {
              const iconButton = (<IconButton
                className="p-0 mr-10"
                component={Link}
                to={link.link}
                state={toComponentState}
                aria-label={link.value}
                size="large"
              >
                <Icon>arrow_back</Icon>
              </IconButton>)
              return link.title ? (<Tooltip key={link.id} title={link.title}>
                {iconButton}
              </Tooltip>) : iconButton

            }
            return null;
          })}
          <span className="text-16 md:text-24 font-semibold">{props.headerTitle}</span>
          {props.links?.map((link) => (
            <div key={link.id}>
              {link.type === 'new' && link.show &&
                <>
                  {user.role !== 'customer' && hasPermission(user, "create", props.permissionModule) ? (
                    <Tooltip title={link.title}>
                      <IconButton
                        className="w-32 h-32 mx-4 p-0"
                        size="large"
                        {...(link.link ? { to: link.link, component: Link } : null)}
                        {...(link.onClick ? { onClick: link.onClick } : null)}
                      >
                        <Icon>add_circle</Icon>
                      </IconButton>
                    </Tooltip>
                  ) : null}
                </>
              }
              {link.type === 'import' && link.show && link.stage !== 'order' && hasPermission(user, "import", props.permissionModule) && (
                <AuraImport
                  id={_.uniqueId('id_')}
                  name={link.value}
                  title={link.title}
                  icon={link.icon}
                  handleBackdropToggle={props.handleBackdropToggle}
                  display={link.display} // field to display import options in list or open file document directly
                  {...(link.params ? { params: link.params } : null)}
                />
              )}
              {link.type === 'import' && link.show && link.stage === 'order' && hasPermission(user, "import", props.permissionModule)&& (
                <AuraImportPopover
                  id={_.uniqueId('id_')}
                  name={link.value}
                  title={link.title}
                  icon={link.icon}
                  display={link.display} // field to display import options in list or open file document directly
                  handleBackdropToggle={props.handleBackdropToggle}
                  {...(link.params ? { params: link.params } : null)}
                />
              )}
              {link.type === 'export' && link.show && hasPermission(user, "export", props.permissionModule)&& (
                <Tooltip title={link.title} className='cursor-pointer'>
                  <IconButton
                    className="w-32 h-32 mx-4 p-0"
                    size="large"
                    {...(link.onClick ? { onClick: link.onClick } : null)}
                  >
                    <FuseSvgIcon size={20} color="action">material-outline:cloud_upload</FuseSvgIcon>
                  </IconButton>
                </Tooltip>
              )}

              {link.type === 'sample_file' && link.show && hasPermission(user, "import", props.permissionModule) && (
                <Tooltip title={link.title} className='cursor-pointer'>
                  <IconButton
                    className="w-32 h-32 mx-4 p-0"
                    size="small"
                  >
                    <Link
                      className='!bg-white !border-0 !text-blue-500 mt-4'
                      to={link.path}
                      target="_blank"
                      download
                    >
                      <FuseSvgIcon color="action">description</FuseSvgIcon>
                    </Link>
                  </IconButton>
                </Tooltip>
              )}
            </div>
          ))}
        </div>
        {props.filter?.enabled && (
          <FilterComponent
            entitiesSelector={props.entitiesSelector}
            setCustomer={props.setCustomer}
            defaultCustomer={props.defaultCustomer}
            defaultSalesPerson={props.defaultSalesPerson}
          />
        )}
      </div>

      {props.search?.enabled && (
        <div className="flex flex-1 items-center justify-end">
          {!isSupplierCofirmedOrder &&
            <>
              {/* {!isCustomer(user.role) && <ThemeProvider theme={mainTheme}>
                <Paper
                  component={motion.div}
                  initial={{ y: -20, opacity: 0 }}
                  animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
                  className="flex items-center w-400 max-w-512 px-8 py-4 rounded-16 shadow"
                >
                  <Icon color="action">search</Icon>

                  <Input
                    placeholder="Search"
                    className="flex flex-1 mx-8"
                    disableUnderline
                    fullWidth
                    value={props.search.default || ''}
                    inputProps={{
                      'aria-label': 'Search',
                    }}
                    onChange={(ev) => props.search.onChange(ev)}
                  />
                </Paper>
                <ResetFilter handleResetFiltersClick={props.search.resetHandler} />
              </ThemeProvider>
              } */}
            </>}

          {props.links?.map((link) => (
            <div key={link.id}>
              {link.type === 'new' && link.show && isCustomer(user.role) &&
                <Button
                  className="whitespace-nowrap mx-4"
                  variant="contained"
                  color="secondary"
                  component={Link}
                  to={link.link}
                >
                  {link.title}
                </Button>}
            </div>
          ))}
          {isSupplier(user.role) && isSupplierCofirmedOrder && hasPermission(user, "PRIV_ACCESS_CREATE_CONTAINER") && (
            <Button
              className="whitespace-nowrap mx-4"
              variant="contained"
              color="secondary"
              component={Link}
              to="/shipments/new"
              startIcon={<FuseSvgIcon size={20}>material-solid:local_shipping</FuseSvgIcon>}
            >
              Create Container
            </Button>
          )}
        </div>
      )}
      <div>
        {isCustomer(user.role) && isCustomerProducts === 'products' && allState.auth.carts.totalCartItem > 0 && <Button
          component={Link}
          to='/cart'
          className="whitespace-nowrap"
          variant="contained"
          color="secondary"
        >
          <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <div>
              <Badge badgeContent={allState.auth && allState.auth.carts ? allState.auth.carts.totalCartItem : 0} color="error">
                <FuseSvgIcon size={30} style={{ marginRight: '0px' }}>
                  shopping_cart
                </FuseSvgIcon>
              </Badge>

            </div>
            <div>
              <span>Proceed To Place Order</span>
            </div>
          </div>
        </Button>}
      </div>
      {isCustomer(user.role) && props.totalQuantity > 0 && (
        <Button
          className="whitespace-nowrap mx-4"
          variant="contained"
          color="secondary"
          onClick={props.addAllToCart}
        >
          Add all to Cart
        </Button>
      )}
      {
        props.isProfile && (
          <Button
            className="whitespace-nowrap mx-4"
            variant="contained"
            color="secondary"
            disabled={props.disableProfileUpdate}
            onClick={props.updateProfile}
          >
            Update
          </Button>
        )
      }
      {props.links?.map((link) => {
        if (link.type === 'save' && link.show) {
          return (
            <div key={link.id} className="flex flex-1 items-center justify-end">
              <Button
                className="whitespace-nowrap mx-4"
                variant="contained"
                color="secondary"
                {...(link.disabled ? { disabled: link.disabled } : null)}
                {...(link.onClick ? { onClick: link.onClick } : null)}
              >
                {link.title || 'Save'}
              </Button>
            </div>
          );
        }
        if (link.type === 'print_popup' && link.show) {
          return (
            <div key={link.id}>
              <Tooltip title="Print List">
                <IconButton onClick={link.onClick && link.onClick}>
                  <LocalPrintshopOutlined />
                </IconButton>
              </Tooltip>
            </div>
          );
        }
        return null;
      })}
    </div>
  );
}

export default AuraHeader;