import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const getReviewShipmentDetail = createAsyncThunk(
  "auth/reviewShipment/getReviewShipmentDetail",
  async (token) => {
    const response = await axios.get(`/api/processShipment/${token}`);
    const data = await response.data;
    return data;
  }
);

export const updateReviewedShipment = createAsyncThunk(
  "auth/reviewShipment/updateReviewedShipment",
  async ({ token, payload, params}) => {
    const response = await axios.put(`/api/approveShipment/${token}`, {...payload,}, { params });
    const data = await response.data;
    return data;
  }
);

const initialState = {
  shipmentDetail: {},
  name: "",
  reviewShipmentDialog: {
    props: {
      open: false,
    },
    data: null,
    param: null,
  },
};

const reviewShipmentSlice = createSlice({
  name: "auth/reviewShipment",
  initialState,
  reducers: {
    openReviewShipmentDialog: (state, action) => {
      state.reviewShipmentDialog = {
        props: {
          open: true,
        },
        completeOrderData: action.payload.completeOrderData,
        data: action.payload.data,
        param: action.payload.param,
        stage: action.payload.stage,
      };
    },
    closeReviewShipmentDialog: (state, action) => {
      state.reviewShipmentDialog = {
        props: {
          open: false,
        },
        data: null,
        param: null,
      };
    },
  },
  extraReducers: {},
});

export const { openReviewShipmentDialog, closeReviewShipmentDialog } =
  reviewShipmentSlice.actions;

export default reviewShipmentSlice.reducer;
