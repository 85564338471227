import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { Controller, useFormContext } from 'react-hook-form';
import { displayName } from 'app/main/apps/common/AuraFunctions';

function ConfirmInvoice(props) {
    const methods = useFormContext();
    const { control, formState } = methods;
    const { errors } = formState;

    return (
        <>
            <div className="flex flex-col md:flex-row w-full gap-10">
                <div className="flex w-full ">
                    <Controller
                        control={control}
                        name="shipping_cost"
                        render={({ field }) => (
                            <TextField
                                {...field}
                                className="mb-24"
                                label="Shipping Cost"
                                id="shipping_cost"
                                variant="outlined"
                                fullWidth
                                size="small"
                                type="number"
                                required
                                error={!!errors.shipping_cost}
                                helperText={errors?.shipping_cost?.message}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                }}
                            />
                        )}
                    />
                </div>

            </div>
            <div className="flex flex-col md:flex-row w-full gap-10">
                <div className="flex w-full ">
                    <Controller
                        control={control}
                        name="tax"
                        render={({ field }) => (
                            <TextField
                                {...field}
                                className="mb-24"
                                label={displayName?.tax || "Tax"}
                                id="tax"
                                variant="outlined"
                                fullWidth
                                size="small"
                                type="number"
                                required
                                error={!!errors.tax}
                                helperText={errors?.tax?.message}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                }}
                            />
                        )}
                    />
                </div>
            </div>
            <div className="flex flex-col md:flex-row w-full gap-10">
                <div className="flex w-full ">
                    <Controller
                        control={control}
                        name="comments_to_send"
                        render={({ field }) => (
                            <TextField
                                {...field}
                                className="mb-24"
                                label="Comment"
                                id="comments_to_send"
                                variant="outlined"
                                fullWidth
                                size="small"
                                type="text"
                                multiline
                                rows={3}
                                error={!!errors.comments_to_send}
                                helperText={errors?.comments_to_send?.message}
                            />
                        )}
                    />
                </div>
            </div>
        </>
    );
}

export default ConfirmInvoice;
