import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Icon from '@mui/material/Icon';
import format from 'date-fns/format';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';
import { isCustomer, isSupplier } from 'app/main/apps/common/AuraFunctions';
import clsx from 'clsx';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import NavLinkAdapter from '@fuse/core/NavLinkAdapter';
import _ from 'lodash';

function NotificationCard(props) {
    const { item, className, parent = null } = props;
    const variant = item?.variant || '';
    const navigate = useNavigate();
    const currentUser = useSelector(({ user }) => user);
    const isAdminOrStaff =
        currentUser && (currentUser.role === 'admin' || currentUser.role === 'staff' || currentUser.role === 'sales');

    const handleNavigation = (isComplete = false) => {
        // console.log('item.stage', item.stage)
        let from = 'customer'
        let supplierParams = ''
        let isCompleteParam = ''
        let stage = `${item.stage}s`
        let title = ''
        if ((isCustomer(currentUser.role) || isSupplier(currentUser.role)) && !['rfq', 'quotes'].includes(stage)) {
            if (item.stage === 'rfd') {
                title = 'Rfp'
                stage = 'rfds'
            }
            if (item.stage === 'completed') {
                title = 'Rfp Completed'
                stage = 'completed'
            }
            if (item.stage === 'order') {
                title = isCustomer(currentUser.role) ? 'Orders' : 'Received Order'
                stage = 'orders'
            }
            if (item.stage === 'confirmed') {
                title = 'Confirmed Order'
                stage = 'confirmed'
            }
            if (item.stage === 'rfq') {
                stage = 'rfqs'
            }
        }
        if (item.stage === 'order' && isCustomer(currentUser.role)) {
            stage = 'orders'
        }
        if (item.stage === 'rfq') {
            if (isCustomer(currentUser.role) || isSupplier(currentUser.role)) {
                stage = 'rfqs'
            } else {
                stage = item.stage
            }
        }
        if (item.supplier_name) {
            from = 'supplier'
            supplierParams = `&supplier_name=${item.supplier_name}&supplier_id=${item.supplier_id}`
        }
        if (isComplete) {
            isCompleteParam = `&is_complete=${isComplete}&entity_history_id=${item.entity_history_id}`
        }
        if(isAdminOrStaff){
            navigate(`/${stage}/${item.supplier_name ? item.parent_order_id : item.id}?tab=6&from=${from}${supplierParams}${isCompleteParam}&title=${title}`)
        } else {
            navigate(`/${stage}/${item.id}?tab=6&from=${from}${supplierParams}${isCompleteParam}&title=${title}`)
        }
    }

    const getCardBgColor = (note) => {
        if(parent && parent === 'notes') return "#f1f4f9"
        let colorCode = '#FFECEB'
        if (!isAdminOrStaff) colorCode = '#F3F0FF'
        else if (note.customer_name) colorCode = '#E9F2FF'
        return colorCode
    }

    const getOrderLabel = () => {
        if (item.stage === 'rfd') {
            return 'Rfp'
        }
        return _.capitalize(item.stage)
    }

    return (
        <Card
            className={clsx(
                'flex relative w-full rounded-16 p-20 min-h-64 shadow space-x-8 self-start',
                variant === 'success' && 'bg-green-600 text-white',
                variant === 'info' && 'bg-blue-700 text-white',
                variant === 'error' && 'bg-red-600 text-white',
                variant === 'warning' && 'bg-orange-600 text-white',
                className
            )}
            elevation={0}
            component={item.useRouter ? NavLinkAdapter : 'div'}
            to={item.link || ''}
            role={item.link && 'button'}
            sx={{ color: 'black', background: getCardBgColor(item) }}
        >
            {item.icon && !item.image && (
                <Box
                    sx={{ backgroundColor: 'background.default' }}
                    className="flex shrink-0 items-center justify-center w-32 h-32 mr-12 rounded-full"
                >
                    <FuseSvgIcon className="opacity-75" color="inherit">
                        {item.icon}
                    </FuseSvgIcon>
                </Box>
            )}

            {item.image && (
                <img
                    className="shrink-0 w-32 h-32 mr-12 rounded-full overflow-hidden object-cover object-center"
                    src={item.image}
                    alt="Notification"
                />
            )}

            <div className="flex flex-col flex-auto">
                <div className='flex justify-between mb-8'>
                    {parent && parent === 'notes' && <Typography className="font-semibold line-clamp-1">Added By: {item.added_by}</Typography>}
                    {item.customer_name && isAdminOrStaff && <Typography className="font-semibold line-clamp-1">Customer Name: {item.customer_name}</Typography>}
                    {item.supplier_name && isAdminOrStaff && <Typography className="font-semibold line-clamp-1">Supplier Name: {item.supplier_name}</Typography>}
                    {item.stage && item.order_no && <Typography className="flex font-semibold line-clamp-1 cursor-pointer" onClick={() => handleNavigation(true)}>{item?.is_has_attachment && <span className='mr-14'>
                        <FuseSvgIcon
                            className="text-48 cursor-pointer"
                            size={24}
                            color="action"
                        >
                            material-twotone:attachment
                        </FuseSvgIcon></span>} {getOrderLabel()}: #{item.order_no} <Icon fontSize='small'>launch</Icon> </Typography>}
                    {parent && parent === 'notes' && <Typography >{format(new Date(item.added_on), 'MMM dd, yyyy h:mm a')}</Typography>}
                    {item.stage && item.shipment_no && <Typography className="flex font-semibold line-clamp-1 cursor-pointer" onClick={() => props.updateCommunication(item)}>{item?.is_has_attachment && <span className='mr-14'>
                        <FuseSvgIcon
                            className="text-48 cursor-pointer"
                            size={24}
                            color="action"
                        >
                            material-twotone:attachment
                        </FuseSvgIcon></span>} Shipment: #{item.shipment_no} <Icon fontSize='small'>launch</Icon> </Typography>}
                </div>
                {
                    item?.email_notes ? (
                        <div className='min-h-28 whitespace-pre-line'>
                            <div><span className='font-bold mr-2'>Subject:</span>{item?.subject}</div>
                            <div><span className='font-bold mr-2'>Message:</span>{item?.note}</div>
                        </div>
                    ) : (
                        <div className='min-h-28 whitespace-pre-line'>
                            {item.note && (
                                <div dangerouslySetInnerHTML={{ __html: item.note }} />
                            )}
                        </div>
                    )
                }

                {item.last_updated_at && (
                    <div className='flex justify-between'>
                        <Typography className="mt-8 text-sm">
                            {format(new Date(item.last_updated_at), 'MMM dd, yyyy h:mm a')}
                        </Typography>
                        {!item?.shipment_no &&
                            <div>
                                <Button
                                    onClick={() => handleNavigation(true)}
                                    variant='contained'
                                    sx={{ color: 'black' }}
                                >
                                    Reply and Mark as Completed
                                </Button>
                            </div>
                        }
                    </div>
                )}
            </div>
            {item.children}
        </Card>
    );
}

export default NotificationCard;
