import { Controller } from 'react-hook-form';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useState } from 'react';

export default function AuraControlledAutocomplete({
  onChange: ignored,
  control,
  controlName,
  inputValues,
  labelName,
  readOnly = false,
  hideClearable = false,
  optionDisplayName = 'name',
  optionCompareField = null,
  onUpdate = null,
  renderOption = null,
  filterOptions = undefined,
  className = 'mt-8 mb-16',
  defaultValue = null,
  multiple,
  size,
  error,
  limitTags,
  helperText,
  creatable,
  disabled,
  required,
  variant = "outlined",
  InputProps = {},
  rules,
  showAdd = false,
  forcePopupIcon = true
}) {
  const createFilter = createFilterOptions();
  const compareValue = optionCompareField || optionDisplayName;
  const [open, setOpen] = useState(false);
  const [fieldValue, setFieldValue] = useState(''); // Manage input value
  const textFieldSx = {
    '& .MuiAutocomplete-input': {
      textTransform: 'capitalize',
    },
  };

  function listOption(props1, option) {
    if (typeof option === 'string')
      return <li {...props1}>{option}</li>;
    return <li {...props1}>{option[optionDisplayName]}</li>;
  }

  const filters = (options, params) => {
    if (!creatable)
      return options

    const filtered = createFilter(options, params);
    const { inputValue } = params;
    // Suggest the creation of a new value
    const isExisting = options.some((option) => {
      if (typeof option === 'string') {
        return inputValue === option;
      }
      return inputValue === option[optionDisplayName]
    });
    if (inputValue !== '' && !isExisting) {
      if (typeof inputValues?.[0] === 'string')
        filtered.push(`Add "${inputValue}"`);
      else
        filtered.push({
          inputValue,
          title: `Add "${inputValue}"`,
          ...showAdd ? { name: `Add ${inputValue}` } : {}
        });
    }

    return filtered;
  }

  console.log('fieldValue', fieldValue)
  return (
    <Controller
      rules={rules}
      render={({ field: { onChange, value, ...props } }) => (
        <Autocomplete
          readOnly={readOnly}
          disablePortal
          fullWidth
          multiple={multiple}
          size={size}
          limitTags={limitTags}
          freeSolo={creatable}
          disabled={disabled}
          onInputChange={(event, newInputValue, reason) => {
            // Clear inputValue when the user moves focus away (after blur is handled)
            // if (reason !== 'reset') {
            setFieldValue(newInputValue); // Update input value
            // }
          }}
          onChange={(event, newValue) => {
            onChange(newValue);
            if (onUpdate) {
              onUpdate(event, newValue);
            }
          }}
          selectOnFocus
          open={open}
          onOpen={() => !readOnly && setOpen(true)}
          onClose={() => setOpen(false)}
          disableClearable={hideClearable}
          value={value || defaultValue}
          inputValue={fieldValue}
          options={inputValues}
          getOptionLabel={(option) => {
            if (typeof option === 'string') {
              return option;
            }
            if (option.inputValue) {
              return option.inputValue;
            }
            if (option && option[optionDisplayName]) {
              return option[optionDisplayName];
            }
            return '';
          }}
          isOptionEqualToValue={(option, value1) => {
            if (option && option[compareValue] && value1[compareValue]) {
              return option[compareValue] === value1[compareValue];
            }
            return false;
          }}
          renderOption={renderOption || listOption}
          renderInput={(params) => (
            <TextField
              {...params}
              label={labelName}
              margin="normal"
              variant={variant}
              className={className}
              fullWidth
              size="small"
              // sx={textFieldSx}
              InputProps={{ ...params.InputProps, ...InputProps, readOnly }}
              helperText={helperText}
              error={error}
              required={required}
              sx={textFieldSx}
              onBlur={() => {
                if (!creatable) return
                console.log('onblur')
                // Add input value to the list on blur
                if (fieldValue && !inputValues.includes(fieldValue)) {
                  const updatedValue = multiple
                    ? [...(value || []), fieldValue]
                    : fieldValue;
                  onChange(updatedValue); // Update value in the form
                  if (onUpdate) {
                    onUpdate(null, updatedValue);
                  }
                }
                setFieldValue(''); // Reset input value
              }}
            />
          )}
          sx={{
            '& .MuiAutocomplete-paper': {
              zIndex: 9999, // Adjust z-index to be higher than the parent div
            },
          }}
          forcePopupIcon={forcePopupIcon}
          {...(!multiple && { filterOptions: filterOptions || filters })}
          {...props}
        />
      )}
      onChange={([, data]) => data}
      control={control}
      name={controlName}

    />
  );
}
