import { combineReducers } from '@reduxjs/toolkit';
import shipmentOrder from './shipmentOrderSlice';
import shipment from './shipmentSlice';
import shipments from './shipmentsSlice';
import communicationNotes from './communicationNotesSlice';

const reducer = combineReducers({
  shipmentOrder,
  shipment,
  communicationNotes,
  shipments
});

export default reducer;
