import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { formatDateString } from 'app/main/apps/common/AuraFunctions';
import format from 'date-fns/format';
import { capitalize } from 'lodash';

export const getRfqDetails = createAsyncThunk(
  'eCommerceApp/rfq/getEnquiryDetails',
  async ({ id, params }, { dispatch, getState }) => {
    const response = await axios.post(`/api/widgetRfqData`, { oid: id });
    const data = await response.data.orderItems[0];
    const getData = getRfq(data)
    dispatch(setProductsData(getData))
    dispatch(setOriginalData(data))
    const revisionNotes = { revisionNote: response?.data?.revisionNote }
    return data ? { ...getInitValues(data), ...revisionNotes } : { ...data, ...revisionNotes };
  }
);

const getRfq = (data) => {
  return data.order_details?.map((od) =>
  ({
    ...od.product, order_quantity: od.quantity,
    weight: Number(od.weight), price: !od.unit_price ? '' : od.unit_price,
    decedent: od.decedent,
    customer_provider_number: od.customer_provider_number,
    is_has_drawing: od.is_has_drawing,
    vas: od.vas,
    comments: od?.comments
  }))
}

export const convertToQuoteShippingProfile = createAsyncThunk('eCommerceApp/rfq/getShippingProfile',
  async ({ params }, { dispatch, getState }) => {
    const response = await axios.get(`/api/setting`, { params });
    const data = await response.data;
    return data;
  }
);

export const getCustomerMargin = createAsyncThunk('eCommerceApp/rfq/getCustomerMargin',
  async (customerId, { dispatch, getState }) => {
    const response = await axios.get(`/api/customerMargin/${customerId}`);
    const data = await response.data;
    return data;
  }
);



export const getRfqStatuses = createAsyncThunk(
  'eCommerceApp/rfq/getEnquiryStatuses',
  async ({ params }) => {
    const response = await axios.get('/api/categoryValues', { params });
    const data = await response.data;
    return { data, params };
  }
);

export const saveRfq = createAsyncThunk(
  'eCommerceApp/rfq/saveRfq',
  async ({ rfqData, params }, { dispatch, getState }) => {
    dispatch(setLoading());
    const { rfq } = getState().eCommerceApp;
    let response;
    const payload = { ...rfq, ...rfqData, through: rfqData.through?.name || rfqData.through }
    if (rfqData.id) {
      response = await axios.put(`/api/updateRfq/${rfqData.id}`, payload, { params });
    } else {
      response = await axios.post('/api/requestForQuote', payload);
    }
    const data = await response.data;
    return getInitValues(data);
  }
);

export const saveSupplierRfq = createAsyncThunk(
  'eCommerceApp/rfq/saveSupplierRfq',
  async (rfqData, { dispatch, getState }) => {
    const { rfq } = getState().eCommerceApp;
    const payload = { ...rfq, ...rfqData, through: rfqData.through?.name || rfqData.through }
    delete payload.orderType
    const response = await axios.put(`/api/requestForQuote/${rfqData.id}`, payload);
    const data = await response.data;
    return data;
  }
);

// For rejecting the  Quote request by supplier in RFQ page
export const rejectRfqQuote = createAsyncThunk(
  'eCommerceApp/rfq/rejectRfqQuote',
  async (payload) => {
    const response = await axios.put(`/api/rejectQuote/${payload.id}`, payload);
    const data = await response.data;
    return data;
  }
);

export const productData = {
  id: null,
  name: '',
  description: '',
  code: '',
  type: null,
  sub_type: null,
  stone_color: null,
  category: {
    name: '',
  },
  length: undefined,
  width: undefined,
  height: undefined,
  weight: undefined,
  polish: '',
  finish: '',
  order_quantity: 1,
  isCustomProduct: 'yes',
  is_draft_product_id: true,
  is_rfq: true,
  unit_price: undefined,
  unit_cost: undefined,
  price: undefined,
  customer_provider_number: '',
  decedent: '',
  errors: {},
  new_drawing_documents: [],
  is_has_drawing: false,
  tariff: undefined,
  shipping: undefined,
  margin: undefined,
  discount: '',
  comments: ''
};

export const initState = {
  loading: false,
  data: null,
  productsData: [productData],
  originalData: {},
  orderType: []
};

function getInitValues(data, revisionNote) {
  return {
    id: data && data.id ? data.id : null,
    enquiry_no: data && data.enquiry_no ? data.enquiry_no : '',
    name: data && data.name ? data.name : '',
    customer_id: data && data.customer_id ? data.customer_id : '',
    customer_email: data && data.customer ? data.customer?.email : '',
    customer_phone: data && data.customer ? data.customer?.phone : null,
    customer: data && data.customer ? data.customer : null,
    selected_customer: data && data.customer ? { ...data.customer } : null,
    client_contract_number: data && data.client_contract_number ? data.client_contract_number : '',
    date:
      data && data.date
        ? formatDateString(data.date, 'yyyy-MM-dd')
        : format(new Date(), 'yyyy-MM-dd'),
    requested_date:
      data && data.eta_date ? formatDateString(data.eta_date, 'yyyy-MM-dd') : '',
    quantity: data && data.quantity ? data.quantity : 1,
    amount: data && data.amount ? data.amount : '',
    status_id: data && data.status_id ? data.status_id : null,
    status: data && data.status ? data.status : null,
    status_change_time: data && data.status_change_time ? data.status_change_time : null,
    through: data && data.through ? capitalize(data.through) : 'Email',
    reference: data && data.reference ? data.reference : '',
    ship_to: data && data.ship_to ? data.ship_to : '',
    tags: data && data.tags ? data.tags : '',
    status_histories: data && data.status_histories ? data.status_histories : [],
    note_histories: data && data.note_histories ? data.note_histories : [],
    histories: data && data.histories ? data.histories : [],
    documents: data && data.uploadedFile ? data.uploadedFile : [],
    new_documents: data && data.new_documents ? data.new_documents : [],
    remarks: data && data.remarks ? data.remarks : '',
    notes: data && data.notes ? data.notes : '',
    order_details: data && data.order_details ? data.order_details : [],
    weight: data?.order_details && data?.order_details[0]?.weight ? data?.order_details[0]?.weight : '',
    length: data?.order_details && data?.order_details[0]?.name ? data?.order_details[0]?.length : '',
    width: data?.order_details && data?.order_details[0]?.width ? data?.order_details[0]?.width : '',
    height: data?.order_details && data?.order_details[0]?.height ? data?.order_details[0]?.height : '',
    stone_color: data?.order_details && data?.order_details[0]?.product ? data?.order_details[0]?.product?.stone_color : null,
    finish: data?.order_details && data?.order_details[0]?.finish ? data?.order_details[0]?.finish : '',
    polish: data?.order_details && data?.order_details[0]?.polish ? data?.order_details[0]?.polish : '',
    decedents: data && data?.decedents ? data?.decedents : '',
    type: data?.order_details && data?.order_details[0]?.product?.type ? data?.order_details[0]?.product?.type : '',
    sub_type:
      data?.order_details && data?.order_details[0]?.product?.sub_type
        ? data?.order_details[0]?.product?.sub_type
        : '',
    product: data?.order_details && data?.order_details[0]?.product ? data?.order_details[0]?.product : '',
    supplier_id: data?.supplier_id,
    unit_price: 0,
    revision_notes: data && data.revision_notes ? data.revision_notes : [],
    vas_order_type: data && data.vas_order_type ? data.vas_order_type : null,
    order_no: data && data.order_no ? data.order_no : null,
    order_type: data && data.order_type ? data.order_type : '',
    comments: data?.comments,
  };
}

const enquirySlice = createSlice({
  name: 'eCommerceApp/rfq',
  initialState: initState,
  reducers: {
    resetRfq: (state, action) => {
      state.data = null;
    },
    setLoading: (state, action) => {
      state.loading = true;
    },
    resetLoading: (state, action) => {
      state.loading = false;
    },
    setProductsData: (state, action) => {
      state.productsData = action.payload
    },
    setOriginalData: (state, action) => {
      state.originalData = action.payload
    },
    newRfq: {
      reducer: (state, action) => {
        state.data = action.payload;
      },
      prepare: (event) => ({
        payload: getInitValues(),
      }),
    },
    resetAll: (state) => {
      state.loading = false;
      state.data = null;
      state.productsData = [];
      state.originalData = {};
      state.orderType = [];
    },
  },
  extraReducers: {
    [getRfqDetails.fulfilled]: (state, action) => {
      state.data = action.payload;
    },
    [saveRfq.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    [saveRfq.rejected]: (state, action) => {
      state.loading = false;
    },
    [getRfqStatuses.fulfilled]: (state, action) => {
      state.orderType = action.payload
    }
  },
});

export const {
  resetRfq,
  newRfq,
  setLoading,
  resetLoading,
  setProductsData,
  setOriginalData,
  resetAll,
} = enquirySlice.actions;

export default enquirySlice.reducer;
