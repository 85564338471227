import { createAsyncThunk, createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'axios';

export const getCommunicationNotes = createAsyncThunk(
    'backofficeApp/communicationNotes/getCommunicationNotes',
    async (queryParams, { dispatch, getState }) => {
        dispatch(setLoading())
        const response = await axios.get(`/api/communicationNotes`, { params: queryParams })
        const data = await response.data.note_notifications || [];
        dispatch(resetLoading())
        return data;
    }
);

export const updateCommunicationNote = createAsyncThunk(
    'backofficeApp/communicationNotes/updateCommunicationNote', async ({ inputData = {}, params = {} }) => {
    const response = await axios.put(`/api/communicationNotes`, inputData, { params });
    const data = await response.data;
    return data
});

export const initState = {
    loading: false,
    notifications: []
};

const communicationNotesAdapter = createEntityAdapter({});

export const { selectAll: selectNotifications, selectById: selectOrderById } =
    communicationNotesAdapter.getSelectors((state) => state.backofficeApp.communicationNotes);

const communicationSlice = createSlice({
    name: 'backofficeApp/communicationNotes',
    initialState: communicationNotesAdapter.getInitialState(initState),
    reducers: {
        setLoading: (state, action) => {
            state.loading = true;
        },
        resetLoading: (state, action) => {
            state.loading = false;
        },
        resetNotifications: () => communicationNotesAdapter.getInitialState({
            ...initState
        }),
    },
    extraReducers: {
        [getCommunicationNotes.fulfilled]: (state, action) => {
            state.notifications = action.payload
        },
        [getCommunicationNotes.rejected]: (state, action) => {
            state.notifications = []
            state.loading = false;
        },
    },
});

export const {
    setLoading,
    resetLoading,
    resetNotifications
} = communicationSlice.actions;

export default communicationSlice.reducer;
