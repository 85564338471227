import { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import _ from '@lodash';
import { yupResolver } from '@hookform/resolvers/yup';
import { showMessage } from 'app/store/fuse/messageSlice';
import * as yup from 'yup';
import { checkNullOrUndefined } from 'app/main/apps/common/AuraFunctions';
import AuraBaseDialog from '../../../common/AuraBaseDialog';
import ShipmentHeader from './ShipmentHeader';
import ShipmentFormContent from './ShipmentFormContent';
import { closeShipmentDialog, createShipment } from '../../store/shipmentOrderSlice';

export const shipmentSchema = yup.object().shape({
  shipment_type: yup
    .string()
    .nullable()
    .required("Required"),
  schedule: yup.date().required("You must select a date"),
  note: yup.string().nullable(),
  pallet_count: yup
    .number()
    .min(0, "Min 0")
    .nullable()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .test({
      message: "Enter Pallets",
      test: (val, { parent }) => {
        if (parent.shipment_type === "LTL" && checkNullOrUndefined(val)) {
          return false;
        }
        return true;
      },
    }),
});

function getInitValues() {
  return {
    note: '',
    schedule: '',
    shipment_type: '',
    pallet_count: '',
  };
}
function ShipmentDialog(props) {
  const dispatch = useDispatch();
  const shipmentDialog = useSelector(({ backofficeApp }) => {
    return backofficeApp?.shipmentOrder?.shipmentDialog;
  });

  const methods = useForm({
    mode: 'onChange',
    defaultValues: getInitValues(),
    resolver: yupResolver(shipmentSchema),
  });

  const { reset, formState } = methods;
  const { dirtyFields, isValid } = formState;

  const [dialogTitle, setDialogTitle] = useState('New Shipment');
  const dialogParam = shipmentDialog?.param;

  /**
   * Initialize Dialog with Data
   */
  const initDialog = useCallback(() => {
    if (dialogParam) {
      const title = dialogParam.title || `Update ${dialogParam.name}`;
      setDialogTitle(title);
    }
  }, [dialogParam]);

  /**
   * On Dialog Open
   */
  useEffect(() => {
    if (shipmentDialog?.props.open) {
      initDialog();
    }
  }, [shipmentDialog?.props.open, initDialog]);

  /**
   * Close Dialog
   */
  function closeDialog() {
    dispatch(closeShipmentDialog());
  }

  function getSuccessMessage() {
    return dialogParam.successMessage
      ? dialogParam.successMessage
      : 'Shipment has been created successfully';
  }
  /**
   * Form Submit
   */
  function onSubmit(data) {
    dispatch(createShipment(data)).then(() => {
      dispatch(
        showMessage({
          message: getSuccessMessage(),
          variant: 'info',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        })
      );
    });
    closeDialog();
  }

  function disableSubmitButton() {
    return !!(_.isEmpty(dirtyFields) || !isValid);
  }

  const headerData = {
    enable: true,
    component: ShipmentHeader,
    data: shipmentDialog?.data,
  };

  return shipmentDialog && shipmentDialog.props && shipmentDialog.props.open ? (
    <>
      <FormProvider {...methods}>
        <AuraBaseDialog
          dialogProps={shipmentDialog.props}
          closeDialog={closeDialog}
          dialogParam={dialogParam}
          onSubmit={onSubmit}
          disableSubmitButton={disableSubmitButton}
          header={headerData}
        >
          <ShipmentFormContent />
        </AuraBaseDialog>
      </FormProvider>
    </>
  ) : (
    <></>
  );
}

export default ShipmentDialog;
