import { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import Checkbox from '@mui/material/Checkbox';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import _ from '@lodash';
import FormHelperText from '@mui/material/FormHelperText';
import NavigationUtil from 'app/nav/NavigationUtil';
import { setNavigation } from 'app/store/fuse/navigationSlice';
import jwtService from '../../../auth/services/jwtService';

/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
  email: yup.string().email('You must enter a valid email').required('You must enter a email'),
  password: yup
    .string()
    .required('Please enter your password.')
    .min(4, 'Password is too short -- should be 4 chars minimum.'),
});

const defaultValues = {
  email: '',
  password: '',
};

function JWTLoginTab(props) {
  const dispatch = useDispatch();
  // const login = useSelector(({ authProvider }) => authProvider.login);
  const [loading, setLoading] = useState(false);
  const { control, setValue, formState, handleSubmit, reset, trigger, setError } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, isSubmitting, errors } = formState;
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    setValue('rememberMe', true, { shouldDirty: true, shouldValidate: true });
  }, [reset, setValue, trigger]);

  const onSubmit = async ({ email, password, rememberMe }) => {
    await jwtService
      .signInWithEmailAndPassword(email, password, rememberMe)
      .then((user) => {
        // No need to do anything, user data will be set at app/auth/AuthContext

        // Get the Menu from response, update it and assign it to setNavigation to display in the nav bar
        const navUtil = new NavigationUtil();
        // Convert menu items to react navigation array format
        navUtil.loadMenus(user?.securityContext?.menus);
        // Retrieve the navigation array. Sent to true to retrieve without empty children array
        const navigationArray = navUtil.getNavigation(true);
        dispatch(setNavigation(navigationArray));
      })
      .catch((_errors) => {
        _errors.response.data.forEach((error) => {
          setError(error.type, {
            type: 'manual',
            message: error.message,
          });
        });
      });
  };

  return (
    <div className="w-full">
      <form className="flex flex-col justify-center w-full" onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              className="mb-16"
              type="text"
              error={!!errors.email}
              helperText={errors?.email?.message}
              label="Enter Email"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Icon className="text-20" color="action">
                      user
                    </Icon>
                  </InputAdornment>
                ),
              }}
              variant="outlined"
            />
          )}
        />

        <Controller
          name="password"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              className="mb-16"
              label="Enter Password"
              type="password"
              error={!!errors.password}
              helperText={errors?.password?.message}
              variant="outlined"
              InputProps={{
                className: 'pr-2',
                type: showPassword ? 'text' : 'password',
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword(!showPassword)} size="large">
                      <Icon className="text-20" color="action">
                        {showPassword ? 'visibility' : 'visibility_off'}
                      </Icon>
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              required
            />
          )}
        />
        <div className="flex flex-row">
          <Typography className="mt-10 font-medium text-14">Remember Me</Typography>
          <Controller
            name="rememberMe"
            control={control}
            defaultValue={false}
            render={({ field: { onChange, value } }) => (
              <Checkbox
                className="mb-16"
                label="Remember Me"
                name="rememberMe"
                variant="outlined"
                checked={value}
                onChange={(ev) => onChange(ev.target.checked)}
              />
            )}
          />
        </div>
        {errors.auth && (
          <FormHelperText className="mt-10 text-12 text-center" error>
            {errors?.auth?.message}
          </FormHelperText>
        )}
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className="w-full mx-auto mt-16"
          aria-label="LOG IN"
          disabled={_.isEmpty(dirtyFields) || !isValid || isSubmitting}
          value="legacy"
        >
          Login
        </Button>
      </form>
      <div className="h-128" />
    </div>
  );
}

export default JWTLoginTab;
