import { useDispatch } from 'react-redux';
import IconButton from '@mui/material/IconButton';
import Icon from '@mui/material/Icon';
import { Tooltip } from '@mui/material';
import Typography from '@mui/material/Typography';
import { openViewOrderDialog, openOrderDialog } from '../e-commerce/store/orderSlice';
import {
  getEcomWidgetsOrderItems
} from '../../dashboards/main/store/widgetsSlice';

function OrderViewAction({ row, cell, stage, value, loadOrders, loadOrdersFun, toHide = undefined, showEditAction = false, rowValue, paramKey = '', styles = {}, type = null }) {
  const dispatch = useDispatch();
  const onCellClick = (ev) => {
    ev.stopPropagation();
  };
  let { handleOpen } = row;
  if (!handleOpen) {
    handleOpen = openViewOrderDialog;
  }
  const getDetails = () => {
    let stageValue = { id: '', key: '', widgetId: '' };
    switch (stage) {
      case 'invoice':
        stageValue = {
          id: row.original.invoice_no,
          key: 'invoice_id',
          widgetId: row.original.id
        }
        break;
      case 'container':
      case 'in-production':
        stageValue = {
          id: row.original.container_number,
          key: 'shipment_id',
          widgetId: row.original.shipment_id
        }
        break;
      case 'outbound_shipments':
      case 'outbound_shipments_customer':
        stageValue = {
          id: row.original.shipment_no,
          key: 'shipment_id',
          widgetId: row.original.shipment_id
        }
        break;
      default:
        stageValue = {
          id: row.original.order_no,
          key: 'oid',
          widgetId: row.original.id
        }
    }
    return stageValue;
  };

  const getStageTitle = (isTooltipTitle) => {
    const stageData = value || stage
    switch (stageData) {
      case 'fulfilled':
      case 'confirmed':
      case 'order':
        return `View Order Details`;
      case 'quote':
        return `View Quote Details`;
      case 'rfq':
        return `View Rfq Details`;
      case 'invoice':
        return `View Invoice`;
      case 'approval':
        return `View Approval Items`;
      case 'shipment':
        return 'View Shipment';
      case 'container':
        return 'View Container Shipment'
      case 'in-production':
        return 'View In-production'
      case 'outbound_shipments':
      case 'outbound_shipments_customer':
        return 'View Shipment'
      default:
        return "";
    }
  }

  const handleContainerEdit = () => {
    dispatch(
      openOrderDialog({
        completeOrderData: row.original,
        data: row.original,
        param: {
          name: 'container_number',
          title: 'Edit Container Number',
          successMessage: 'Success',
          loadOrdersFun,
          submitButtonName: 'Update'
        },
        stage,
      })
    );
  }

  const containerNumberStyle = {
    display: "inline-block",
    maxWidth: "250px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  }

  const stageParam = () => {
    if(stage === "outbound_shipments" || stage === "outbound_shipments_customer") return "shipment"
    return stage === "container" ? "in-production" : stage
  }

  const updateResponseToOrginal = () => {
    return stage === "outbound_shipments" || stage === "outbound_shipments_customer";
  }

  const getDialogData = (payload = []) => {
    if(updateResponseToOrginal()){
      return payload?.orders || []
    }
    return stage === "invoice" ? payload?.invoice : payload
  }


  return (
    <Tooltip
      title={paramKey ? row.original?.container_number : getDetails().id}
    >
      <div
        onClick={onCellClick}
        onKeyDown={onCellClick}
        role="button"
        tabIndex="0"
        style={{ cursor: "default", pointerEvents: "auto", ...styles }}
        // className="flex flex-row"
      >
        {rowValue || (
          <Tooltip title={getStageTitle(true)}>
            <Typography
              className="py-0 px-2"
              sx={{ cursor: "pointer" }}
              onClick={(ev) => {
                const param = {
                  [getDetails().key]: getDetails().widgetId,
                  stage: stageParam(),
                  invoiceId: true,
                }
                if(stage === "outbound_shipments" || stage === "outbound_shipments_customer") {
                  param.is_outbound = true;
                }
                dispatch(
                  getEcomWidgetsOrderItems(param)
                ).then((data) => {
                  if (data && data.payload) {
                    // setOrderItems(data.payload);
                    dispatch(
                      handleOpen({
                        completeOrderData: updateResponseToOrginal() ? {...data.payload } : row.original,
                        stage: stageParam(),
                        data: getDialogData(data?.payload),
                        param: {
                          name: "viewOrder",
                          type: "View",
                          title: getStageTitle(false),
                          showDialogActions: true,
                          is_vas_order: row.original?.is_vas_order,
                          packingSlips: data.payload?.packing_slips || [],
                          supplierInvoices:
                            data.payload?.supplier_invoices || [],
                          loadOrders,
                          toHide,
                          isSupplierInvoice: type === 'supplier-invoices' || type === 'Supplier Invoice',
                          pageType: stage
                        },
                      })
                    );
                  }
                });
              }}
              size="small"
            >
              <div
                className={`${
                  (stage === "rfq" || stage === "quote") &&
                  row?.original?.confirmed_order === true &&
                  "text-green-600 font-bold"
                } ${
                  stage !== "container" &&
                  stage !== "in-production" &&
                  "less-text"
                } ${
                  (stage === "invoice") &&
                  row?.original?.is_overdue  === true &&
                  "text-red-600 font-bold"
                }` }
                style={
                  stage === "container" || stage === "in-production"
                    ? containerNumberStyle
                    : {}
                }
              >
                {paramKey ? row.original?.container_number : getDetails().id}
              </div>
            </Typography>
          </Tooltip>
        )}
        {showEditAction && (
          <IconButton
            className="py-0 px-2"
            size="small"
            onClick={() => handleContainerEdit()}
          >
            <Icon fontSize="small">edit</Icon>
          </IconButton>
        )}
      </div>
    </Tooltip>
  );
}

export default OrderViewAction;
