import _ from "lodash";

class NavigationUtil {
  constructor() {
    // Initialize the navigation array
    this.navigation = [];
  }

  /**
   * Getter method to retrieve the navigation array
   *
   * @param withoutEmptyChildern
   *
   */
  getNavigation(withoutEmptyChildern = null) {
    if (withoutEmptyChildern) {
      const navigationNew = this.navigation.map((item) => {
        if (item.children && item.children.length === 0) {
          // Remove the 'children' key
          const { children, ...rest } = item;
          // Return the object without 'children'
          return rest;
        }
        // Return the object as is if children exist
        return item;
      });
      return navigationNew;
    }
    return this.navigation;
  }

  /**
   * Create or update the navigation item
   *
   * @param path
   * @param item
   */
  saveItem = (path, item) => {
    if (typeof path !== "string") {
      console.error("path must be a string (eg. `dashboard.project`)");
      return;
    }

    const parts = path.split(".");

    // Generate the object id from the parts
    const id = parts[parts.length - 1];

    // Get the parent item from the parts
    const parent = this.findOrCreateParent(parts);

    // Decide if we are going to update or create
    const updateItem = _.find(parent, { _id: id });

    // Update
    if (updateItem) {
      _.assign(updateItem, item);
      // Add proper ui-sref
      updateItem.url = this.getUiSref(updateItem);
    }
    // Create
    else {
      const newItem = {
        ...item,
        children: [], // Create an empty children array in the item
        weight:
          item.weight && typeof item.weight === "number" ? item.weight : 1, // Add the default weight if not provided or if it's not a number
        _id: id, // Add the item id
        _path: path, // Add the item path
        url: this.getUiSref(item), // Add proper ui-sref
      };

      if (item.menu_type === "button") {
        // Add style sx for the button menu
        newItem.sx = {
          backgroundColor: "#45ce22c2",
          borderRadius: "10px",
          marginLeft: "10px",
        };
      }
      // Push the item into the array
      parent.push(newItem);
    }
  };

  /**
   * Delete navigation item
   *
   * @param path
   */
  deleteItem = (path) => {
    if (typeof path !== "string") {
      console.error("path must be a string (eg. `dashboard.project`)");
      return false;
    }

    // Locate the item by using given path
    const parts = path.split(".");
    let item = this.navigation;

    _.forEach(parts, (part) => {
      const id = part;

      _.forEach(item, (navItem, index) => {
        if (navItem._id === id) {
          // If we have a matching path,
          // we have found our object:
          // remove it.
          if (navItem._path === path) {
            item.splice(index, 1);
            // setNavigation([...this.navigation]);
            return false;
          }
          // Otherwise grab the children of
          // the current item and continue
          item = navItem.children;
          return false;
        }
        return null; // fix code
      });
    });

    return false;
  };

  /**
   * Sort the navigation items by their weights
   * Function to sort by weight recursively
   *
   * @param parent
   * If parent not provided, sort the root items
   */
  sortByWeight = (parent = this.navigation) => {
    return parent.map((item) => ({
        ...item,
        children: item.children ? this.sortByWeight(item.children) : [],
      }))
      .sort((a, b) => a.weight - b.weight);
  };

  /**
   * Find or create parent
   *
   * @param parts
   * @returns {Array|Boolean}
   * @private
   */
  findOrCreateParent = (parts) => {
    // Store the main navigation
    let parent = this.navigation;

    // If it's going to be a root item
    // return the navigation itself
    if (parts.length === 1) {
      return parent;
    }

    // Remove the last element from the parts as
    // we don't need that to figure out the parent
    parts.pop();

    // Find and return the parent
    _.forEach(parts, (part) => {
      const _id = part;
      const existingParent = _.find(parent, { _id });

      if (existingParent) {
        parent = existingParent.children;
      } else {
        // If there is no parent found, create one, push
        // it into the current parent and assign it as a
        // new parent
        const newItem = {
          _id,
          _path: parts.join("."),
          title: _id,
          weight: 1,
          children: [],
        };
        parent.push(newItem);
        parent = newItem.children;
      }
    });

    return parent;
  };

  /**
   * Sort by weight
   *
   * @param x
   * @param y
   * @returns {number}
   */
  byWeight = (x, y) => parseInt(x.weight, 10) - parseInt(y.weight, 10);

  /**
   * Setup the ui-sref using state & state parameters
   *
   * @param item
   * @returns {string}
   * @private
   */
  getUiSref = (item) => {
    let url = "";

    if (item.url) {
      url = item.url;

      if (item.stateParams && typeof item.stateParams === "object") {
        url += `(${JSON.stringify(item.stateParams)})`;
      }
    }

    return url;
  };

  /**
   * Convert menu items to react navigation array format
   *
   * @param menus
   */
  loadMenus = (menus) => {
    // Clear Navigation
    this.navigation = [];
    menus?.forEach((menu) => {
      this.saveItem(menu.name, menu);
    });
    this.navigation = this.sortByWeight(this.navigation);
  };
}

export default NavigationUtil;
